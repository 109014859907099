import { useEffect, useState } from "react";
import { APIClient } from "../../helpers/api_helper";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  FormFeedback,
  Input,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import { useDispatch, useSelector } from "react-redux";
import { addCategory } from "../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetAddCategory } from "../../store/actions";
import MsgToast from "../../Components/Common/MsgToast";

function Add() {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");

  document.title = "Create Category | " + metatitle;

  const dispatch = useDispatch();
  const [selectedCatType, setselectedCatType] = useState("");

  const categoryType = [
    {
      options: [
        { label: "Videos", value: "videos" },
        { label: "Blogs", value: "blogs" },
        { label: "FAQS", value: "faqs" },
      ],
    },
  ];

  const catType = (e) => {
    setselectedCatType(e.target.value);
  };

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      category_type: selectedCatType || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      const data = {
        name: values.name,
        category_type: values.category_type?.value,
        category_alias: convertToSlug(values.name),
        status: 1,
      };
      const res = dispatch(addCategory(data));
    },
  });

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, "")
      .replace(/ +/g, "-");
  }

  const { status_code, message } = useSelector((state) => ({
    status_code: state.Categories.status_code,
    message: state.Categories.message,
    isData: state.Categories.isData,
  }));

  useEffect(() => {
    if (status_code) {
      setTimeout(() => {
        dispatch(resetAddCategory({}));
      }, 3000);
    }
  }, [dispatch, status_code]);

  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Category"
            pageTitle="Categories"
            pageUrl="/categories"
          />
          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Create Category" />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="name"
                              className="form-control"
                              placeholder="Category name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={2} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={validation.values.category_type || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    name: "category_type",
                                    value: selectedCatType,
                                  },
                                };
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={categoryType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category_type &&
                                validation.errors.category_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category_type &&
                            validation.errors.category_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <button type="submit" className="btn btn-primary w-sm">
                      Create
                    </button>
                  </CardFooter>
                </Card>
              </form>
            </Col>
          </Row>
          {status_code === 200 ? (
            <MsgToast
              msg={message}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {status_code && status_code !== 200 ? (
            <MsgToast
              msg={message}
              color="danger"
              icon="ri-error-warning-line"
            />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
}

export default Add;
