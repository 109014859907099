import React, { useEffect, useState } from "react";
import { APIClient } from "../../../helpers/api_helper";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function StoriesView() {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const dispatch = useDispatch();
  const { id } = useParams();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [stories, setStories] = useState([]);

  useEffect(() => {
    fetch(
      `https://read.ceo.com/ghost/api/content/posts/${id}/?key=abdd2defe6c304abc9946d1d8e`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setStories(result.posts);
        },
        (error) => {}
      );
  }, [id, limit]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {
        id: "624c1d79ac364e5ef3796d76",
      });

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  document.title = "Stories Details | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="StoriesDetails" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Stories Details
                    </h5>
                  </div>
                </CardHeader>

                <CardBody className="pt-0">
                  <div className="table-responsive table-card mt-3 mb-1">
                    {stories?.length ? (
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {stories &&
                            stories.length > 0 &&
                            stories.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <img
                                      className="image avatar-xs rounded-circle"
                                      src={item?.feature_image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item.title}</td>
                                  <td>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.html.substring(0, 90) + "...",
                                      }}
                                    ></p>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="noresult">
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: "75px", height: "75px" }}
                          ></lord-icon>
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default StoriesView;
