import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_MEMBER,
  DELETE_MEMBER,
  GET_MEMBERS,
  GET_MEMBER_DETAILS,
  UPDATE_MEMBER,
  STATUS_CHANGE_MEMBER,
  SAVE_ORDERING_MEMBER,
} from "./actionTypes"

import {
  getMembersSuccess,
  getMembersFail,
  addNewMemberFail,
  addNewMemberSuccess,
  getMemberDetailsSuccess,
  getMemberDetailsFail,
  updateMemberSuccess,
  updateMemberFail,
  deleteMemberSuccess,
  deleteMemberFail,
  statusChangeMemberSuccess,
  statusChangeMemberFail,
  saveOrderingMemberSuccess,
  saveOrderingMemberFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMemberList,
  addNewMember,
  updateMember,
  getMemberDetails,
  deleteMember,
  statusChange,
  saveOrdering,

} from "../../helpers/fakebackend_helper"

function* fetchMembers({ payload: data }) {
  try {
    const response = yield call(getMemberList, data)
    yield put(getMembersSuccess(response))
  } catch (error) {
    yield put(getMembersFail(error))
  }
}

function* onaddNewMember({ payload: members }) {
  try {
    const response = yield call(addNewMember, members)
    yield put(addNewMemberSuccess(response))
  } catch (error) {
    yield put(addNewMemberFail(error))
  }
}

function* onUpdateMember({ payload: members }) {
  try {
    const response = yield call(updateMember, members)
    yield put(updateMemberSuccess(response))
  } catch (error) {
    yield put(updateMemberFail(error))
  }
}

function* onDeleteMember({ payload: members }) {
  try {
    const response = yield call(deleteMember, members)
    yield put(deleteMemberSuccess(response))
  } catch (error) {
    yield put(deleteMemberFail(error))
  }
}

function* onMemberDetails({ payload: members }) {
  try {
      const response = yield call(getMemberDetails, members);
      if(response.status === 200){
        yield put(getMemberDetailsSuccess(response));
      } else {
        yield put(getMemberDetailsFail(response));
      }
  } catch (error) {
      yield put(getMemberDetailsFail(error));
  }
}

//Status Change
function* onStatusChangeMember({ payload: members }) {
  try {
    const response = yield call(statusChange, members);
    yield put(statusChangeMemberSuccess({ members, ...response }));
  } catch (error) {
    yield put(statusChangeMemberFail(error));
  }
}

//Save Ordering
function* onSaveOrderingMember({ payload: members }) {
  try {
    const response = yield call(saveOrdering, members);
    yield put(saveOrderingMemberSuccess({ members, ...response }));
  } catch (error) {
    yield put(saveOrderingMemberFail(error));
  }
}


function* MemberSaga() {
  yield takeEvery(GET_MEMBERS, fetchMembers)
  yield takeEvery(ADD_NEW_MEMBER, onaddNewMember)
  yield takeEvery(UPDATE_MEMBER, onUpdateMember)
  yield takeEvery(DELETE_MEMBER, onDeleteMember)
  yield takeEvery(STATUS_CHANGE_MEMBER, onStatusChangeMember);
  yield takeEvery(GET_MEMBER_DETAILS, onMemberDetails);
  yield takeEvery(SAVE_ORDERING_MEMBER, onSaveOrderingMember);


}

export default MemberSaga
