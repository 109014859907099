// useAxios hook

import { useState, useEffect } from "react";
import axios from "axios";
import hostUrl from "../config/host";
import amityToken from "../config/token";

const instance = axios.create({
  baseURL: hostUrl,
});

instance.defaults.headers.common["Authorization"] = "Bearer " + amityToken;

const useAxios = ({ url, method, data = null, headers = null }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    instance[method](url, JSON.parse(data), JSON.parse(headers))
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [method, url, data, headers]);

  return { response, error, loading };
};

export default useAxios;
