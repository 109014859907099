import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
    ADD_NEW_EVENT,
    DELETE_EVENT,
    GET_EVENTS,
    GET_EVENT_DETAILS,
    UPDATE_EVENT,
    STATUS_CHANGE_EVENT,
    SAVE_ORDERING_EVENT,
} from "./actionTypes"

import {
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  getEventDetailsSuccess,
  getEventDetailsFail,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
  statusChangeEventSuccess,
  statusChangeEventFail,
  saveOrderingEventSuccess,
  saveOrderingEventFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getEventsList,
  addNewEvent,
  updateEvent,
  getEventDetails,
  deleteFaq,
  statusChange,
  saveOrdering,
  
} from "../../helpers/fakebackend_helper"

function* fetchEvents({ payload: data }) {
  try {
    const response = yield call(getEventsList, data)
    yield put(getEventsSuccess(response))
  } catch (error) {
    yield put(getEventsFail(error))
  }
}

function* onAddNewEvent({ payload: events }) {
  try {
    const response = yield call(addNewEvent, events)
    yield put(addEventSuccess(response))
  } catch (error) {
    yield put(addEventFail(error))
  }
}

function* onUpdateEvent({ payload: events }) {
  try {
    const response = yield call(updateEvent, events)
    yield put(updateEventSuccess(response))
  } catch (error) {
    yield put(updateEventFail(error))
  }
}

function* onDeleteEvent({ payload: events }) {
  try {
    const response = yield call(deleteFaq, events)
    yield put(deleteEventSuccess(response))
  } catch (error) {
    yield put(deleteEventFail(error))
  }
}

function* onEventDetails({ payload: events }) {
  try {
      const response = yield call(getEventDetails, events);
      if(response.status === 200){
        yield put(getEventDetailsSuccess(response));
      } else {
        yield put(getEventDetailsFail(response));
      }
  } catch (error) {
      yield put(getEventDetailsFail(error));
  }
}

//Status Change
function* onStatusChangeEvent({ payload: events }) {
  try {
    const response = yield call(statusChange, events);
    yield put(statusChangeEventSuccess({ events, ...response }));
  } catch (error) {
    yield put(statusChangeEventFail(error));
  }
}


//Save Ordering
function* onSaveOrderingEvent({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingEventSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingEventFail(error));
  }
}
function* EventSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents)
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent)
  yield takeEvery(UPDATE_EVENT, onUpdateEvent)
  yield takeEvery(DELETE_EVENT, onDeleteEvent)
  yield takeEvery(STATUS_CHANGE_EVENT, onStatusChangeEvent);
  yield takeEvery(SAVE_ORDERING_EVENT, onSaveOrderingEvent);
  yield takeEvery(GET_EVENT_DETAILS, onEventDetails);


}

export default EventSaga
