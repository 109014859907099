import React, { useEffect, useState } from "react";
import { APIClient } from "../../../helpers/api_helper";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventDetails } from "../../../store/actions";
import Moment from "react-moment";
import frontUrl from "../../../config/frontUrl";
import moment from "moment";

function EventsDetails() {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const dispatch = useDispatch();
  const { event_id } = useParams();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);

  useEffect(() => {
    dispatch(
      getEventDetails({
        keyword,
        limit,
        offset,
        event_id,
      })
    );
  }, [dispatch, keyword, limit, offset, event_id]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const { details } = useSelector((state) => ({
    details: state.Events.details,
  }));

  useEffect(() => {
    dispatch(getEventDetails({ event_id: event_id }));
  }, [dispatch, event_id]);

  document.title = "Event Details | " + metatitle;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <BreadCrumb title="EventDetails" /> */}
          <Row>
            <Col lg={8}>
              <div id="orderList">
                {details && (
                  <>
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title mb-0 flex-grow-1">
                            Event Name
                          </h5>
                          <a
                            href={
                              frontUrl +
                              "/event-details/" +
                              details?.slug +
                              "/" +
                              details?.event_id
                            }
                            target="_blank"
                            className="btn btn-primary w-sm"
                          >
                            Preview
                          </a>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="card-descrption">{details?.title}</div>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title mb-0 flex-grow-1">
                            Details
                          </h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="card-descrption">
                          Date:{" "}
                          <Moment format="D MMM YYYY">
                            {details?.start_date}
                          </Moment>
                          -
                          <Moment format="D MMM YYYY">
                            {details?.end_date}
                          </Moment>
                        </div>
                        {details?.end_time !== "" ? (
                          <div className="card-descrption">
                            Time:{" "}
                            {moment(details?.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}{" "}
                            -{" "}
                            {moment(details?.end_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </div>
                        ) : (
                          <div className="card-descrption">
                            Time:{" "}
                            {moment(details?.start_time, "HH:mm:ss").format(
                              "hh:mm A"
                            )}
                          </div>
                        )}
                      </CardBody>
                    </Card>
                    {details?.description && (
                      <Card>
                        <CardHeader>
                          <div className="d-flex align-items-center">
                            <h5 className="card-title mb-0 flex-grow-1">
                              About the event
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <div className="card-descrption">
                            {details?.description}
                          </div>
                        </CardBody>
                      </Card>
                    )}

                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title mb-0 flex-grow-1">
                            User Details
                          </h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="card-descrption">
                          {details?.user_name}
                        </div>
                        <div className="card-descrption">
                          {details?.user_email}
                        </div>
                      </CardBody>
                    </Card>

                    {Array.isArray(details?.eventTags) &&
                      details?.eventTags.length > 0 && (
                        <>
                          <Card>
                            <CardHeader>
                              <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">
                                  Tags
                                </h5>
                              </div>
                            </CardHeader>
                            <CardBody>
                              <div className="interest-list">
                                {Array.isArray(details?.eventTags) &&
                                  details.eventTags.map((item, index) => {
                                    return (
                                      <>
                                        <div
                                          className="interest-item"
                                          key={item._id}
                                        >
                                          <div className="interest-title">
                                            {item?.title}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      )}
                  </>
                )}
              </div>
            </Col>
            <Col lg={4}>
              <div id="orderList">
                {details && (
                  <>
                    <Card>
                      <CardHeader>
                        <div className="d-flex align-items-center">
                          <h5 className="card-title mb-0 flex-grow-1">Image</h5>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="image-container">
                          <img className="image" src={details?.image} alt="" />
                        </div>
                      </CardBody>
                    </Card>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default EventsDetails;
