import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateFaq,
  resetFaqFlag,
  getCategories,
  getFaqDetails,
} from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const FaqEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const { Settingsdetails } = useSelector((state) => ({
    Settingsdetails: state.Sites.details,
  }));
  document.title = "Edit Faq | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [category_type, setCategoryType] = useState("faqs");
  const [status, setStatus] = useState();
  const [answer, setData] = useState();
  const { isFaqUpdate, isFaqUpdateFail, error, success, details, categories } =
    useSelector((state) => ({
      isFaqUpdate: state.Faq.isFaqUpdate,
      isFaqUpdateFail: state.Faq.isFaqUpdateFail,
      error: state.Faq.error,
      success: state.Faq.success,
      details: state.Faq.details,
      categories: state.Categories.categories,
    }));

  const [selectedCattypeOption, setSelectedCattypeOption] = useState({
    value: details && details?.category ? details.category?._id : "",
    label: details && details?.category ? details.category?.name : "",
  });
  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");

      setMetaTitle(response.data.title);
    };
    request();
  }, []);
  useEffect(() => {
    setSelectedCattypeOption({
      value: details?.category ? details.category?._id : "",
      label: details && details?.category ? details.category?.name : "",
    });
  }, [details]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      question: details && details?.question ? details.question : "",
      answer: details && details?.answer ? details.answer : "",
      category: selectedCattypeOption,
    },

    validationSchema: Yup.object({
      question: Yup.string().required("Please Enter Your Question"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        question: values.question,
        answer: answer,
        category: selectedCattypeOption.value,
      };
      dispatch(updateFaq(data));
    },
  });

  useEffect(() => {
    if (isFaqUpdate || isFaqUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFaqFlag());
        history.push("/faqs");
      }, 3000);
    }
  }, [dispatch, isFaqUpdate, isFaqUpdateFail, validation, history]);

  useEffect(() => {
    dispatch(getFaqDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCategories({ category_type, status }));
  }, [dispatch, category_type, status]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Faq" pageTitle="Faqs" pageUrl="/faqs" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Faq" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Question *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Question"
                              name="question"
                              value={validation.values.question || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.question &&
                                validation.errors.question
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.question &&
                            validation.errors.question ? (
                              <FormFeedback type="invalid">
                                {validation.errors.question}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={selectedCattypeOption || ""}
                              name="category"
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    value: selectedCatType,
                                  },
                                };

                                setSelectedCattypeOption(event.target.value);
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={
                                categories.length &&
                                categories.map((item, index) => ({
                                  label: item.name,
                                  value: item._id,
                                }))
                              }
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category &&
                                validation.errors.category
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category &&
                            validation.errors.category ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Answer*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="answer"
                              data={validation.values.answer || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.answer &&
                            validation.errors.answer ? (
                              <FormFeedback type="invalid">
                                {validation.errors.answer}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link to="/faqs" className="btn btn-cancel w-sm me-1">
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Update{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Update</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isFaqUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFaqUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FaqEdit;
