import {
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  ADD_NEW_MEMBER_SUCCESS,
  ADD_NEW_MEMBER_FAIL,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAIL,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAIL,
  STATUS_CHANGE_MEMBER_SUCCESS,
  STATUS_CHANGE_MEMBER_FAIL,
  RESET_MEMBER_FLAG,
  MODIFY_MEMBER_FLAG,
  SAVE_ORDERING_MEMBER_SUCCESS,
  SAVE_ORDERING_MEMBER_FAIL,
  SAVE_ORDERING_MEMBER_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  members: [],
  isSuccess: false,
};

const Members = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_MEMBERS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };
    case ADD_NEW_MEMBER_SUCCESS:
      return {
        ...state,
        members: [...state.members, action.payload.data],
        success: action.payload.message,
        isMemberAdd: true,
        isMemberAddFail: false,
      };

    case ADD_NEW_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMemberAdd: false,
        isMemberAddFail: true,
      };
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.map((member) =>
          member._id.toString() === action.payload.data._id.toString()
            ? { ...member, ...action.payload.data }
            : member
        ),
        success: action.payload.message,
        isMemberUpdate: true,
        isMemberUpdateFail: false,
      };

    case UPDATE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isMemberUpdate: false,
        isMemberUpdateFail: true,
      };

    case GET_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };

    case GET_MEMBER_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    //Save Ordering
    case SAVE_ORDERING_MEMBER_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_MEMBER_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        members: state.members.filter((getData) => {
          return getData._id !== action.payload.data._id;
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isMemberRemove: true,
        isMemberRemoveFail: false,
      };

    case DELETE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isMemberRemove: false,
        isMemberRemoveFail: true,
      };
    //Status Change

    case STATUS_CHANGE_MEMBER_SUCCESS:

      return {
        ...state,
        members: state.members.map((member) =>
          action.payload.members.id.includes(member._id)
            ? { ...member, status: action.payload.members.column_value }
            : member
        ),
        success: action.payload.message,
        statusSuccess: true,
        isSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_MEMBER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_MEMBER_FLAG:
      return {
        ...state,
        isMemberAdd: false,
        isMemberAddFail: false,
        isSuccess: true,
        statusSuccess: false,
        isMemberDetails: false,
        isMemberUpdate: false,
        isMemberUpdateFail: false,
        error: false,
      };

    case MODIFY_MEMBER_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Members;
