import React, { useEffect, useState } from "react";
import { getUsers } from "../../store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import { useDispatch } from "react-redux";
import "moment-timezone";
import { useParams } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import UserNav from "../../Components/UserNavigation/UserNav";
import Loader from "../../Components/Common/Loader";
import Moment from "react-moment";

function DraftList() {
  const { usrId } = useParams();
  const [posts, setPosts] = useState();
  const [sortBy, setSortBy] = useState("lastCreated");
  const searchParams = new URLSearchParams(window.location.search).get("type");
  const [limit, setLimit] = useState(10);
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);
  const [deletedPostId, setDeletedPostId] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers({ type: "user" }));
  }, []);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    const draftRequest = async () => {
      setLoader(true);
      const response = await api.create("admimdraft-post/list", {
        limit: limit,
        offset: offset,
        user_id: usrId,
      });
      if (response.status === 200) {
        setPosts(response?.data);
        setLoader(false);
      }
    };
    draftRequest();
  }, []);

  const draftDel = async (delId) => {
    setLoader(true);
    const response = await api.create(
      "admin-draft-post/delete",
      {
        id: delId,
      },
      {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("accesstoken"),
        },
      }
    );
    if (response.status === 200) {
      setDeletedPostId(delId);
      let myElement = document.getElementById(delId);
      myElement.remove();
      setLoader(false);
    }
  };

  document.title = "Drafts List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row>
            {usrId && <UserNav user_id={usrId} />}

            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Drafts List List
                    </h5>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="100">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {!loader && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(posts) && posts.length > 0 && (
                        <table
                          className="table align-middle table-nowrap"
                          id={"customerTable"}
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Image</th>
                              <th>Title</th>
                              {posts?.subtitle && <th>Subtitle</th>}

                              <th>Description</th>
                              <th className="sort" data-sort="created_at">
                                Created at
                              </th>
                              <th className="text-end">Action</th>
                            </tr>
                          </thead>

                          <tbody className="list form-check-all">
                            {Array.isArray(posts) &&
                              posts.map((item) => (
                                <tr key={item._id} id={item._id}>
                                  <td>
                                    {" "}
                                    <img
                                      className="image avatar-xs rounded-circle"
                                      src={item.images?.map((img) => {
                                        return img.imgLink;
                                      })}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item.title}</td>
                                  {item?.subtitle && <td>{item?.subtitle}</td>}
                                  {item?.description && (
                                    <td>{item?.description}</td>
                                  )}

                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      >
                                        <Button className="p-0 bg-transparent border-0 text-danger">
                                          <i className="ri-delete-bin-5-line fs-16"></i>
                                        </Button>
                                      </li>
                                      <UncontrolledPopover
                                        trigger="focus"
                                        placement="left"
                                        target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>Delete</PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2">
                                            <Button className="btn btn-sm btn-light">
                                              No
                                            </Button>
                                            <Button
                                              onClick={() => draftDel(item._id)}
                                              className="btn btn-sm btn-danger ms-2"
                                            >
                                              Yes
                                            </Button>
                                          </div>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {loader && <Loader />}
                  {!loader && Array.isArray(posts) && posts.length === 0 && (
                    <h5 className="mt-3 text-center">No Result Found</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default DraftList;
