import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewUser, resetUserFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const AdminCreate = () => {
  const { Settingsdetails } = useSelector((state) => ({
    Settingsdetails: state.Sites.details,
  }));
  document.title = "Create Admin | " + Settingsdetails?.title;
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [image, setImage] = useState();
  const [type, setType] = useState("admin");
  const { isUserAdd, isUserAddFail, error, success } = useSelector((state) => ({
    isUserAdd: state.Users.isUserAdd,
    isUserAddFail: state.Users.isUserAddFail,
    error: state.Users.error,
    success: state.Users.success,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      password: "",
      image: "",
      type: "admin",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        type: values.type,
        image: image,
        email_verified: true,
      };
      dispatch(addNewUser(data));
      setImage("");
    },
  });

  useEffect(() => {
    if (isUserAdd || isUserAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetUserFlag());
      }, 3000);
    }
  }, [dispatch, type, isUserAdd, isUserAddFail, validation]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Admin"
            pageTitle="Admins"
            pageUrl="/admins"
          />
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <PreviewCardHeader title="Create Admin" />
                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={12}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Email *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={validation.values.email || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Password *
                            </Label>
                            <Input
                              type="password"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              value={validation.values.password || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link to="/admins" className="btn btn-cancel w-sm me-1">
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            Create
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Create</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <PreviewCardHeader title="Image *" />
                  <CardBody>
                    <div className="mb-4">
                      <Input
                        type="file"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Place your image"
                        name="image"
                        onChange={onImageChange}
                      />
                    </div>
                    <div className="image-container">
                      <img src={image || ""} alt="" id="image1" />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>

          {isUserAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isUserAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default AdminCreate;
