import { useLocation, Link } from "react-router-dom";

const UserNav = ({ user_id }) => {
  const location = useLocation();

  return (
    <>

        <ul className="tab-navigation-menu">
          <li className="item" title="Edit">
            <Link to={`/user/edit/${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `user` && location.pathname.split('/')[2] === `edit`) ? " active" : "")}>
              <i className="ri-pencil-line me-2"></i> Edit
            </Link>
          </li>
          <li className="item" title="View">
            <Link to={"/user/view/" + user_id} className={"item-link " + ((location.pathname.split('/')[1] === `user` && location.pathname.split('/')[2] === `view`) ? " active" : "")} >
              <i className="ri-eye-line me-2"></i> About
            </Link>
          </li>
          <li className="item" title="Posts">
            <Link to={`/posts/${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `posts`) ? " active" : "")}>
              <i className="ri-pages-line me-2"></i> Your Posts
            </Link>
          </li>
          <li className="item" title="Save Content">
            <Link to={`/saved-content/${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `saved-content`) ? " active" : "")}>
              <i className="ri-pages-line me-2"></i> Saved Content
            </Link>
          </li>
          <li className="item" title="Drafts">
            <Link to={`/draft/${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `draft`) ? " active" : "")}>
              <i className="ri-pages-line me-2"></i> Drafts
            </Link>
          </li>
          <li className="item" title="Connections">
            <Link to={`/connections?user_id=${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `connections`) ? " active" : "")}>
              <i className="ri-links-line me-2"></i> Connections
            </Link>
          </li>
          <li className="item" title="Events">
            <Link to={`/events?user_id=${user_id}`} className={"item-link " + ((location.pathname.split('/')[1] === `events`) ? " active" : "")} >
              {/* {"header-options" + ((location.pathname.split('/')[1] === "/membership")?" membership":"")} */}
              <i className="ri-calendar-event-line me-2"></i> Events
            </Link>
          </li>
        </ul>

    </>
  );
};

export default UserNav;
