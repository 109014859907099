import {
  GET_STEPS_SUCCESS,
  GET_STEPS_FAIL,
  ADD_NEW_STEPS_SUCCESS,
  ADD_NEW_STEPS_FAIL,
  GET_STEPS_DETAILS_SUCCESS,
  GET_STEPS_DETAILS_FAIL,
  UPDATE_STEPS_SUCCESS,
  UPDATE_STEPS_FAIL,
  DELETE_STEPS_SUCCESS,
  DELETE_STEPS_FAIL,
  MODIFY_STEPS_FLAG,
  RESET_STEPS_FLAG,
  STATUS_CHANGE_STEPS_SUCCESS,
  STATUS_CHANGE_STEPS_FAIL,
  FEATURED_CHANGE_STEPS_SUCCESS,
  FEATURED_CHANGE_STEPS_FAIL,
  SAVE_ORDERING_STEPS_SUCCESS,
  SAVE_ORDERING_STEPS_FAIL,
  SAVE_ORDERING_STEPS_RESET_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  steps: [],
  loading: true
};

const Steps = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STEPS_SUCCESS:
      return {
        ...state,
        steps: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
        loading: false
      };

    case GET_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        totalDataCount: 0,
        loading: false
      };

    case ADD_NEW_STEPS_SUCCESS:
      return {
        ...state,
        steps: [...state.steps, action.payload.data],
        success: action.payload.message,
        isStepsAdd: true,
        isStepsAddFail: false,
      };

    case ADD_NEW_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isStepsAdd: false,
        isStepsAddFail: true,
      };

    case UPDATE_STEPS_SUCCESS:
      return {
        ...state,
        steps: state.steps.map((step) =>
          step._id.toString() === action.payload.data._id.toString()
            ? { ...step, ...action.payload.data }
            : step
        ),
        success: action.payload.message,
        isStepsUpdate: true,
        isStepsUpdateFail: false,
      };

    case UPDATE_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isStepsUpdate: false,
        isStepsUpdateFail: true,
      };

    case GET_STEPS_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_STEPS_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_STEPS_SUCCESS:
      return {
        ...state,
        steps: state.steps.filter((getData) => {
          return !action.payload.data.includes(getData._id);
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isStepsRemove: true,
        isStepsRemoveFail: false,
      };

    case DELETE_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isStepsRemove: false,
        isStepsRemoveFail: true,
      };

    //Status Change
    case STATUS_CHANGE_STEPS_SUCCESS:
      return {
        ...state,
        steps: state.steps.map((step) =>
          action.payload.Steps.id.includes(step._id)
            ? { ...step, status: action.payload.Steps.column_value }
            : step
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    // Feature Change
    case FEATURED_CHANGE_STEPS_SUCCESS:
      return {
        ...state,
        steps: state.steps.map((step) =>
          action.payload.steps.id.includes(step._id)
            ? { ...step, featured: action.payload.steps.column_value }
            : step
        ),
        success: action.payload.message,
        featuredSuccess: true,
        featuredFailed: false,
      };

    case FEATURED_CHANGE_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        featuredSuccess: false,
        featuredFailed: true,
      };
    //Save Ordering
    case SAVE_ORDERING_STEPS_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_STEPS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_STEPS_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    case RESET_STEPS_FLAG:
      return {
        ...state,
        isStepsAdd: false,
        isStepsAddFail: false,
        isSuccess: true,
        isStepsDetails: false,
        isStepsUpdate: false,
        isStepsUpdateFail: false,
        error: false,
        statusSuccess: false,
      };
    case MODIFY_STEPS_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Steps;
