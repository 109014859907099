import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewSteps, resetStepsFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const StepCreate = () => {
  document.title = "Create Step | CEO - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const { isStepsAdd, isStepsAddFail, error, success } = useSelector(
    (state) => ({
      isStepsAdd: state.Steps.isStepsAdd,
      isStepsAddFail: state.Steps.isStepsAddFail,
      error: state.Steps.error,
      success: state.Steps.success,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      icon: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Step Name"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.name,
        description: values.description,
        icon: values.icon,
      };
      dispatch(addNewSteps(data));
    },
  });

  useEffect(() => {
    if (isStepsAdd || isStepsAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetStepsFlag());
        history.push("/exclusive-communities");
      }, 1000);
    }
  }, [dispatch, isStepsAdd, isStepsAddFail, validation]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Step"
            pageTitle="Steps"
            pageUrl="/exclusive-communities"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Step" />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={12} md={12}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Step name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <Input
                              type="textarea"
                              id="placeholderInput"
                              rows="3"
                              className="form-control"
                              placeholder="Enter description"
                              name="description"
                              value={validation.values.description || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.description &&
                                validation.errors.description
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link
                      to="/exclusive-communities"
                      className="btn btn-cancel w-sm me-1"
                    >
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Create{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Create</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isStepsAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isStepsAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default StepCreate;
