import {
    GET_CHANNELS_SUCCESS,
    GET_CHANNELS_FAIL,
  } from "./actionType";
  
  const INIT_STATE = {
    channels: []
  };
  
  const Channels = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_CHANNELS_SUCCESS:
        return {
          ...state,
          channels: action.payload.results,
          isSuccess: true
        };

       
  
      case GET_CHANNELS_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false
        };
  
      default:
        return state;
    }
  };
  
  export default Channels;
  