import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewFeatures, resetFeaturesFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

import { convertToBase64 } from "../../../helpers/image";

const FeatureCreate = () => {
  document.title = "Create Feature | CEO - Admin Panel";
  const dispatch = useDispatch();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const [logo, setLogo] = useState();

  const { isFeaturesAdd, isFeaturesAddFail, error, success } = useSelector(
    (state) => ({
      isFeaturesAdd: state.Features.isFeaturesAdd,
      isFeaturesAddFail: state.Features.isFeaturesAddFail,
      error: state.Features.error,
      success: state.Features.success,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      button_link: "",
      button_text: "",
      icon: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Feature Name"),
      button_link: Yup.string().required("Please Enter Button Link"),
      button_text: Yup.string().required("Please Enter Button Text"),
      icon: Yup.string().required("Please Enter Feature Icon"),
      description: Yup.string().required("Please Enter Description"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.name,
        button_link: values.button_link,
        button_text: values.button_text,
        icon: values.icon,
        description: values.description,
        image: logo,
      };
      dispatch(addNewFeatures(data));
    },
  });

  useEffect(() => {
    if (isFeaturesAdd || isFeaturesAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetFeaturesFlag());
        history.push("/features");
      }, 1000);
    }
  }, [dispatch, isFeaturesAdd, isFeaturesAddFail, validation]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onimageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setLogo);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Feature"
            pageTitle="Features"
            pageUrl="/features"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={8}>
                  <Card>
                    <PreviewCardHeader title="Create Feature" />

                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col lg={12}>
                            <Row>
                              <Col xxl={6} md={6}>
                                <div className="mb-4">
                                  <Label
                                    htmlFor="placeholderInput"
                                    className="form-label"
                                  >
                                    Name*
                                  </Label>
                                  <Input
                                    type="text"
                                    id="placeholderInput"
                                    className="form-control"
                                    placeholder="Feature name"
                                    name="name"
                                    value={validation.values.name || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    invalid={
                                      validation.touched.name &&
                                      validation.errors.name
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.name &&
                                  validation.errors.name ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.name}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col xxl={6} md={6}>
                                <div className="mb-4">
                                  <Label
                                    htmlFor="placeholderInput"
                                    className="form-label"
                                  >
                                    Icon*
                                  </Label>
                                  <Input
                                    type="text"
                                    id="placeholderInput"
                                    className="form-control"
                                    placeholder="Icon"
                                    name="icon"
                                    value={validation.values.icon || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    invalid={
                                      validation.touched.icon &&
                                      validation.errors.icon
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.icon &&
                                  validation.errors.icon ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.icon}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col xxl={6} md={6}>
                                <div className="mb-4">
                                  <Label
                                    htmlFor="placeholderInput"
                                    className="form-label"
                                  >
                                    Button Link*
                                  </Label>
                                  <Input
                                    type="text"
                                    id="placeholderInput"
                                    className="form-control"
                                    placeholder="Button link"
                                    name="button_link"
                                    value={validation.values.button_link || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    invalid={
                                      validation.touched.button_link &&
                                      validation.errors.button_link
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.button_link &&
                                  validation.errors.button_link ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.button_link}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col xxl={6} md={6}>
                                <div className="mb-4">
                                  <Label
                                    htmlFor="placeholderInput"
                                    className="form-label"
                                  >
                                    Button Text*
                                  </Label>
                                  <Input
                                    type="text"
                                    id="placeholderInput"
                                    className="form-control"
                                    placeholder="Button text"
                                    name="button_text"
                                    value={validation.values.button_text || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    invalid={
                                      validation.touched.button_text &&
                                      validation.errors.button_text
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.button_text &&
                                  validation.errors.button_text ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.button_text}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col xxl={12} md={12}>
                                <div className="mb-4">
                                  <Label
                                    htmlFor="placeholderInput"
                                    className="form-label"
                                  >
                                    Description*
                                  </Label>
                                  <Input
                                    type="textarea"
                                    id="placeholderInput"
                                    rows="3"
                                    className="form-control"
                                    placeholder="Enter description"
                                    name="description"
                                    value={validation.values.description || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    validate={{
                                      required: { value: true },
                                    }}
                                    invalid={
                                      validation.touched.description &&
                                      validation.errors.description
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.description &&
                                  validation.errors.description ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.description}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Link to="/features" className="btn btn-cancel w-sm me-1">
                        Cancel
                      </Link>
                      <Button
                        color="primary"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Create{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Create</span>
                        </span>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card>
                    <PreviewCardHeader title="Feature Image" />
                    <CardBody>
                      <div className="mb-4">
                        <Input
                          type="file"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Place your image"
                          name="image"
                          onChange={onimageChange}
                        />
                      </div>
                      <div className="image-container">
                        <img
                          src={validation.values.iamage || ""}
                          alt=""
                          id="image1"
                        />
                      </div>
                      {validation.touched.iamage && validation.errors.iamage ? (
                        <FormFeedback type="invalid">
                          {validation.errors.iamage}
                        </FormFeedback>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Row>
          {isFeaturesAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isFeaturesAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default FeatureCreate;
