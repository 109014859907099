import {
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  ADD_NEW_ROLES_SUCCESS,
  ADD_NEW_ROLES_FAIL,
  GET_ROLES_DETAILS_SUCCESS,
  GET_ROLES_DETAILS_FAIL,
  MODIFY_ROLE_FLAG,
  RESET_ROLE_FLAG
} from "./actionTypes";

const INIT_STATE = {
  roles: []
};

const Roles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true
      };

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        totalDataCount: 0,
      };

      case ADD_NEW_ROLES_SUCCESS:
         
        return {
          ...state,
          roles: [...state.roles, action.payload.data],
          success: action.payload.message,
          isRolesAdd: true,
          isRolesAddFail: false,
        };
  
      case ADD_NEW_ROLES_FAIL:
        
        return {
          ...state,
          error: action.payload.message,
          isrolesAdd: false,
          isrolesAddFail: true,
        };

        case GET_ROLES_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
          isSuccess: true,
        };
      case GET_ROLES_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };

        case RESET_ROLE_FLAG:
      return {
        ...state,
        isrolesAdd: false,
        isrolesAddFail: false,
        isSuccess: false,
        isRoleDetails: false,
        isRoleUpdate: false,
        isRoleUpdateFail: false,
        error: false,
      };

      case MODIFY_ROLE_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Roles;
