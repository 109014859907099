import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  ADD_NEW_FAQ,
  DELETE_FAQ,
  GET_FAQS,
  GET_FAQ_DETAILS,
  UPDATE_FAQ,
  STATUS_CHANGE_FAQ,
  SAVE_ORDERING_FAQ,
  FEATURED_CHANGE_FAQ
} from "./actionType"

import {
  getFaqsSuccess,
  getFaqsFail,
  addFaqFail,
  addFaqSuccess,
  getFaqDetailsSuccess,
  getFaqDetailsFail,
  updateFaqSuccess,
  updateFaqFail,
  deleteFaqSuccess,
  deleteFaqFail,
  statusChangeFaqSuccess,
  statusChangeFaqFail,
  saveOrderingFaqSuccess,
  saveOrderingFaqFail,
  featuredChangeFaqSuccess,
  featuredChangeFaqFail
} from "./action"

//Include Both Helper File with needed methods
import {
  getFaqsList,
  addNewFaq,
  updateFaq,
  getFaqDetails,
  deleteFaq,
  statusChange,
  saveOrdering,
  
} from "../../helpers/fakebackend_helper"

function* fetchFaqs({ payload: data }) {
  try {
    const response = yield call(getFaqsList, data)
    yield put(getFaqsSuccess(response))
  } catch (error) {
    yield put(getFaqsFail(error))
  }
}

function* onAddNewFaq({ payload: faqs }) {
  try {
    const response = yield call(addNewFaq, faqs)
    yield put(addFaqSuccess(response))
  } catch (error) {
    yield put(addFaqFail(error))
  }
}

function* onUpdateFaq({ payload: faqs }) {
  try {
    const response = yield call(updateFaq, faqs)
    yield put(updateFaqSuccess(response))
  } catch (error) {
    yield put(updateFaqFail(error))
  }
}

function* onDeleteFaq({ payload: faqs }) {
  try {
    const response = yield call(deleteFaq, faqs)
    yield put(deleteFaqSuccess(response))
  } catch (error) {
    yield put(deleteFaqFail(error))
  }
}

function* onFaqDetails({ payload: faq }) {
  try {
      const response = yield call(getFaqDetails, faq);
      if(response.status === 200){
        yield put(getFaqDetailsSuccess(response));
      } else {
        yield put(getFaqDetailsFail(response));
      }
  } catch (error) {
      yield put(getFaqDetailsFail(error));
  }
}

//Status Change
function* onStatusChangeFaq({ payload: faqs }) {
  try {
    const response = yield call(statusChange, faqs);
    yield put(statusChangeFaqSuccess({ faqs, ...response }));
  } catch (error) {
    yield put(statusChangeFaqFail(error));
  }
}

// Featured Change
function* onFeaturedChangeFaq({ payload: faqs }) {
  try {
    const response = yield call(statusChange, faqs);
    yield put(featuredChangeFaqSuccess({ faqs, ...response }));
  } catch (error) {
    yield put(featuredChangeFaqFail(error));
  }
}

//Save Ordering
function* onSaveOrderingFaq({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingFaqSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingFaqFail(error));
  }
}
function* FaqSaga() {
  yield takeEvery(GET_FAQS, fetchFaqs)
  yield takeEvery(ADD_NEW_FAQ, onAddNewFaq)
  yield takeEvery(UPDATE_FAQ, onUpdateFaq)
  yield takeEvery(DELETE_FAQ, onDeleteFaq)
  yield takeEvery(STATUS_CHANGE_FAQ, onStatusChangeFaq);
  yield takeEvery(FEATURED_CHANGE_FAQ, onFeaturedChangeFaq);
  yield takeEvery(SAVE_ORDERING_FAQ, onSaveOrderingFaq);
  yield takeEvery(GET_FAQ_DETAILS, onFaqDetails);


}

export default FaqSaga
