import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

// Category
import categorySaga from "./categories/saga";
// Playlist
import PlaylistSaga from "./playlist/saga";
// Steps
import StepsSaga from "./steps/saga";

// Role
import roleSaga from "./role/saga";

// Features
import FeaturesSaga from "./features/saga";

// Members
import MemberSaga from "./member/saga";

//User
import userSaga from "./users/saga";

// Faqs
import FaqSaga from "./Faqs/saga";
// Tags
import TagSaga from "./tags/saga";

// Events
import EventSaga from "./events/saga";

// Banner
import bannerSaga from "./banner/saga";

//Site
import siteSaga from "./sites/saga";

// Page
import pageSaga from "./page/saga";

// Channels
import ChannelSaga from "./channels/saga";

// Connections
import ConnectionSaga from "./connection/saga";

// SEO
import seoSaga from "./seo/saga";

//Contact
import contactSaga from "./contacts/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(categorySaga),
    fork(FaqSaga),
    fork(TagSaga),
    fork(userSaga),
    fork(bannerSaga),
    fork(ConnectionSaga),
    fork(siteSaga),
    fork(pageSaga),
    fork(roleSaga),
    fork(PlaylistSaga),
    fork(StepsSaga),
    fork(FeaturesSaga),
    fork(seoSaga),
    fork(EventSaga),
    fork(ChannelSaga),
    fork(contactSaga),
    fork(MemberSaga),
  ]);
}
