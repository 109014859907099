import { useEffect } from "react";
import Moment from "react-moment";
import axios from "axios";
import { useState } from "react";
import hostUrl from "../../../config/host";
import { Link } from "react-router-dom";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
function ListData({ item }) {
  const [isMuted, setIsmuted] = useState(false);
  useEffect(() => {
    setIsmuted(item.channel.isMuted);
  }, [item.channel.isMuted]);

  const token = "d7a8b0dedcb87d11824170d254f8c3824d462142";
  const muteChannel = (channelId, mutePeriod) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .put(
        `${hostUrl}/v2/channel/${channelId}/mute`,
        { mutePeriod: mutePeriod },
        { headers: headers }
      )
      .then(function (response) {
        if (response.success === true) {
          if (mutePeriod === 0) {
            setIsmuted(false);
          } else {
            setIsmuted(true);
          }
        }
      })
      .catch(function (error) {});
  };
  return (
    <>
      {" "}
      <tr key={item.channel._id}>
        <td>
          <Link to={"/channel/" + item?.channel?.channelId}>
            {item?.channel?.channelId}
          </Link>
        </td>
        <td>{item.channel.displayName}</td>
        <td>{item.channel.memberCount}</td>
        <td>{item.channel.messageCount}</td>
        <td>{item.channelUser.membership}</td>
        <td>
          <Moment format="D MMM YYYY">{item.channel.created_at}</Moment>
        </td>
        <td>
          <ul className="list-inline hstack gap-2 mb-0">
            <li
              className="list-inline-item"
              title="Remove"
              id={"deletePopover" + item?.channel?.channelId}
            >
              <Button className="p-0 bg-transparent border-0">
                {isMuted === true ? (
                  <i className="ri-volume-mute-line fs-18 text-danger"></i>
                ) : isMuted === false ? (
                  <i className="ri-volume-up-line fs-18 text-primary"></i>
                ) : (
                  ""
                )}
              </Button>
            </li>
            <UncontrolledPopover
              trigger="focus"
              placement="left"
              target={"deletePopover" + item?.channel?.channelId}
            >
              <PopoverBody>
                Do you want to{" "}
                {item.channel.isMuted ? "unmute channel" : "mute channel"}{" "}
                {item?.channel?.channelId}?
                <div className="d-flex align-items-center justify-content-end mt-2">
                  <Button className="btn btn-sm btn-light">No</Button>
                  <Button
                    onClick={() =>
                      muteChannel(item.channel.channelId, isMuted ? 0 : -1)
                    }
                    className="btn btn-sm btn-success ms-2"
                  >
                    Confirm
                  </Button>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </ul>
        </td>
      </tr>
    </>
  );
}

export default ListData;
