import {
  GET_PROFILE_SUCCESS,
  GET_PROFILE_ERROR,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
} from "./actionTypes";

const initialState = {
  user: {},
  getuser: [],
  isUserUpdate: false
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getuser: action.payload.data,
        isSuccess: true,
        isUserUpdate: true,
        isUserUpdateFail: false,
      };

    case GET_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        isUserUpdate: false,
        isUserUpdateFail: true,
      };
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        isSuccess: true,
        success: action.payload.message,
        user: action.payload.data,
        isUserUpdate: true,
        isUserUpdateFail: false,
      };
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        isUserUpdate: false,
        isUserUpdateFail: true,
      };
      break;
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        success: false,
        isSuccess: true,
        error: false,
        isUserUpdate: false,
        isUserUpdateFail: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Profile;
