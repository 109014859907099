/* EVENTS */
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const MODIFY_EVENT_FLAG = "MODIFY_EVENT_FLAG";

export const RESET_EVENT_FLAG = "RESET_EVENT_FLAG";

export const GET_EVENT_DETAILS = "GET_EVENT_DETAILS";
export const GET_EVENT_DETAILS_SUCCESS = "GET_EVENT_DETAILS_SUCCESS";
export const GET_EVENT_DETAILS_FAIL = "GET_EVENT_DETAILS_FAIL";

// STATUS CHANGE
export const STATUS_CHANGE_EVENT = "STATUS_CHANGE_EVENT";
export const STATUS_CHANGE_EVENT_SUCCESS = "STATUS_CHANGE_EVENT_SUCCESS";
export const STATUS_CHANGE_EVENT_FAIL = "STATUS_CHANGE_EVENT_FAIL";


// Save Ordering
export const SAVE_ORDERING_EVENT = "SAVE_ORDERING_EVENT";
export const SAVE_ORDERING_EVENT_SUCCESS = "SAVE_ORDERING_EVENT_SUCCESS";
export const SAVE_ORDERING_EVENT_FAIL = "SAVE_ORDERING_EVENT_FAIL";
export const SAVE_ORDERING_EVENT_RESET_FLAG = "SAVE_ORDERING_EVENT_RESET_FLAG";
