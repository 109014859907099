import { useEffect, useState } from "react";
import { APIClient } from "../../helpers/api_helper";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  FormFeedback,
  CardBody,
  Input,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { editCategory, updateCategory } from "../../store/actions";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Edit() {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Category | " + metatitle;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCatType, setselectedCatType] = useState("");
  const [catType, setCatType] = useState("");
  const { id } = useParams();

  const categoryType = [
    {
      options: [
        { label: "Videos", value: "videos" },
        { label: "Blogs", value: "blogs" },
        { label: "FAQS", value: "faqs" },
      ],
    },
  ];

  useEffect(() => {
    dispatch(editCategory({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const { category, isSuccess, error } = useSelector((state) => ({
    category: state.Categories.category,
    isSuccess: state.Categories.isSuccess,
    error: state.Categories.error,
  }));
  useEffect(() => {
    if (category && category._id) {
      setCatType({
        label: category.category_type.replace(/\b\w/g, (x) => x.toUpperCase()),
        value: category.category_type,
      });
    }
  }, [category]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "",
      category_type: selectedCatType || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Category Name"),
    }),
    onSubmit: (values) => {
      const data = {
        id: id,
        name: values.name,
        category_type: values.category_type?.value,
        status: 1,
      };
      const res = dispatch(updateCategory(data));
      toast("Category Successfully updated");
    },
  });

  return (
    <>
      {" "}
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Category"
            pageTitle="Categories"
            pageUrl="/categories"
          />
          <Row>
            <Col lg={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <PreviewCardHeader title="Edit Category" />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Category Name
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              name="name"
                              className="form-control"
                              value={validation.values.name || ""}
                              placeholder="Category name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </Col>
                        <Col xxl={2} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Category Type
                            </Label>
                            <Select
                              value={catType || ""}
                              onChange={(selectedCatType) => {
                                let event = {
                                  target: {
                                    name: "category_type",
                                    value: selectedCatType,
                                  },
                                };

                                setCatType(event.target.value);
                                validation.handleChange(event);
                              }}
                              onBlur={validation.handleBlur}
                              options={categoryType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.category_type &&
                                validation.errors.category_type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.category_type &&
                            validation.errors.category_type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.category_type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link
                      to="/faq-categories"
                      className="btn btn-cancel w-sm me-1"
                    >
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary w-sm">
                      Update
                    </button>
                  </CardFooter>
                </Card>
              </form>
            </Col>
          </Row>
        </Container>
      </div>{" "}
      <ToastContainer />
    </>
  );
}

export default Edit;
