import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updatePlaylist,
  resetPlaylistFlag,
  getPlaylistDetails,
} from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { APIClient } from "../../../helpers/api_helper";
import PlaylistTabs from "../../../Components/TabNavigation/PlaylistTabs";

const PlaylistEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Playlist | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isCreating, setIsCreating] = useState(false);
  const [description, setData] = useState("");

  const { isPlaylistUpdate, isPlaylistUpdateFail, error, success, details } =
    useSelector((state) => ({
      isPlaylistUpdate: state.Playlists.isPlaylistUpdate,
      isPlaylistUpdateFail: state.Playlists.isPlaylistUpdateFail,
      error: state.Playlists.error,
      success: state.Playlists.success,
      details: state.Playlists.details,
    }));

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  function handleSelectType(label, value) {
    SetVideoType({ label: label, value: value });
  }
  const [VideoType, SetVideoType] = useState();
  const optionsVideoType = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
    { value: "spotify", label: "Spotify" },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details?.name ? details.name : "",
      playlist_id: details && details?.playlist_id ? details.playlist_id : "",
      description: details && details?.description ? details.description : "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      playlist_id: Yup.string().required("Please Enter Playlist ID"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);

      if (VideoType.value === "youtube") {
        fetch(
          "https://www.googleapis.com/youtube/v3/playlists?key=AIzaSyCrfGfmOlRRWGP2hFQ1qQ6e5PBqoKl-Qn4&part=snippet&id=" +
            values.playlist_id
        )
          .then((res) => res.json())
          .then((result) => {
            const data = {
              id: id,
              name: values.name,
              type: VideoType.value,
              playlist_id: values.playlist_id,
              description: description,
              image: result.items[0].snippet.thumbnails.maxres.url,
            };
            dispatch(updatePlaylist(data));
          });
      }

      if (VideoType.value === "vimeo") {
        fetch("https://api.vimeo.com/channels/" + values.playlist_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer 0a81b84c241427063ecf50227ca56f35",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            const data = {
              id: id,
              name: values.name,
              type: VideoType.value,
              playlist_id: values.playlist_id,
              description: description,
              image: result.pictures.base_link,
            };

            dispatch(updatePlaylist(data));
          });
      }

      if (VideoType.value === "spotify") {
        fetch("https://ceofront.iosx.in/spotify_token.php")
          .then((res) => res.text())
          .then(
            (result) => {
              fetch(
                "https://api.spotify.com/v1/playlists/" + values.playlist_id,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + result,
                  },
                }
              )
                .then((res) => res.json())
                .then((result) => {
                  const data = {
                    id: id,
                    name: values.name,
                    type: VideoType.value,
                    playlist_id: values.playlist_id,
                    image: result.images[0].url,
                    description: description,
                  };

                  dispatch(updatePlaylist(data));
                });
            },

            (error) => {}
          );
      }
    },
  });

  useEffect(() => {
    SetVideoType({
      value: details?.type,
      label:
        details?.type === "youtube"
          ? "Youtube"
          : details?.type === "vimeo"
          ? "Vimeo"
          : details?.type === "spotify"
          ? "Spotify"
          : "",
    });
  }, [details]);

  useEffect(() => {
    if (isPlaylistUpdate || isPlaylistUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetPlaylistFlag());
        window.location.href = `/playlist?type=${
          details.type ? details.type : "youtube"
        }`;
      }, 3000);
    }
  }, [dispatch, isPlaylistUpdate, isPlaylistUpdateFail, validation]);

  useEffect(() => {
    dispatch(getPlaylistDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Playlist"
            pageTitle="Playlists"
            pageUrl="/playlist"
          />
          <PlaylistTabs id={id} type={details.type} playlist_id={validation.values.playlist_id}/>
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Playlist" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Playlist ID*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Playlist ID"
                              name="playlist_id"
                              value={validation.values.playlist_id || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.playlist_id &&
                                validation.errors.playlist_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.playlist_id &&
                            validation.errors.playlist_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.playlist_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Video Type
                            </Label>
                            <Select
                              value={VideoType || ""}
                              onChange={(e) =>
                                handleSelectType(e.label, e.value)
                              }
                              onBlur={validation.handleBlur}
                              options={optionsVideoType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link
                      to={`/playlist?type=${details?.type}`}
                      className="btn btn-cancel w-sm me-1"
                    >
                      Cancel
                    </Link>
                    <Button color="primary" disabled={isCreating} type="submit">
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Update{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Update</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isPlaylistUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isPlaylistUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default PlaylistEdit;
