import {
    GET_EVENTS,
    GET_EVENTS_FAIL,
    GET_EVENTS_SUCCESS,
    ADD_NEW_EVENT,
    ADD_EVENT_SUCCESS,
    ADD_EVENT_FAIL,
    GET_EVENT_DETAILS,
    GET_EVENT_DETAILS_SUCCESS,
    GET_EVENT_DETAILS_FAIL,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    DELETE_EVENT,
    RESET_EVENT_FLAG,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_FAIL,
    STATUS_CHANGE_EVENT,
    STATUS_CHANGE_EVENT_SUCCESS,
    STATUS_CHANGE_EVENT_FAIL,
    MODIFY_EVENT_FLAG,
    SAVE_ORDERING_EVENT,
    SAVE_ORDERING_EVENT_SUCCESS,
    SAVE_ORDERING_EVENT_FAIL,
    SAVE_ORDERING_EVENT_RESET_FLAG,
  } from "./actionTypes";
  
  export const getEvents = data => ({
    type: GET_EVENTS,
    payload: data,
  });
  
  export const getEventsSuccess = data => ({
    type: GET_EVENTS_SUCCESS,
    payload: data,
  });
  
  export const getEventsFail = error => ({
    type: GET_EVENTS_FAIL,
    payload: error,
  });
  
  export const getEventDetails = events => ({
    type: GET_EVENT_DETAILS,
    payload: events,
  });
  
  export const getEventDetailsSuccess = events => ({
    type: GET_EVENT_DETAILS_SUCCESS,
    payload: events,
  });
  
  export const getEventDetailsFail = error => ({
    type: GET_EVENT_DETAILS_FAIL,
    payload: error,
  });
  
  export const addNewEvent = events => ({
    type: ADD_NEW_EVENT,
    payload: events,
  });
  
  export const addEventSuccess = events => ({
    type: ADD_EVENT_SUCCESS,
    payload: events,
  });
  
  export const addEventFail = error => ({
    type: ADD_EVENT_FAIL,
    payload: error,
  });
  
  export const updateEvent = events => ({
    type: UPDATE_EVENT,
    payload: events,
  });
  
  export const updateEventSuccess = events => ({
    type: UPDATE_EVENT_SUCCESS,
    payload: events,
  });
  
  export const updateEventFail = error => ({
    type: UPDATE_EVENT_FAIL,
    payload: error,
  });
  
  export const deleteEvent = events => ({
    type: DELETE_EVENT,
    payload: events,
  });
  
  export const deleteEventSuccess = events => ({
    type: DELETE_EVENT_SUCCESS,
    payload: events,
  });
  
  export const deleteEventFail = error => ({
    type: DELETE_EVENT_FAIL,
    payload: error,
  });
  
  export const modifyEventFlag = () => {
    return {
      type: MODIFY_EVENT_FLAG,
    }
  }
  
  export const resetEventFlag = () => {
    return {
      type: RESET_EVENT_FLAG,
    }
  }
  // Status Chaange
  export const statusChangeEvent = events => ({
    type: STATUS_CHANGE_EVENT,
    payload: events,
  });
  
  export const statusChangeEventSuccess = events => ({
    type: STATUS_CHANGE_EVENT_SUCCESS,
    payload: events,
  });
  
  export const statusChangeEventFail = error => ({
    type: STATUS_CHANGE_EVENT_FAIL,
    payload: error,
  });
  
  
  // Save ordering
  
  export const saveOrderingEvent = data => ({
    type: SAVE_ORDERING_EVENT,
    payload: data,
  });
  
  export const saveOrderingEventSuccess = data => ({
    type: SAVE_ORDERING_EVENT_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingEventFail = error => ({
    type: SAVE_ORDERING_EVENT_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingEventResetFlag = () => {
    return {
      type: SAVE_ORDERING_EVENT_RESET_FLAG,
    }
  }
  