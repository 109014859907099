import { Link } from "react-router-dom";

function MemberNav({ id }) {
  return (
    <>
      <ul className="tab-navigation-menu">
        <li className="item">
          <Link to={`/page/edit/caught-it`}>
            SERIOUSLY,YOU STILL HAVEN'T CAUGHT IT.
          </Link>
        </li>
        <li className="item">
          <Link to={`/features`}>Features</Link>
        </li>
        <li className="item">
          <Link to={`/exclusive-communities`}>Exclusive Community</Link>
        </li>
        <li className="item">
          <Link to={`/members?type=membership`}>Become a Member</Link>
        </li>
        {/* <li className="item">
          <Link to={`/seo/page/${id}`}>SEO</Link>
        </li> */}

        {/* <li className="item">
          <Link to={`/page/edit/premium-access`}>PREMIUM ACCESS</Link>
        </li> */}
      </ul>
    </>
  );
}

export default MemberNav;
