import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { APIClient } from "../../helpers/api_helper";

const ChangePassword = () => {
  const api = new APIClient();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const request = async () => {
    const response = await api.create("change-password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    });
    if (response.status === 200) {
      toast.success(response.message);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      toast.error(response.message);
    }
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <h4 className="card-title mb-4">Change Password</h4>

            <Card>
              <CardBody>
                <Form className="form-horizontal">
                  <Row>
                    <Col xxl={6} md={6}>
                      <div>
                        <Label
                          htmlFor="placeholderInput"
                          className="form-label"
                        >
                          Old Password *
                        </Label>
                        <Input
                          type="text"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Password"
                          name="oldPassword"
                          value={oldPassword || ""}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col xxl={6} md={6}>
                      <div>
                        <Label
                          htmlFor="placeholderInput"
                          className="form-label"
                        >
                          New Password *
                        </Label>
                        <Input
                          type="text"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={newPassword || ""}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </div>
                    </Col>

                    <Col xxl={6} md={6}>
                      <div>
                        <Label
                          htmlFor="placeholderInput"
                          className="form-label"
                        >
                          Confirm Password *
                        </Label>
                        <Input
                          type="text"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Password"
                          name="password"
                          value={confirmPassword || ""}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-4">
                    <Button
                      type="button"
                      color="danger"
                      onClick={() => request()}
                    >
                      Change Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    </>
  );
};

export default ChangePassword;
