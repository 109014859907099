import { useEffect, useState } from "react";
import { APIClient } from "../../../helpers/api_helper";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewTag, resetTagFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const TagCreate = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Create Tag | " + metatitle;
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const searchParams = new URLSearchParams(window.location.search).get(
    "tag_type"
  );
  const [tag_type, settag_type] = useState(searchParams);
  const { isTagAdd, isTagAddFail, error, success } = useSelector((state) => ({
    isTagAdd: state.Tag.isTagAdd,
    isTagAddFail: state.Tag.isTagAddFail,
    error: state.Tag.error,
    success: state.Tag.success,
  }));

  useEffect(() => {
    settag_type(searchParams);
  }, [searchParams]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        tag_type: tag_type,
      };
      dispatch(addNewTag(data));
    },
  });

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {
        id: "624c1d79ac364e5ef3796d76",
      });
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    if (isTagAdd || isTagAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetTagFlag());
        window.location.href = `/tags?tag_type=${tag_type}`;
      }, 2000);
    }
  }, [dispatch, isTagAdd, isTagAddFail, validation, tag_type]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create Tag" pageTitle="Tags" pageUrl="/tags" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Tag" />
                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link
                      to={`/tags?tag_type=${tag_type}`}
                      className="btn btn-cancel w-sm me-1"
                    >
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            Create
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Create</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isTagAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isTagAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default TagCreate;
