import React, { useEffect, useState } from "react";
import axios from "axios";
import hostUrl from "../../config/host";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import useAxios from "../../helpers/useAxios";

import Loader from "../../Components/Common/Loader";
import Moment from "react-moment";
import "moment-timezone";
import { useParams } from "react-router-dom";

function ChannelMsg() {
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("all");
  const [msgs, setMsgs] = useState([]);
  const [user, setUser] = useState([]);
  const { id } = useParams();
  const [delUrl, setDelUrl] = useState(``);
  const [url, setUrl] = useState(``);
  const [method, setMethod] = useState("");
  const { response, loading, error } = useAxios({
    method: "post",
    url: "/v2/messages",
    data: JSON.stringify({
      channelId: id,
    }),
  });

  useEffect(() => {
    if (response !== null) {
      setMsgs(response?.messages);
      setUser(response?.users);
    }
  }, [response]);

  const token = "d7a8b0dedcb87d11824170d254f8c3824d462142";

  const deleteMessage = (messageID) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .delete(`${hostUrl}/v2/message/${messageID}`, { headers: headers })
      .then(function (response) {
        document.getElementById("del" + messageID).disabled = true;
      })

      .catch(function (error) {});
  };

  document.title = "Channel List | CEO";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Messeges"
            pageTitle="Channels"
            pageUrl="/channels"
          />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Message List
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(parseInt(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        >
                          <option disabled>Select Type</option>
                          <option value="all">All</option>
                          <option value="flagged">Flagged</option>
                          <option value="threaded">Threaded</option>
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  <div className="table-responsive table-card mt-3 mb-1">
                    <table
                      className="table align-middle table-nowrap"
                      id="customerTable"
                    >
                      <thead className="table-light">
                        <tr>
                          <th>User</th>
                          <th>Messege</th>
                          <th>Mentioned Users</th>
                          <th>Reactions</th>
                          <th>Flags</th>
                          <th className="sort" data-sort="created_at">
                            Created at
                          </th>

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {msgs &&
                          msgs.map((item, i) => (
                            <tr key={item._id}>
                              <td>
                                {user[i]?.displayName} <br />
                                <i>({item.userId})</i>
                              </td>
                              <td>{item.data.text}</td>
                              <td>
                                {item?.mentionees &&
                                  item?.mentionees.map((ele) => ele.name)}
                              </td>

                              <td>
                                <Moment format="D MMM YYYY">
                                  {item.updatedAt}
                                </Moment>
                              </td>
                              <td>
                                <ul className="list-inline hstack gap-2 mb-0">
                                  <li
                                    className="list-inline-item"
                                    title="Remove"
                                    id={"deletePopover" + item?.messageId}
                                  >
                                    <Button
                                      className="p-0 bg-transparent border-0 text-danger"
                                      id={"del" + item?.messageId}
                                      disabled={item.isDeleted}
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16"></i>
                                    </Button>
                                  </li>
                                  <UncontrolledPopover
                                    trigger="focus"
                                    placement="left"
                                    target={"deletePopover" + item?.messageId}
                                  >
                                    <PopoverHeader>Delete</PopoverHeader>
                                    <PopoverBody>
                                      Are you sure want to delete?
                                      <div className="d-flex align-items-center justify-content-end mt-2">
                                        <Button className="btn btn-sm btn-light">
                                          No
                                        </Button>
                                        <Button
                                          onClick={() =>
                                            deleteMessage(item?.messageId)
                                          }
                                          className="btn btn-sm btn-danger ms-2"
                                        >
                                          Yes
                                        </Button>
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </ul>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {loading && <Loader error={error} />}
                  {error && (
                    <div className="noresult">
                      <div className="text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#121331,secondary:#08a88a"
                          style={{ width: "75px", height: "75px" }}
                        ></lord-icon>
                        <h5 className="mt-2">Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ChannelMsg;
