import React, { useEffect, useRef, useState, Fragment } from "react";
import { APIClient } from "../../helpers/api_helper";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import MsgToast from "../../Components/Common/MsgToast";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  deleteCategory,
  modifyCatFlag,
  statusChangeCat,
  saveOrderingCategory,
  saveOrderingCategoryResetFlag,
} from "../../store/actions";
import Flatpickr from "react-flatpickr";
import Loader from "../../Components/Common/Loader";
import Moment from "react-moment";
import "moment-timezone";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonModal from "../CommonModal";

function List() {
  const fp = useRef(null);
  const dispatch = useDispatch();
  const api = new APIClient();
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortByField] = useState("created_at");
  const [sortValue, setSortByValue] = useState("DESC");
  const [isChecked, setisChecked] = useState([]);
  const [metatitle, setMetaTitle] = useState("CEO");
  const [modal, setModal] = useState(false);

  let category_type = "";
  if (window.location.pathname === "/blog-categories") {
    category_type = "blogs";
  }
  if (window.location.pathname === "/video-categories") {
    category_type = "videos";
  }
  if (window.location.pathname === "/faq-categories") {
    category_type = "faqs";
  }
  useEffect(() => {
    dispatch(
      getCategories({
        limit,
        offset,
        keyword,
        category_type,
        sortKey,
        sortValue,
        status,
      })
    );
  }, [
    dispatch,
    limit,
    keyword,
    offset,
    sortKey,
    sortValue,
    category_type,
    status,
  ]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(
        getCategories({ limit, dateRange, offset, category_type, keyword })
      );
    }
  }, [dispatch, limit, category_type, offset, date, keyword]);

  const {
    categories,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
  } = useSelector((state) => ({
    categories: state.Categories.categories,
    totalDataCount: state.Categories.totalDataCount,
    isSuccess: state.Categories.isSuccess,
    error: state.Categories.error,
    success: state.Categories.success,
    statusSuccess: state.Categories.statusSuccess,
    statusFailed: state.Categories.statusFailed,
    saveOrderingSuccess: state.Categories.saveOrderingSuccess,
    saveOrderingFaild: state.Categories.saveOrderingFaild,
  }));

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };

  const deleteCat = (id) => {
    let idDeleted = id.split(" ");
    dispatch(
      deleteCategory({
        table_name: "category",
        id: idDeleted,
      })
    );
  };

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyCatFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);
  const resetFilter = () => {
    setKeyword("");
    fp.current.flatpickr.clear();

    dispatch(getCategories({ limit, offset, category_type }));
  };

  function onClickStatusChange(id, status) {
    let idChecked = id.split(" ");
    dispatch(
      statusChangeCat({
        id: idChecked,
        table_name: "category",
        column_name: "status",
        column_value: status,
      })
    );
  }

  //Ordring
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(categories);
  }, [categories]);

  let a = [];
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  //Ordaring
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingCategory({
        ordering_data: orderingData,
        table_name: "category",
      })
    );
  }

  const statusChange = (e) => {
    if (isChecked.length > 0) {
      dispatch(
        statusChangeCat({
          table_name: "category",
          id: isChecked,
          column_name: "status",
          column_value: e,
        })
      );
    } else {
      toast.warning("You do not have any selected files to Change the Status", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingCategoryResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  const handleDeletedData = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

  const deleteCheck = () => {
    if (isChecked.length > 0) {
      setModal(true);
    } else {
      toast.warning("You do not have any selected files to delete", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const onClickDeleteData = () => {
    dispatch(
      deleteCategory({
        table_name: "faq",
        id: isChecked,
      })
    );
    setModal(false);
  };

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const selects = (e) => {
    let checkval = e.target.checked;
    var ele = document.getElementsByName("chk_child");
    if (checkval === true) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
      }
    } else {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = false;
      }
    }

    var array = [];
    var checkboxes = document.querySelectorAll("input[name=chk_child]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    setisChecked(array);
  };

  document.title = "Category List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <ToastContainer /> */}
        <Container fluid={true}>
          <BreadCrumb title="Categories" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Category List
                    </h5>
                    <div className="d-flex gap-2">
                      <Link
                        to="/category/create"
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Category
                      </Link>{" "}
                      <Button
                        className="btn btn-success"
                        onClick={() => statusChange(true)}
                      >
                        Active
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => statusChange(false)}
                      >
                        Deactivate
                      </Button>
                      <Button className="btn btn-delete" onClick={deleteCheck}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-lg-1 me-auto">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      <Col sm={3} className="col-lg-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-lg-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setStatus(value);
                            }}
                          >
                            <option value="">Select status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </div>
                      </Col>

                      <Col sm={3} className="col-lg-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={1} className="col-lg-1">
                        <div className="d-flex">
                          <Button
                            className="btn-reset"
                            color="grey"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {categories && categories.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <>
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                        onClick={(e) => selects(e)}
                                      />
                                    </div>
                                  </th>
                                  <th className="cursor-pointer">Name</th>
                                  <th className="cursor-pointer">Type</th>
                                  <th className="cursor-pointer">Status</th>
                                  <th
                                    className="sort cursor-pointer"
                                    data-sort="created_at"
                                    onClick={() => sortBy("created_at")}
                                  >
                                    Created Date
                                  </th>
                                  <th className="text-end">Action</th>
                                </tr>
                              </thead>
                              <Droppable droppableId="tbody">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list form-check-all"
                                  >
                                    {dataList?.map((item, i) => (
                                      <Draggable
                                        draggableId={item._id}
                                        key={item._id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <tr
                                            key={item._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <th scope="row">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="chk_child"
                                                  value={item._id}
                                                  checked={item.isChecked}
                                                  onChange={(e) =>
                                                    handleDeletedData(e)
                                                  }
                                                />
                                              </div>
                                            </th>
                                            <td>{item.name}</td>
                                            <td>{item.category_type}</td>
                                            {window.location.pathname !==
                                              "/blog-categories" && (
                                              <td>
                                                <button
                                                  className="btn-status"
                                                  title="Click to change status"
                                                  onClick={() =>
                                                    onClickStatusChange(
                                                      item._id,
                                                      item.status ? false : true
                                                    )
                                                  }
                                                >
                                                  {item?.status ? (
                                                    <span className="badge badge-soft-success text-uppercase">
                                                      Active
                                                    </span>
                                                  ) : (
                                                    <span className="badge badge-soft-danger text-uppercase">
                                                      Inactive
                                                    </span>
                                                  )}
                                                </button>
                                              </td>
                                            )}
                                            <td>
                                              <Moment format="D MMM YYYY">
                                                {item.created_at}
                                              </Moment>
                                            </td>
                                            <td>
                                              <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                                <li
                                                  className="list-inline-item edit"
                                                  title="Edit"
                                                >
                                                  <Link
                                                    className="text-primary d-inline-block edit-item-btn"
                                                    to={
                                                      "/category/edit/" +
                                                      item._id
                                                    }
                                                  >
                                                    <i className="ri-pencil-line fs-16"></i>
                                                  </Link>
                                                </li>
                                                <li
                                                  className="list-inline-item"
                                                  title="Remove"
                                                  id={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <Button className="p-0 bg-transparent border-0 text-danger">
                                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                                  </Button>
                                                </li>
                                                <UncontrolledPopover
                                                  trigger="focus"
                                                  placement="left"
                                                  target={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    Delete Category List
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    Are you sure want to delete?
                                                    <div className="d-flex align-items-center justify-content-end mt-2">
                                                      <Button className="btn btn-sm btn-light">
                                                        No
                                                      </Button>
                                                      <Button
                                                        onClick={() =>
                                                          deleteCat(item._id)
                                                        }
                                                        className="btn btn-sm btn-danger ms-2"
                                                      >
                                                        Yes
                                                      </Button>
                                                    </div>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </ul>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>

                            {noOfPage > 1 && (
                              <Pagination className="px-3">
                                {Array(noOfPage)
                                  .fill()
                                  .map((_, i) => {
                                    return (
                                      <Fragment key={i}>
                                        {offset > 0 && i === 0 && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset - limit);
                                                setCurrentPage(currentPage - 1);
                                              }}
                                            >
                                              {" "}
                                              ← &nbsp; Prev{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                        {currentPage === i + 1 && (
                                          <PaginationItem active>
                                            {" "}
                                            <PaginationLink>
                                              {" "}
                                              {i + 1}{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                        {currentPage !== i + 1 && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(limit * i);
                                                setCurrentPage(i + 1);
                                              }}
                                            >
                                              {" "}
                                              {i + 1}{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                        {currentPage !== noOfPage &&
                                          i + 1 === noOfPage && (
                                            <PaginationItem>
                                              {" "}
                                              <PaginationLink
                                                to="#"
                                                onClick={() => {
                                                  setOffset(offset + limit);
                                                  setCurrentPage(
                                                    currentPage + 1
                                                  );
                                                }}
                                              >
                                                {" "}
                                                Next &nbsp; →{" "}
                                              </PaginationLink>{" "}
                                            </PaginationItem>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                              </Pagination>
                            )}
                          </>
                        </DragDropContext>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <CommonModal
                    modal={modal}
                    setModal={setModal}
                    onClickDeleteData={onClickDeleteData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        {saveOrderingSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {saveOrderingFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default List;
