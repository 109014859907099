import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  GET_FEATURES,
  ADD_NEW_FEATURES,
  GET_FEATURES_DETAILS,
  UPDATE_FEATURES,
  DELETE_FEATURES,
  STATUS_CHANGE_FEATURES,
  SAVE_ORDERING_FEATURES,
} from "./actionTypes";

import {
  getFeaturesSuccess,
  getFeaturesFail,
  addNewFeaturesuccess,
  addNewFeaturesFail,
  getFeaturesDetailsSuccess,
  getFeaturesDetailsFail,
  updateFeaturesuccess,
  updateFeaturesFail,
  deleteFeaturesuccess,
  deleteFeaturesFail,
  statusChangeFeaturesuccess,
  statusChangeFeaturesFail,
  saveOrderingFeaturesuccess,
  saveOrderingFeaturesFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getFeatureList,
  addNewFeature,
  getFeatureDetails,
  updateFeature,
  deleteFaq,
  statusChange,
  saveOrdering,
} from "../../helpers/fakebackend_helper";

function* fetchFeatures({ payload: Features }) {
  try {
    const response = yield call(getFeatureList, Features);

    yield put(getFeaturesSuccess(response));
  } catch (error) {
    yield put(getFeaturesFail(error));
  }
}

function* onAddNewFeatures({ payload: Features }) {
  try {
    const response = yield call(addNewFeature, Features);
    if (response.status === 200) {
      yield put(addNewFeaturesuccess(response));
    } else {
      yield put(addNewFeaturesFail(response));
    }
  } catch (error) {
    yield put(addNewFeaturesFail(error));
  }
}

function* onFeaturesDetails({ payload: Features }) {
  try {
    const response = yield call(getFeatureDetails, Features);
    if (response.status === 200) {
      yield put(getFeaturesDetailsSuccess(response));
    } else {
      yield put(getFeaturesDetailsFail(response));
    }
  } catch (error) {
    yield put(getFeaturesDetailsFail(error));
  }
}

function* onFeaturesUpdate({ payload: Features }) {
  try {
    const response = yield call(updateFeature, Features);
    if (response.status === 200) {
      yield put(updateFeaturesuccess(response));
    } else {
      yield put(updateFeaturesFail(response));
    }
  } catch (error) {
    yield put(updateFeaturesFail(error));
  }
}

function* onDeleteFeatures({ payload: data }) {
  try {
    const response = yield call(deleteFaq, data);
    yield put(deleteFeaturesuccess(response));
  } catch (error) {
    yield put(deleteFeaturesFail(error));
  }
}

// //Status Change
function* onStatusChangeFeatures({ payload: Features }) {
  try {
    const response = yield call(statusChange, Features);
    yield put(statusChangeFeaturesuccess({ Features, ...response }));
  } catch (error) {
    yield put(statusChangeFeaturesFail(error));
  }
}

//Save Ordering
function* onSaveOrderingFeatures({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingFeaturesuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingFeaturesFail(error));
  }
}

function* FeaturesSaga() {
  yield takeEvery(GET_FEATURES, fetchFeatures);
  yield takeEvery(ADD_NEW_FEATURES, onAddNewFeatures);
  yield takeEvery(GET_FEATURES_DETAILS, onFeaturesDetails);
  yield takeEvery(UPDATE_FEATURES, onFeaturesUpdate);
  yield takeEvery(DELETE_FEATURES, onDeleteFeatures);
  yield takeEvery(STATUS_CHANGE_FEATURES, onStatusChangeFeatures);
  yield takeEvery(SAVE_ORDERING_FEATURES, onSaveOrderingFeatures);
}

export default FeaturesSaga;
