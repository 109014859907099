import axios from 'axios';
import hostUrl from '../config/host';
import amityToken from '../config/token';

// localStorage.getItem('access_token')

const instance = axios.create({
    baseURL: hostUrl,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + amityToken
    }
});


export const userUpdate = async (payload) => {

    return instance.put('/v3/users', payload).then((res) => res);

};