import { useEffect, useState, useRef, Fragment } from "react";
import { APIClient } from "../../../helpers/api_helper";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, delUser, resetAdminFlag } from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";

const AdminList = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Admin list | " + metatitle;
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortKey, setSortByField] = useState("created_at");
  const [sortValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("admin");
  const [email_verified, setVerified] = useState("");
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { users, totalDataCount, isSuccess, error, loading } = useSelector(
    (state) => ({
      users: state.Users.users,
      totalDataCount: state.Users.totalDataCount,
      isSuccess: state.Users.isSuccess,
      error: state.Users.error,
      loading: state.Users.loading,
    })
  );

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const { user } = useSelector((state) => ({
    user: state.Profile.getuser,
  }));

  console.log(user);

  useEffect(() => {
    dispatch(
      getUsers({
        keyword,
        sortKey,
        sortValue,
        limit,
        offset,
        type,
        email_verified,
      })
    );
  }, [
    dispatch,
    keyword,
    sortKey,
    sortValue,
    limit,
    offset,
    type,
    email_verified,
  ]);
  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getUsers({ limit, dateRange, offset, keyword, type }));
    }
  }, [dispatch, date, keyword, limit, offset, type]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    dispatch(resetAdminFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    setLimit(10);
    setVerified("");
    dispatch(
      getUsers({
        keyword,
        sortKey,
        sortValue,
        limit,
        offset,
        type,
        email_verified,
      })
    );
  };

  const deleteAdminData = (id) => {
    let idDeleted = id.split(" ");
    dispatch(
      delUser({
        table_name: "users",
        id: idDeleted,
      })
    );
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Admin" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Admin List</h5>
                  <div className="flex-shrink-0 ms-auto">
                    <Link
                      to="/admin/create"
                      className="btn btn-primary add-btn me-2"
                    >
                      <i className="ri-add-line align-bottom me-1"></i> Create
                      Admin
                    </Link>
                    {/* <Button className="btn btn-delete" onClick={deleteCheck}>
                        Delete
                      </Button> */}
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-lg-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-lg-2">
                        <select
                          className="form-select"
                          value={email_verified}
                          onChange={(e) => {
                            const value =
                              e.target.value === "true"
                                ? Boolean(e.target.value)
                                : e.target.value === "false"
                                ? false
                                : "";
                            setVerified(value);
                          }}
                        >
                          <option value="">Email Verified</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-lg-2">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={1} className="col-lg-1">
                        <div className="d-flex">
                          <Button
                            className="btn-reset"
                            color="grey"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(users) && users.length > 0 && (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th className="cursor-pointer">Image</th>
                                <th className="cursor-pointer">Name</th>
                                <th className="cursor-pointer">Email</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>
                                <th className="cursor-pointer">
                                  Email Verified
                                </th>
                                <th className="text-end">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {users.map((item) => (
                                <tr key={item._id}>
                                  <td>
                                    {" "}
                                    <img
                                      className="image avatar-xs rounded-circle"
                                      src={item.image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  <td>
                                    {item.email_verified ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Yes
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        No
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                      <li
                                        className="list-flex-item"
                                        title="View"
                                      >
                                        <Link
                                          className="text-primary d-inline-block edit-item-btn h4 m-0"
                                          to={"/admin/edit/" + item._id}
                                        >
                                          <i className="ri-more-2-fill"></i>
                                        </Link>
                                      </li>
                                      {user?._id !== item._id &&
                                        item?.email !== "admin@ceo.com" && (
                                          <>
                                            <li
                                              className="list-inline-item"
                                              title="Remove"
                                              id={"deletePopover" + item._id}
                                            >
                                              <Button className="p-0 bg-transparent border-0 text-danger">
                                                <i className="ri-delete-bin-5-line fs-16"></i>
                                              </Button>
                                            </li>
                                            <UncontrolledPopover
                                              trigger="focus"
                                              placement="left"
                                              target={
                                                "deletePopover" + item._id
                                              }
                                            >
                                              <PopoverHeader>
                                                Delete Admin List
                                              </PopoverHeader>
                                              <PopoverBody>
                                                Are you sure want to delete?
                                                <div className="d-flex align-items-center justify-content-end mt-2">
                                                  <Button className="btn btn-sm btn-light">
                                                    No
                                                  </Button>
                                                  <Button
                                                    onClick={() =>
                                                      deleteAdminData(item._id)
                                                    }
                                                    className="btn btn-sm btn-danger ms-2"
                                                  >
                                                    Yes
                                                  </Button>
                                                </div>
                                              </PopoverBody>
                                            </UncontrolledPopover>
                                          </>
                                        )}
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            ← &nbsp; Prev
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          <PaginationLink>
                                            {i + 1}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {i + 1}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              Next &nbsp; →
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {loading && <Loader />}
                  {isSuccess &&
                    !loading &&
                    Array.isArray(users) &&
                    users.length === 0 && (
                      <h5 className="mt-3 text-center">No Result Found</h5>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AdminList;
