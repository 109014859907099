import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
    ADD_NEW_TAG,
    DELETE_TAG,
    GET_TAGS,
    GET_TAG_DETAILS,
    UPDATE_TAG,
    STATUS_CHANGE_TAG,
    SAVE_ORDERING_TAG,
    FEATURED_CHANGE_TAG
} from "./actionTypes"

import {
  getTagsSuccess,
  getTagsFail,
  addTagFail,
  addTagSuccess,
  getTagDetailsSuccess,
  getTagDetailsFail,
  updateTagSuccess,
  updateTagFail,
  deleteTagSuccess,
  deleteTagFail,
  statusChangeTagSuccess,
  statusChangeTagFail,
  saveOrderingTagSuccess,
  saveOrderingTagFail,
  featuredChangeTagSuccess,
  featuredChangeTagFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTagsList,
  addNewTag,
  updateTag,
  getTagDetails,
  deleteFaq,
  statusChange,
  saveOrdering,
  
} from "../../helpers/fakebackend_helper"

function* fetchTags({ payload: data }) {
  try {
    const response = yield call(getTagsList, data)
    yield put(getTagsSuccess(response))
  } catch (error) {
    yield put(getTagsFail(error))
  }
}

function* onAddNewTag({ payload: tags }) {
  try {
    const response = yield call(addNewTag, tags)
    yield put(addTagSuccess(response))
  } catch (error) {
    yield put(addTagFail(error))
  }
}

function* onUpdateTag({ payload: tags }) {
  try {
    const response = yield call(updateTag, tags)
    yield put(updateTagSuccess(response))
  } catch (error) {
    yield put(updateTagFail(error))
  }
}

function* onDeleteTag({ payload: tags }) {
  try {
    const response = yield call(deleteFaq, tags)
    yield put(deleteTagSuccess(response))
  } catch (error) {
    yield put(deleteTagFail(error))
  }
}

function* onTagDetails({ payload: tags }) {
  try {
      const response = yield call(getTagDetails, tags);
      if(response.status === 200){
        yield put(getTagDetailsSuccess(response));
      } else {
        yield put(getTagDetailsFail(response));
      }
  } catch (error) {
      yield put(getTagDetailsFail(error));
  }
}

//Status Change
function* onStatusChangeTag({ payload: tags }) {
  try {
    const response = yield call(statusChange, tags);
    yield put(statusChangeTagSuccess({ tags, ...response }));
  } catch (error) {
    yield put(statusChangeTagFail(error));
  }
}

// Featured Change
function* onFeaturedChangetag({ payload: tags }) {
  try {
    const response = yield call(statusChange, tags);
    yield put(featuredChangeTagSuccess({ tags, ...response }));
  } catch (error) {
    yield put(featuredChangeTagFail(error));
  }
}

//Save Ordering
function* onSaveOrderingTag({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingTagSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingTagFail(error));
  }
}
function* TagSaga() {
  yield takeEvery(GET_TAGS, fetchTags)
  yield takeEvery(ADD_NEW_TAG, onAddNewTag)
  yield takeEvery(UPDATE_TAG, onUpdateTag)
  yield takeEvery(DELETE_TAG, onDeleteTag)
  yield takeEvery(STATUS_CHANGE_TAG, onStatusChangeTag);
  yield takeEvery(FEATURED_CHANGE_TAG, onFeaturedChangetag);
  yield takeEvery(SAVE_ORDERING_TAG, onSaveOrderingTag);
  yield takeEvery(GET_TAG_DETAILS, onTagDetails);


}

export default TagSaga
