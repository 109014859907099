import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addNewPlaylist, resetPlaylistFlag } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import Select from "react-select";
import { APIClient } from "../../../helpers/api_helper";

const PlaylistCreate = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Create Playlist | " + metatitle;
  const dispatch = useDispatch();
  const [description, setData] = useState("");
  const searchParams = new URLSearchParams(window.location.search).get("type");
  const [type, setType] = useState(null);

  const [isCreating, setIsCreating] = useState(false);
  const [VideoType, SetVideoType] = useState({
    value: "youtube",
    label: "Youtube",
  });
  const { isPlaylistAdd, isPlaylistAddFail, error, success } = useSelector(
    (state) => ({
      playlists: state.Playlists.playlists,
      isPlaylistAdd: state.Playlists.isPlaylistAdd,
      isPlaylistAddFail: state.Playlists.isPlaylistAddFail,
      error: state.Playlists.error,
      success: state.Playlists.success,
    })
  );

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    setType(searchParams);
    SetVideoType({
      value: searchParams,
      label: searchParams,
    })
  }, [searchParams]);

  function handleSelectType(label, value) {
    SetVideoType({ label: label, value: value });
  }
  
  const optionsVideoType = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
    { value: "spotify", label: "Spotify" },
  ];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      playlist_id: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name"),
      playlist_id: Yup.string().required("Please Enter Playlist Id"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      let data = {
        name: values.name,
        type: VideoType.value,
        description: description,
        playlist_id: values.playlist_id,
      };

      
      if (data.type === "youtube") {
        fetch(
          "https://www.googleapis.com/youtube/v3/playlists?key=AIzaSyCrfGfmOlRRWGP2hFQ1qQ6e5PBqoKl-Qn4&part=snippet&id=" +
            values.playlist_id
        )
          .then((res) => res.json())
          .then((result) => {
            if (result.items.length > 0) {
              data = {
                name: values.name,
                type: VideoType.value,
                playlist_id: values.playlist_id,
                image: result.items[0].snippet.thumbnails.maxres.url,
                description: description,
              };

              dispatch(addNewPlaylist(data));
            } else {
              toast.error("Invalid Youtube Playlist");
            }
            setIsCreating(false);
          });
      }

      if (data.type === "vimeo") {
        fetch("https://api.vimeo.com/channels/" + values.playlist_id, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer 0a81b84c241427063ecf50227ca56f35",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (!result.error) {
              data = {
                name: values.name,
                type: VideoType.value,
                playlist_id: values.playlist_id,
                image: result.pictures.base_link,
                description: description,
              };
              
              dispatch(addNewPlaylist(data));
            } else {
              toast.error(result.error);
            }
            setIsCreating(false);
          });
      }

      if (data.type === "spotify") {
        fetch("https://ceofront.iosx.in/spotify_token.php")
          .then((res) => res.text())
          .then(
            (result) => {
              fetch(
                "https://api.spotify.com/v1/playlists/" + values.playlist_id,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + result,
                  },
                }
              )
                .then((res) => res.json())
                .then((result) => {
                 
                  if (!result.error) {
                    const data = {
                      name: values.name,
                      type: VideoType.value,
                      playlist_id: values.playlist_id,
                      image: result.images[0].url,
                      description: description,
                    };

                    dispatch(addNewPlaylist(data));
                  } else {
                    toast.error(result.error.message);
                  }
                  setIsCreating(false);
                });
            },

            (error) => {
             
            }
          );
      }

      //
    },
  });

  useEffect(() => {
    if (isPlaylistAdd || isPlaylistAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetPlaylistFlag());
        window.location.href = `/playlist?type=${searchParams}`;
      }, 3000);
    }
  }, [dispatch, isPlaylistAdd, isPlaylistAddFail, validation]);

  

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Playlist"
            pageTitle="Playlists"
            pageUrl="/playlist"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Playlist" />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Playlist ID*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Playlist ID"
                              name="playlist_id"
                              value={validation.values.playlist_id || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.playlist_id &&
                                validation.errors.playlist_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.playlist_id &&
                            validation.errors.playlist_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.playlist_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name*
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label
                              htmlFor="choices-privacy-status-input"
                              className="form-label"
                            >
                              Video Type
                            </Label>
                            <Select
                              value={VideoType || ""}
                              onChange={(e) =>
                                handleSelectType(e.label, e.value)
                              }
                              onBlur={validation.handleBlur}
                              options={optionsVideoType}
                              name="type"
                              classNamePrefix="select2-selection form-select"
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link to={`/playlist?type=${type}`} className="btn btn-cancel w-sm">
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Create{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Create</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isPlaylistAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isPlaylistAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} theme="dark" autoClose={2000} />
        </Container>
      </div>
    </>
  );
};

export default PlaylistCreate;
