import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { updateTag, resetTagFlag, getTagDetails } from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const TagEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Tag | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);

  const [tag_type, settag_type] = useState();
  const { isTagUpdate, isTagUpdateFail, error, success, details } = useSelector(
    (state) => ({
      isTagUpdate: state.Tag.isTagUpdate,
      isTagUpdateFail: state.Tag.isTagUpdateFail,
      error: state.Tag.error,
      success: state.Tag.success,
      details: state.Tag.details,
    })
  );

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {
        id: "624c1d79ac364e5ef3796d76",
      });

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: details && details?.title ? details.title : "",
      tag_type: details && details?.tag_type ? details.tag_type : "",
    },

    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        tag_type: tag_type,
      };
      dispatch(updateTag(data));
    },
  });
  useEffect(() => {
    if (details !== undefined) {
      settag_type(details.tag_type);
    }
  }, [settag_type, details]);

  useEffect(() => {
    if (isTagUpdate || isTagUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetTagFlag());
        window.location.href = `/tags?tag_type=${tag_type}`;
      }, 2000);
    }
  }, [dispatch, isTagUpdate, isTagUpdateFail, validation, history, tag_type]);

  useEffect(() => {
    dispatch(getTagDetails({ id: id }));
  }, [dispatch, id]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Tag" pageTitle="Tags" pageUrl="/tags" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Tag" />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Link
                      to={`/tags?tag_type=${tag_type}`}
                      className="btn btn-cancel w-sm me-1"
                    >
                      Cancel
                    </Link>
                    <Button
                      color="primary"
                      className="btn-load"
                      disabled={isCreating}
                      type="submit"
                    >
                      <span className="d-flex align-items-center">
                        {isCreating && (
                          <Spinner size="sm" className="flex-shrink-0 me-2">
                            {" "}
                            Update{" "}
                          </Spinner>
                        )}
                        <span className="flex-grow-1">Update</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Form>
          </Row>
          {isTagUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isTagUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default TagEdit;
