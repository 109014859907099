/* MEMBERS */
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_MEMBERS_FAIL = "GET_MEMBERS_FAIL";

export const ADD_NEW_MEMBER = "ADD_NEW_MEMBER";
export const ADD_NEW_MEMBER_SUCCESS = "ADD_NEW_MEMBER_SUCCESS";
export const ADD_NEW_MEMBER_FAIL = "ADD_NEW_MEMBER_FAIL";

export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAIL = "UPDATE_MEMBER_FAIL";

export const GET_MEMBER_DETAILS = "GET_MEMBER_DETAILS";
export const GET_MEMBER_DETAILS_SUCCESS = "GET_MEMBER_DETAILS_SUCCESS";
export const GET_MEMBER_DETAILS_FAIL = "GET_MEMBER_DETAILS_FAIL";

export const RESET_MEMBER_FLAG = "RESET_MEMBER_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_MEMBER = "STATUS_CHANGE_MEMBER";
export const STATUS_CHANGE_MEMBER_SUCCESS = "STATUS_CHANGE_MEMBER_SUCCESS";
export const STATUS_CHANGE_MEMBER_FAIL = "STATUS_CHANGE_MEMBER_FAIL";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAIL = "DELETE_MEMBER_FAIL";

export const MODIFY_MEMBER_FLAG = "MODIFY_MEMBER_FLAG";

// Save Ordering
export const SAVE_ORDERING_MEMBER = "SAVE_ORDERING_MEMBER";
export const SAVE_ORDERING_MEMBER_SUCCESS = "SAVE_ORDERING_MEMBER_SUCCESS";
export const SAVE_ORDERING_MEMBER_FAIL = "SAVE_ORDERING_MEMBER_FAIL";
export const SAVE_ORDERING_MEMBER_RESET_FLAG = "SAVE_ORDERING_MEMBER_RESET_FLAG";