/* TAGS */
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_FAIL = "GET_TAGS_FAIL";

export const ADD_NEW_TAG = "ADD_NEW_TAG";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAIL = "ADD_TAG_FAIL";

export const UPDATE_TAG = "UPDATE_TAG";
export const UPDATE_TAG_SUCCESS = "UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAIL = "UPDATE_TAG_FAIL";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";

export const MODIFY_TAG_FLAG = "MODIFY_TAG_FLAG";

export const RESET_TAG_FLAG = "RESET_TAG_FLAG";

export const GET_TAG_DETAILS = "GET_TAG_DETAILS";
export const GET_TAG_DETAILS_SUCCESS = "GET_TAG_DETAILS_SUCCESS";
export const GET_TAG_DETAILS_FAIL = "GET_TAG_DETAILS_FAIL";

// STATUS CHANGE
export const STATUS_CHANGE_TAG = "STATUS_CHANGE_TAG";
export const STATUS_CHANGE_TAG_SUCCESS = "STATUS_CHANGE_TAG_SUCCESS";
export const STATUS_CHANGE_TAG_FAIL = "STATUS_CHANGE_TAG_FAIL";

// FEATURED CHANGE
export const FEATURED_CHANGE_TAG = "FEATURED_CHANGE_TAG";
export const FEATURED_CHANGE_TAG_SUCCESS = "FEATURED_CHANGE_TAG_SUCCESS";
export const FEATURED_CHANGE_TAG_FAIL = "FEATURED_CHANGE_TAG_FAIL";

// Save Ordering
export const SAVE_ORDERING_TAG = "SAVE_ORDERING_TAG";
export const SAVE_ORDERING_TAG_SUCCESS = "SAVE_ORDERING_TAG_SUCCESS";
export const SAVE_ORDERING_TAG_FAIL = "SAVE_ORDERING_TAG_FAIL";
export const SAVE_ORDERING_TAG_RESET_FLAG = "SAVE_ORDERING_TAG_RESET_FLAG";
