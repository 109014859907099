import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Moment from "react-moment";
import "moment-timezone";
import useAxios from "../../../helpers/useAxios";
import { useParams, Link } from "react-router-dom";
import { APIClient } from "../../../helpers/api_helper";
function CommunityList() {
  const { usrId } = useParams();
  const [community, setCommunity] = useState([]);
  const [limit, setLimit] = useState(10);
  const [category, setcategory] = useState([]);
  const [keyword, setkeyword] = useState("");
  const [url, setUrl] = useState(
    `/v3/communities?options[limit]=${limit}&keyword=${keyword}`
  );
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    setUrl(`/v3/communities?options[limit]=${limit}&keyword=${keyword}`);
  }, [limit, keyword]);

  const { response } = useAxios({
    method: "get",
    url: url,
  });

  useEffect(() => {
    if (response !== null) {
      setCommunity(response?.communities);
      setcategory(response?.categories);
    }
  }, [response]);

  document.title = "Community List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Community" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="border-0 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Community List
                    </h5>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-lg-1 me-auto">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="100">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      <Col sm={3} className="col-lg-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setkeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  <div className="table-responsive table-card mt-3 mb-1">
                    {community?.length ? (
                      <table
                        className="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            <th>Community Name</th>
                            <th>Category</th>
                            <th>Type</th>

                            <th>Posts</th>
                            <th>Pending post</th>
                            <th>Flagged posts</th>
                            <th>Flagged comments</th>
                            <th>Last Activity</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {community.length > 0 &&
                            community.map((item) => {
                              return (
                                <React.Fragment key={item._id}>
                                  <tr>
                                    <td>{item?.displayName}</td>
                                    <td>
                                      {item.categoryIds.length > 0 &&
                                        item.categoryIds.map((it) => {
                                          const cat = category.find(
                                            (i) => i.categoryId === it
                                          );
                                          return <>{cat ? cat.name : ""}</>;
                                        })}
                                    </td>
                                    <td>
                                      {item?.isPublic === true
                                        ? "Public"
                                        : null}
                                    </td>

                                    <td>
                                      {" "}
                                      <Link
                                        to={
                                          "/posts?communityId=" +
                                          item._id +
                                          "&type=community"
                                        }
                                        style={{ color: "cyan" }}
                                      >
                                        {item?.postsCount}
                                      </Link>{" "}
                                    </td>
                                    <td>{"-"}</td>
                                    <td>
                                      {item?.hasFlaggedPost === false
                                        ? "-"
                                        : null}
                                    </td>
                                    <td>
                                      {item?.hasFlaggedComment === false
                                        ? "-"
                                        : null}
                                    </td>
                                    <td>
                                      <Moment format="D MMM YYYY">
                                        {item.updatedAt}
                                      </Moment>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="noresult text-center py-5">
                        <h5 className="mt-3">No Result Found</h5>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default CommunityList;
