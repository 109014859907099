import {
    GET_FEATURES,
    GET_FEATURES_SUCCESS,
    GET_FEATURES_FAIL,
    ADD_NEW_FEATURES,
    ADD_NEW_FEATURES_SUCCESS,
    ADD_NEW_FEATURES_FAIL,
    UPDATE_FEATURES,
    UPDATE_FEATURES_SUCCESS,
    UPDATE_FEATURES_FAIL,
    GET_FEATURES_DETAILS,
    GET_FEATURES_DETAILS_SUCCESS,
    GET_FEATURES_DETAILS_FAIL,
    DELETE_FEATURES,
    DELETE_FEATURES_SUCCESS,
    DELETE_FEATURES_FAIL,
    MODIFY_FEATURES_FLAG,
    RESET_FEATURES_FLAG,
    STATUS_CHANGE_FEATURES,
    STATUS_CHANGE_FEATURES_SUCCESS,
    STATUS_CHANGE_FEATURES_FAIL,
    SAVE_ORDERING_FEATURES,
    SAVE_ORDERING_FEATURES_SUCCESS,
    SAVE_ORDERING_FEATURES_FAIL,
    SAVE_ORDERING_FEATURES_RESET_FLAG
  } from "./actionTypes";
  
  export const getFeatures = data => ({
    type: GET_FEATURES,
    payload: data,
  });
  
  export const getFeaturesSuccess = Features => ({
    type: GET_FEATURES_SUCCESS,
    payload: Features,
  });
  
  export const getFeaturesFail = error => ({
    type: GET_FEATURES_FAIL,
    payload: error,
  });
  
  export const addNewFeatures = Features => ({
    type: ADD_NEW_FEATURES,
    payload: Features,
  });
  
  export const addNewFeaturesuccess = Features => ({
    type: ADD_NEW_FEATURES_SUCCESS,
    payload: Features,
  });
  
  export const addNewFeaturesFail = error => ({
    type: ADD_NEW_FEATURES_FAIL,
    payload: error,
  });
  
  export const updateFeatures = Features => ({
    type: UPDATE_FEATURES,
    payload: Features,
  });
  
  export const updateFeaturesuccess = Features => ({
    type: UPDATE_FEATURES_SUCCESS,
    payload: Features,
  });
  
  export const updateFeaturesFail = error => ({
    type: UPDATE_FEATURES_FAIL,
    payload: error,
  });
  
  export const getFeaturesDetails = Features => ({
    type: GET_FEATURES_DETAILS,
    payload: Features,
  });
  
  export const getFeaturesDetailsSuccess = Features => ({
    type: GET_FEATURES_DETAILS_SUCCESS,
    payload: Features,
  });
  
  export const getFeaturesDetailsFail = error => ({
    type: GET_FEATURES_DETAILS_FAIL,
    payload: error,
  });
  
  export const delFeatures = data => ({
    type: DELETE_FEATURES,
    payload: data,
  });
  
  export const deleteFeaturesuccess = data => ({
    type: DELETE_FEATURES_SUCCESS,
    payload: data,
  });
  
  export const deleteFeaturesFail = data => ({
    type: DELETE_FEATURES_FAIL,
    payload: data,
  });
  
  export const statusChangeFeatures = Features => ({
    type: STATUS_CHANGE_FEATURES,
    payload: Features,
  });
  
  export const statusChangeFeaturesuccess = Features => ({
    type: STATUS_CHANGE_FEATURES_SUCCESS,
    payload: Features,
  });
  
  export const statusChangeFeaturesFail = error => ({
    type: STATUS_CHANGE_FEATURES_FAIL,
    payload: error,
  });
  
  export const modifyFeaturesFlag = () => {
    return {
      type: MODIFY_FEATURES_FLAG,
    }
  }
  
  export const resetFeaturesFlag = () => {
    return {
      type: RESET_FEATURES_FLAG,
    }
  }
  
  
  //Save Ordaring
  export const saveOrderingFeatures = data => ({
    type: SAVE_ORDERING_FEATURES,
    payload: data,
  });
  
  export const saveOrderingFeaturesuccess = data => ({
    type: SAVE_ORDERING_FEATURES_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingFeaturesFail = error => ({
    type: SAVE_ORDERING_FEATURES_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingFeaturesResetFlag = () => {
    return {
      type: SAVE_ORDERING_FEATURES_RESET_FLAG,
    }
  }
  
  
  
  