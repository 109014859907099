import {
  GET_FAQS,
  GET_FAQS_FAIL,
  GET_FAQS_SUCCESS,
  ADD_NEW_FAQ,
  ADD_FAQ_SUCCESS,
  ADD_FAQ_FAIL,
  GET_FAQ_DETAILS,
  GET_FAQ_DETAILS_SUCCESS,
  GET_FAQ_DETAILS_FAIL,
  UPDATE_FAQ,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAIL,
  DELETE_FAQ,
  RESET_FAQ_FLAG,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAIL,
  STATUS_CHANGE_FAQ,
  STATUS_CHANGE_FAQ_SUCCESS,
  STATUS_CHANGE_FAQ_FAIL,
  FEATURED_CHANGE_FAQ,
  FEATURED_CHANGE_FAQ_SUCCESS,
  FEATURED_CHANGE_FAQ_FAIL,
  MODIFY_FAQ_FLAG,
  SAVE_ORDERING_FAQ,
  SAVE_ORDERING_FAQ_SUCCESS,
  SAVE_ORDERING_FAQ_FAIL,
  SAVE_ORDERING_FAQ_RESET_FLAG,
} from "./actionType";

export const getFaqs = data => ({
  type: GET_FAQS,
  payload: data,
});

export const getFaqsSuccess = data => ({
  type: GET_FAQS_SUCCESS,
  payload: data,
});

export const getFaqsFail = error => ({
  type: GET_FAQS_FAIL,
  payload: error,
});

export const getFaqDetails = faqs => ({
  type: GET_FAQ_DETAILS,
  payload: faqs,
});

export const getFaqDetailsSuccess = faqs => ({
  type: GET_FAQ_DETAILS_SUCCESS,
  payload: faqs,
});

export const getFaqDetailsFail = error => ({
  type: GET_FAQ_DETAILS_FAIL,
  payload: error,
});

export const addNewFaq = faqs => ({
  type: ADD_NEW_FAQ,
  payload: faqs,
});

export const addFaqSuccess = faqs => ({
  type: ADD_FAQ_SUCCESS,
  payload: faqs,
});

export const addFaqFail = error => ({
  type: ADD_FAQ_FAIL,
  payload: error,
});

export const updateFaq = faqs => ({
  type: UPDATE_FAQ,
  payload: faqs,
});

export const updateFaqSuccess = faqs => ({
  type: UPDATE_FAQ_SUCCESS,
  payload: faqs,
});

export const updateFaqFail = error => ({
  type: UPDATE_FAQ_FAIL,
  payload: error,
});

export const deleteFaq = faqs => ({
  type: DELETE_FAQ,
  payload: faqs,
});

export const deleteFaqSuccess = faqs => ({
  type: DELETE_FAQ_SUCCESS,
  payload: faqs,
});

export const deleteFaqFail = error => ({
  type: DELETE_FAQ_FAIL,
  payload: error,
});

export const modifyFaqFlag = () => {
  return {
    type: MODIFY_FAQ_FLAG,
  }
}

export const resetFaqFlag = () => {
  return {
    type: RESET_FAQ_FLAG,
  }
}
// Status Chaange
export const statusChangeFaq = faqs => ({
  type: STATUS_CHANGE_FAQ,
  payload: faqs,
});

export const statusChangeFaqSuccess = faqs => ({
  type: STATUS_CHANGE_FAQ_SUCCESS,
  payload: faqs,
});

export const statusChangeFaqFail = error => ({
  type: STATUS_CHANGE_FAQ_FAIL,
  payload: error,
});

// Featured Change
export const featuredChangeFaq = faqs => ({
  type: FEATURED_CHANGE_FAQ,
  payload: faqs,
});

export const featuredChangeFaqSuccess = faqs => ({
  type: FEATURED_CHANGE_FAQ_SUCCESS,
  payload: faqs,
});

export const featuredChangeFaqFail = error => ({
  type: FEATURED_CHANGE_FAQ_FAIL,
  payload: error,
});

// Save ordering

export const saveOrderingFaq = data => ({
  type: SAVE_ORDERING_FAQ,
  payload: data,
});

export const saveOrderingFaqSuccess = data => ({
  type: SAVE_ORDERING_FAQ_SUCCESS,
  payload: data,
});

export const saveOrderingFaqFail = error => ({
  type: SAVE_ORDERING_FAQ_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingFaqResetFlag = () => {
  return {
    type: SAVE_ORDERING_FAQ_RESET_FLAG,
  }
}
