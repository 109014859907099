import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import { GET_CHANNELS } from "./actionType";

import { getChannelsSuccess, getChannelsFail } from "./actions";

//Include Both Helper File with needed methods
import { getChannelList } from "../../helpers/fakebackend_helper";

function* fetchChannels({ payload: channels }) {
  try {
    const response = yield call(getChannelList, channels);

    yield put(getChannelsSuccess(response));
  } catch (error) {
    yield put(getChannelsFail(error));
  }
}

function* ChannelSaga() {
  yield takeEvery(GET_CHANNELS, fetchChannels);
}

export default ChannelSaga;
