import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  GET_STEPS,
  ADD_NEW_STEPS,
  GET_STEPS_DETAILS,
  UPDATE_STEPS,
  DELETE_STEPS,
  STATUS_CHANGE_STEPS,
  SAVE_ORDERING_STEPS,
  FEATURED_CHANGE_STEPS,
} from "./actionTypes";

import {
  getStepsSuccess,
  getStepsFail,
  addNewStepsuccess,
  addNewStepsFail,
  getStepsDetailsSuccess,
  getStepsDetailsFail,
  updateStepsuccess,
  updateStepsFail,
  deleteStepsuccess,
  deleteStepsFail,
  statusChangeStepsuccess,
  statusChangeStepsFail,
  saveOrderingStepsuccess,
  saveOrderingStepsFail,
  featuredChangeStepsuccess,
  featuredChangeStepsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getStepList,
  addNewStep,
  getStepDetails,
  updateStep,
  deleteFaq,
  statusChange,
  saveOrdering,
} from "../../helpers/fakebackend_helper";

function* fetchSteps({ payload: Steps }) {
  try {
    const response = yield call(getStepList, Steps);

    yield put(getStepsSuccess(response));
  } catch (error) {
    yield put(getStepsFail(error));
  }
}

function* onAddNewSteps({ payload: Steps }) {
  try {
    const response = yield call(addNewStep, Steps);
    if (response.status === 200) {
      yield put(addNewStepsuccess(response));
    } else {
      yield put(addNewStepsFail(response));
    }
  } catch (error) {
    yield put(addNewStepsFail(error));
  }
}

function* onStepsDetails({ payload: Steps }) {
  try {
    const response = yield call(getStepDetails, Steps);
    if (response.status === 200) {
      yield put(getStepsDetailsSuccess(response));
    } else {
      yield put(getStepsDetailsFail(response));
    }
  } catch (error) {
    yield put(getStepsDetailsFail(error));
  }
}

function* onStepsUpdate({ payload: Steps }) {
  try {
    const response = yield call(updateStep, Steps);
    if (response.status === 200) {
      yield put(updateStepsuccess(response));
    } else {
      yield put(updateStepsFail(response));
    }
  } catch (error) {
    yield put(updateStepsFail(error));
  }
}

function* onDeleteSteps({ payload: data }) {
  try {
    const response = yield call(deleteFaq, data);
    yield put(deleteStepsuccess(response));
  } catch (error) {
    yield put(deleteStepsFail(error));
  }
}

//Status Change
function* onStatusChangeSteps({ payload: Steps }) {
  try {
    const response = yield call(statusChange, Steps);
    yield put(statusChangeStepsuccess({ Steps, ...response }));
  } catch (error) {
    yield put(statusChangeStepsFail(error));
  }
}

// Featured Change
function* onFeaturedChangeSteps({ payload: Steps }) {
  try {
    const response = yield call(statusChange, Steps);
    yield put(featuredChangeStepsuccess({ Steps, ...response }));
  } catch (error) {
    yield put(featuredChangeStepsFail(error));
  }
}

//Save Ordering
function* onSaveOrderingSteps({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingStepsuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingStepsFail(error));
  }
}

function* StepsSaga() {
  yield takeEvery(GET_STEPS, fetchSteps);
  yield takeEvery(ADD_NEW_STEPS, onAddNewSteps);
  yield takeEvery(GET_STEPS_DETAILS, onStepsDetails);
  yield takeEvery(UPDATE_STEPS, onStepsUpdate);
  yield takeEvery(DELETE_STEPS, onDeleteSteps);
  yield takeEvery(STATUS_CHANGE_STEPS, onStatusChangeSteps);
  yield takeEvery(FEATURED_CHANGE_STEPS, onFeaturedChangeSteps);
  yield takeEvery(SAVE_ORDERING_STEPS, onSaveOrderingSteps);
}

export default StepsSaga;
