
import {
  GET_CONTACTS,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  ADD_NEW_CONTACT,
  ADD_NEW_CONTACT_SUCCESS,
  ADD_NEW_CONTACT_FAIL,
  GET_CONTACT_DETAILS,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_FAIL,
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  DELETE_CONTACT,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  MODIFY_CONTACT_FLAG,
  RESET_CONTACT_FLAG,
  STATUS_CHANGE_CONTACT,
  STATUS_CHANGE_CONTACT_SUCCESS,
  STATUS_CHANGE_CONTACT_FAIL
} from "./actionTypes";

export const getContacts = data => ({
  type: GET_CONTACTS,
  payload: data,
});

export const getContactsSuccess = data => ({
  type: GET_CONTACTS_SUCCESS,
  payload: data,
});

export const getContactsFail = error => ({
  type: GET_CONTACTS_FAIL,
  payload: error,
});

export const addNewContact = contact=> ({
  type: ADD_NEW_CONTACT,
  payload: contact,
});

export const addNewContactSuccess = contact => ({
  type: ADD_NEW_CONTACT_SUCCESS,
  payload: contact,
});

export const addNewContactFail = error => ({
  type: ADD_NEW_CONTACT_FAIL,
  payload: error,
});

export const updateContact = contact => ({
  type: UPDATE_CONTACT,
  payload: contact,
});

export const updateContactSuccess = contact => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload: contact,
});

export const updateContactFail = error => ({
  type: UPDATE_CONTACT_FAIL,
  payload: error,
});

export const getContactDetails = contact => ({
  type: GET_CONTACT_DETAILS,
  payload: contact,
});

export const getContactDetailsSuccess = contact => ({
  type: GET_CONTACT_DETAILS_SUCCESS,
  payload: contact,
});

export const getContactDetailsFail = error => ({
  type: GET_CONTACT_DETAILS_FAIL,
  payload: error,
});


export const statusChangeContact = contact => ({
  type: STATUS_CHANGE_CONTACT,
  payload: contact,
});

export const statusChangeContactSuccess = contact => ({
  type: STATUS_CHANGE_CONTACT_SUCCESS,
  payload: contact,
});

export const statusChangeContactFail = error => ({
  type: STATUS_CHANGE_CONTACT_FAIL,
  payload: error,
});
export const delContact = data => ({
  type: DELETE_CONTACT,
  payload: data,
});

export const deleteContactSuccess = data => ({
  type: DELETE_CONTACT_SUCCESS,
  payload: data,
});

export const deleteContactFail = data => ({
  type: DELETE_CONTACT_FAIL,
  payload: data,
});
export const modifyContactFlag = () => {
  return {
    type: MODIFY_CONTACT_FLAG,
  }
}

export const resetContactFlag = () => {
  return {
    type: RESET_CONTACT_FLAG,
  }
}