import React, { useEffect, useState } from "react";
import { getUsers } from "../../../store/actions";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import axios from "axios";
import hostUrl from "../../../config/host";
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import "moment-timezone";
import useAxios from "../../../helpers/useAxios";
import { Link, useParams } from "react-router-dom";
import { APIClient } from "../../../helpers/api_helper";
import UserNav from "../../../Components/UserNavigation/UserNav";
import frontUrl, { BASE_URL } from "../../../config/frontUrl";
import Loader from "../../../Components/Common/Loader";

function List() {
  const { usrId } = useParams();
  const [posts, setPosts] = useState();
  const communityId = new URLSearchParams(window.location.search).get(
    "communityId"
  );
  const [targetId, setTargetId] = useState(
    communityId ? communityId : usrId ? usrId : ""
  );

  const [sortBy, setSortBy] = useState("lastCreated");
  const searchParams = new URLSearchParams(window.location.search).get("type");
  const [targetType, setTargetType] = useState(
    searchParams ? searchParams : "user"
  );
  const [limit, setLimit] = useState(10);
  const [url, setUrl] = useState(
    `/v3/posts?sortBy=${sortBy}&targetId=${targetId}&targetType=${targetType}&options[limit]=${limit}`
  );
  const [Communityurl, setCommunityUrl] = useState(`/v3/communities`);
  const [community, setCommunity] = useState([]);
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const [type, setType] = useState(searchParams);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [favList, setFavorites] = useState([]);
  const [postIds, setPostids] = useState([]);
  const [method, setMethod] = useState("get");
  const [userVal, setUserVal] = useState([{}]);
  const [amityToken, setAmityToken] = useState("");
  const { users } = useSelector((state) => ({
    Settingsdetails: state.Sites.details,
    users: state.Users.users,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers({ type: "user" }));
  }, []);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
      setAmityToken(response.data.amity_admin_token);
    };
    request();
  }, []);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("profile-details", {
        user_id: usrId,
      });
      setUserVal({ value: usrId, label: response?.data?.email });
    };
    request();
    setCommunityUrl(`/v3/communities`);
  }, []);

  const { response: res } = useAxios({
    method: method,
    url: Communityurl,
  });

  // users
  useEffect(() => {
    let userData = [];
    users.length > 0 &&
      users.map((item, index) => {
        userData.push({ label: item.email, value: item._id });
      });
    setOptions(userData);
  }, [users]);

  const selectUser = (e) => {
    setUserVal(e);
    setTargetId(e.value);
  };

  useEffect(() => {
    if (res !== null) {
      setCommunity(res?.communities);
    }
  }, [res, community]);

  const typeChange = (e) => {
    setType(e.target.value);
    setTargetType(e.target.value);
    var x = document.getElementById("community");
    var y = document.getElementById("user");
    if (e.target.value === "community") {
      x.style.display = "block";
      y.style.display = "none";
    }
    if (e.target.value === "user") {
      y.style.display = "block";
      x.style.display = "none";
      setUserVal({ value: users[0]._id, label: users[0]?.email });
      setTargetId(users[0]._id);
    }
  };

  const communityChange = (e) => {
    if (e.target.value) {
      setTargetId(e.target.value);
      setTargetType("community");
    }
  };

  const deletePost = (postId) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${amityToken}`,
    };
    axios
      .delete(`${hostUrl}/v3/posts/${postId}`, { headers: headers })
      .then(function (response) {
        document.getElementById("del" + postId).disabled = true;
        var elem = document.getElementById("commentcount" + postId);
        var reaction = document.getElementById("reactioncount" + postId);
        var subpostcount = document.getElementById("subpostcount" + postId);
        var typecount = document.getElementById("typecount" + postId);
        var textcount = document.getElementById("textcount" + postId);
        var createcount = document.getElementById("createcount" + postId);
        elem.classList.add("text-decoration-line-through");
        reaction.classList.add("text-decoration-line-through");
        subpostcount.classList.add("text-decoration-line-through");
        typecount.classList.add("text-decoration-line-through");
        textcount.classList.add("text-decoration-line-through");
        createcount.classList.add("text-decoration-line-through");
      })

      .catch(function (error) {});
  };

  useEffect(() => {
    const getUserList = async () => {
      setLoader(true);
      const response = await api.create("post/Postlist", {
        limit: limit,
        offset: offset,
        user_id: usrId,
      });

      setHasNext(response.hasNext);
      setFavorites(response.data === [] ? [] : response.data);
      response.data.length > 0 &&
        response.data.map((item) => {
          postIds.push(item.post_id);
        });
      setPostids([...new Set(postIds)]);

      let savePosts = [];
      if (!usrId && targetId && targetType) {
        savePosts = await axios.post(
          BASE_URL + "amity/feed/list",

          {
            targetId: targetId,
            targetType: targetType,
            limit: limit,
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("accesstoken"),
            },
          }
        );
      } else {
        savePosts = await axios.post(
          BASE_URL + "amity/fevorite-feed",

          {
            postIds: [...new Set(postIds)],
          },
          {
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("accesstoken"),
            },
          }
        );
      }

      if (savePosts.status === 200) {
        setPosts(savePosts?.data.posts);
      }
      setLoader(false);
    };
    getUserList();
  }, [limit, offset]);

  document.title = "Post List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Posts" />
          <Row>
            {usrId && <UserNav user_id={usrId} />}

            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Posts List</h5>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="100">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col
                        sm={2}
                        className="col-lg-2"
                        id="community"
                        style={{ display: "none" }}
                      >
                        <select
                          className="form-select"
                          value={targetId}
                          onChange={(e) => communityChange(e)}
                        >
                          <option value="">Select Community</option>
                          {community &&
                            community.map((item) => (
                              <option value={item._id} key={item._id}>
                                {item?.displayName}
                              </option>
                            ))}
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {!loader && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(posts) && posts.length > 0 && (
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Content</th>

                              <th>Type</th>

                              <th>Sub Post</th>
                              <th>Comments</th>
                              <th>Reactions</th>
                              <th className="sort" data-sort="created_at">
                                Created at
                              </th>
                              <th className="text-end">Action</th>
                            </tr>
                          </thead>

                          <tbody className="list form-check-all">
                            {posts.map((item) => (
                              <tr key={item._id}>
                                <td
                                  id={"textcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item.data?.text.length > 40
                                    ? item.data?.text.substring(0, 30) + "..."
                                    : item.data?.text}
                                </td>

                                <td
                                  id={"typecount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <span className="badge badge-soft-success text-uppercase">
                                    {item?.dataType}
                                  </span>
                                </td>

                                <td
                                  id={"subpostcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <Link
                                    to={"/post/" + item._id}
                                    style={{ color: "var(--color05)" }}
                                  >
                                    {item.children?.length}
                                  </Link>
                                </td>
                                <td
                                  id={"commentcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item?.commentsCount === 0 ? (
                                    "0"
                                  ) : (
                                    <Link
                                      to={"/comments?referenceId=" + item._id}
                                      style={{ color: "var(--color05)" }}
                                    >
                                      {item.commentsCount}
                                    </Link>
                                  )}
                                </td>
                                <td
                                  id={"reactioncount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item.reactionsCount}
                                </td>
                                <td
                                  id={"createcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <Moment format="D MMM YYYY">
                                    {item.createdAt}
                                  </Moment>
                                </td>
                                <td
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                    <li
                                      className="list-inline-item"
                                      title="View Sites"
                                    >
                                      <a
                                        className="text-primary d-inline-block edit-item-btn"
                                        href={
                                          frontUrl + "/post/" + item?.postId
                                        }
                                        target="_blank"
                                      >
                                        <i className="ri-links-line fs-18"></i>
                                      </a>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      title="Remove"
                                      id={"deletePopover" + item.postId}
                                    >
                                      <Button
                                        className="p-0 bg-transparent border-0 text-danger"
                                        id={"del" + item.postId}
                                        disabled={item.isDeleted}
                                      >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                      </Button>
                                    </li>
                                    <UncontrolledPopover
                                      trigger="focus"
                                      placement="left"
                                      target={"deletePopover" + item.postId}
                                    >
                                      <PopoverHeader>Delete</PopoverHeader>
                                      <PopoverBody>
                                        Are you sure want to delete?
                                        <div className="d-flex align-items-center justify-content-end mt-2">
                                          <Button className="btn btn-sm btn-light">
                                            No
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              deletePost(item.postId)
                                            }
                                            className="btn btn-sm btn-danger ms-2"
                                          >
                                            Yes
                                          </Button>
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {loader && <Loader />}
                  {!loader && Array.isArray(posts) && posts.length === 0 && (
                    <h5 className="mt-3 text-center">No Result Found</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default List;
