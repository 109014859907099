import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
  GET_ROLES,
  ADD_NEW_ROLES,
  GET_ROLES_DETAILS
} from "./actionTypes"

import {
  getRolesSuccess,
  getRolesFail,
  addNewRolesuccess,
  addNewRoleFail,
  getRolesDetailsSuccess,
  getRolesDetailsFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRoleList,
  addNewRole,
  getRoleDetails

} from "../../helpers/fakebackend_helper"

function* fetchRoles({ payload: roles}) {
  try {
    const response = yield call(getRoleList, roles)
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* onAddNewRoles({ payload: roles }) {
  try {
    const response = yield call(addNewRole, roles);
    if (response.status === 200) {
      yield put(addNewRolesuccess(response));
    } else {
      yield put(addNewRoleFail(response));
    }
  } catch (error) {
    yield put(addNewRoleFail(error));
  }
}

function* onRolesDetails({ payload: roles }) {
  try {
    const response = yield call(getRoleDetails, roles);
    if (response.status === 200) {
      yield put(getRolesDetailsSuccess(response));
    } else {
      yield put(getRolesDetailsFail(response));
    }
  } catch (error) {
    yield put(getRolesDetailsFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(ADD_NEW_ROLES, onAddNewRoles)
  yield takeEvery(GET_ROLES_DETAILS, onRolesDetails)
}

export default roleSaga
