import { useEffect, useState, useRef, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getPages, resetPageFlag } from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";
import { APIClient } from "../../../helpers/api_helper";

const PageList = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Page list | " + metatitle;
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [sortKey, setSortByField] = useState("ordering");
  const [sortValue, setSortByValue] = useState("ASC");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { pages, totalDataCount, isSuccess, error, loading } = useSelector(
    (state) => ({
      pages: state.Pages.pages,
      totalDataCount: state.Pages.totalDataCount,
      isSuccess: state.Pages.isSuccess,
      error: state.Pages.error,
      loading: state.Pages.loading,
    })
  );

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    dispatch(
      getPages({ keyword, sortKey, sortValue, limit, offset, main_page: true })
    );
  }, [dispatch, keyword, sortKey, sortValue, limit, offset]);
  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getPages({ limit, dateRange, offset, keyword }));
    }
  }, [dispatch, limit, offset, date, keyword]);
  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    dispatch(resetPageFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    dispatch(getPages({ keyword, sortKey, sortValue, limit, offset }));
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Pages" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Page List</h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={4} className="col-xxl-2">
                        <div className="d-flex">
                          <Button
                            className="btn-reset"
                            color="danger"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(pages) && pages.length > 0 && (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th className="cursor-pointer">Title</th>
                                <th className="cursor-pointer">Subtitle</th>
                                <th className="cursor-pointer">Note</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Created Date
                                </th>

                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {pages.map((item) => (
                                <tr key={item._id}>
                                  <td>{item.title}</td>
                                  <td>{item.subtitle}</td>
                                  <td>{item.note}</td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>

                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      {item?.slug === "help" ? (
                                        <li
                                          className="list-inline-item"
                                          title="View"
                                        >
                                          <Link
                                            className="text-primary d-inline-block edit-item-btn"
                                            to={"/faqs"}
                                          >
                                            <i className="ri-eye-line fs-18"></i>
                                          </Link>
                                        </li>
                                      ) : (
                                        <>
                                          {/* <li
                                            className="list-inline-item edit"
                                            title="Seo"
                                          >
                                            <Link
                                              to={`/seo/page/${item._id}`}
                                              className="text-primary d-inline-block edit-item-btn"
                                            >
                                              <i className="ri-bookmark-line"></i>
                                            </Link>
                                          </li> */}
                                          <li
                                            className="list-inline-item edit"
                                            title="Edit"
                                          >
                                            <Link
                                              to={`/page/edit/${item.slug}`}
                                              className="text-primary d-inline-block edit-item-btn"
                                            >
                                              <i className="ri-pencil-fill fs-16"></i>
                                            </Link>
                                          </li>
                                        </>
                                      )}

                                      <li
                                        className="list-inline-item"
                                        title="Remove"
                                        id={"deletePopover" + item._id}
                                      ></li>
                                      <UncontrolledPopover
                                        placement="left"
                                        target={"deletePopover" + item._id}
                                      >
                                        <PopoverHeader>
                                          Delete Page
                                        </PopoverHeader>
                                        <PopoverBody>
                                          Are you sure want to delete?
                                          <div className="d-flex align-items-center justify-content-end mt-2"></div>
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            ← &nbsp; Prev
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          <PaginationLink>
                                            {i + 1}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {i + 1}
                                          </PaginationLink>
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              Next &nbsp; →
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {loading && <Loader />}
                  {isSuccess &&
                    !loading &&
                    Array.isArray(pages) &&
                    pages.length === 0 && (
                      <h5 className="mt-3 text-center">No Result Found</h5>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PageList;
