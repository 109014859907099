import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewContact, resetContactFlag } from "../../../store/actions";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const ContactCreate = () => {
  document.title = "Create Contact | CEO";
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [message, setData] = useState("");
  const { isContactAdd, isContactAddFail, error, success } = useSelector(
    (state) => ({
      isContactAdd: state.Contacts.isContactAdd,
      isContactAddFail: state.Contacts.isContactAddFail,
      error: state.Contacts.error,
      success: state.Contacts.success,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      message: "",
      contact_number: "",
      contact_email: "",
      subject: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      contact_number: Yup.number().required("Please Enter Contact Number"),
      contact_email: Yup.string().required("Please Enter Contact Email"),
      subject: Yup.string().required("Please Enter Subject"),
      message: Yup.string().required("Please Enter Message"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        name: values.name,
        message: message,
        contact_number: values.contact_number,
        contact_email: values.contact_email,
        subject: values.subject,
      };
      dispatch(addNewContact(data));
    },
  });

  useEffect(() => {
    if (isContactAdd || isContactAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetContactFlag());
      }, 3000);
    }
  }, [dispatch, isContactAdd, isContactAddFail, validation]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Create Contact"
            pageTitle="Contacts"
            pageUrl="/contacts"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create Contact" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Your Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Contact Number *
                            </Label>
                            <Input
                              type="number"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Contact Number"
                              name="contact_number"
                              value={validation.values.contact_number || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.contact_number &&
                                validation.errors.contact_number
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contact_number &&
                            validation.errors.contact_number ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact_number}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Contact Email *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Contact Email"
                              name="contact_email"
                              value={validation.values.contact_email || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.contact_email &&
                                validation.errors.contact_email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.contact_email &&
                            validation.errors.contact_email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact_email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Subject *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Subject"
                              name="subject"
                              value={validation.values.subject || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.subject &&
                                validation.errors.subject
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.subject &&
                            validation.errors.subject ? (
                              <FormFeedback type="invalid">
                                {validation.errors.subject}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Message*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="message"
                              data={validation.values.message || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.message &&
                            validation.errors.message ? (
                              <FormFeedback type="invalid">
                                {validation.errors.message}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/contacts" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isContactAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isContactAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default ContactCreate;
