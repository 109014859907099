import {
  GET_MEMBERS,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_FAIL,
  ADD_NEW_MEMBER,
  ADD_NEW_MEMBER_SUCCESS,
  ADD_NEW_MEMBER_FAIL,
  GET_MEMBER_DETAILS,
  GET_MEMBER_DETAILS_SUCCESS,
  GET_MEMBER_DETAILS_FAIL,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAIL,
  RESET_MEMBER_FLAG,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER,
  DELETE_MEMBER_FAIL,
  MODIFY_MEMBER_FLAG,
  STATUS_CHANGE_MEMBER,
  STATUS_CHANGE_MEMBER_SUCCESS,
  STATUS_CHANGE_MEMBER_FAIL,
  SAVE_ORDERING_MEMBER,
  SAVE_ORDERING_MEMBER_SUCCESS,
  SAVE_ORDERING_MEMBER_FAIL,
  SAVE_ORDERING_MEMBER_RESET_FLAG,
} from "./actionTypes";

export const getMembers = (data) => ({
  type: GET_MEMBERS,
  payload: data,
});

export const getMembersSuccess = (data) => ({
  type: GET_MEMBERS_SUCCESS,
  payload: data,
});

export const getMembersFail = (error) => ({
  type: GET_MEMBERS_FAIL,
  payload: error,
});

export const addNewMember = (member) => ({
  type: ADD_NEW_MEMBER,
  payload: member,
});

export const addNewMemberSuccess = (member) => ({
  type: ADD_NEW_MEMBER_SUCCESS,
  payload: member,
});

export const addNewMemberFail = (error) => ({
  type: ADD_NEW_MEMBER_FAIL,
  payload: error,
});

export const updateMember = (member) => ({
  type: UPDATE_MEMBER,
  payload: member,
});

export const updateMemberSuccess = (member) => ({
  type: UPDATE_MEMBER_SUCCESS,
  payload: member,
});

export const updateMemberFail = (error) => ({
  type: UPDATE_MEMBER_FAIL,
  payload: error,
});

export const getMemberDetails = (member) => ({
  type: GET_MEMBER_DETAILS,
  payload: member,
});

export const getMemberDetailsSuccess = (member) => ({
  type: GET_MEMBER_DETAILS_SUCCESS,
  payload: member,
});

export const getMemberDetailsFail = (error) => ({
  type: GET_MEMBER_DETAILS_FAIL,
  payload: error,
});

export const resetMemberFlag = () => {
  return {
    type: RESET_MEMBER_FLAG,
  };
};

export const deleteMember = (member) => ({
  type: DELETE_MEMBER,
  payload: member,
});

export const deleteMemberSuccess = (member) => ({
  type: DELETE_MEMBER_SUCCESS,
  payload: member,
});

export const deleteMemberFail = (error) => ({
  type: DELETE_MEMBER_FAIL,
  payload: error,
});

export const modifyMemberFlag = () => {
  return {
    type: MODIFY_MEMBER_FLAG,
  };
};

// Status Chaange
export const statusChangeMember = (member) => ({
  type: STATUS_CHANGE_MEMBER,
  payload: member,
});

export const statusChangeMemberSuccess = (member) => ({
  type: STATUS_CHANGE_MEMBER_SUCCESS,
  payload: member,
});

export const statusChangeMemberFail = (error) => ({
  type: STATUS_CHANGE_MEMBER_FAIL,
  payload: error,
});

// Save ordering

export const saveOrderingMember = member => ({
  type: SAVE_ORDERING_MEMBER,
  payload: member,
});

export const saveOrderingMemberSuccess = member => ({
  type: SAVE_ORDERING_MEMBER_SUCCESS,
  payload: member,
});

export const saveOrderingMemberFail = error => ({
  type: SAVE_ORDERING_MEMBER_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingMemberResetFlag = () => {
  return {
    type: SAVE_ORDERING_MEMBER_RESET_FLAG,
  }
}

