import {
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
  DELETE_TAG_SUCCESS,
  RESET_TAG_FLAG,
  DELETE_TAG_FAIL,
  STATUS_CHANGE_TAG_SUCCESS,
  STATUS_CHANGE_TAG_FAIL,
  FEATURED_CHANGE_TAG_SUCCESS,
  FEATURED_CHANGE_TAG_FAIL,
  MODIFY_TAG_FLAG,
  SAVE_ORDERING_TAG_SUCCESS,
  SAVE_ORDERING_TAG_FAIL,
  SAVE_ORDERING_TAG_RESET_FLAG,
  GET_TAG_DETAILS_SUCCESS,
  GET_TAG_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  tags: [],
  error: {},
  loading: true
};

const Tag = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
        loading: false
      };

    case GET_TAGS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
        loading: false
      };

    case ADD_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags, action.payload.data],
        success: action.payload.message,
        isTagAdd: true,
        isTagAddFail: false,
      };

    case ADD_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTagAdd: false,
        isTagAddFail: true,
      };

    case UPDATE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map((tag) =>
          tag._id.toString() === action.payload.data._id.toString()
            ? { tag, ...action.payload.data }
            : tag
        ),
        success: action.payload.message,
        isTagUpdate: true,
        isTagUpdateFail: false,
      };

    case UPDATE_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isTagUpdate: false,
        isTagUpdateFail: true,
      };

    case MODIFY_TAG_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    case RESET_TAG_FLAG:
      return {
        ...state,
        isTagAdd: false,
        isTagAddFail: false,
        isSuccess: true,
        isTagDetails: false,
        isTagUpdate: false,
        isTagUpdateFail: false,
        error: false,
        statusSuccess: false,
      };

    case GET_TAG_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_TAG_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    // Status Change
    case STATUS_CHANGE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map((tag) =>
          action.payload.tags.id.includes(tag._id)
            ? { ...tag, status: action.payload.tags.column_value }
            : tag
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    // Feature Change
    case FEATURED_CHANGE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.map((tag) =>
          action.payload.tags.id.includes(tag._id)
            ? { ...tag, featured: action.payload.tags.column_value }
            : tag
        ),
        success: action.payload.message,
        featuredSuccess: true,
        featuredFailed: false,
      };

    case FEATURED_CHANGE_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        featuredSuccess: false,
        featuredFailed: true,
      };

    //Save Ordering
    case SAVE_ORDERING_TAG_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_TAG_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    //Delete

    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags.filter((tag) => {
          return !action.payload.data.includes(tag._id);
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isTagRemove: true,
        isTagRemoveFail: false,
      };

    case DELETE_TAG_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isTagRemove: false,
        isTagRemoveFail: true,
      };

    default:
      return state;
  }
};

export default Tag;
