import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  GET_PLAYLIST,
  ADD_NEW_PLAYLIST,
  GET_PLAYLIST_DETAILS,
  UPDATE_PLAYLIST,
  DELETE_PLAYLIST,
  STATUS_CHANGE_PLAYLIST,
  SAVE_ORDERING_PLAYLIST,
  FEATURED_CHANGE_PLAYLIST,
} from "./actionType";

import {
  getPlaylistsSuccess,
  getPlaylistsFail,
  addNewPlaylistSuccess,
  addNewPlaylistFail,
  getPlaylistDetailsSuccess,
  getPlaylistDetailsFail,
  updatePlaylistSuccess,
  updatePlaylistFail,
  deletePlaylistSuccess,
  deletePlaylistFail,
  statusChangePlaylistSuccess,
  statusChangePlaylistFail,
  saveOrderingPlaylistSuccess,
  saveOrderingPlaylistFail,
  featuredChangePlaylistSuccess,
  featuredChangePlaylistFail,
} from "./action";

//Include Both Helper File with needed methods
import {
  getPlaylistList,
  addNewPlaylist,
  getPlaylistDetails,
  updatePlaylist,
  deleteFaq,
  statusChange,
  saveOrdering,
} from "../../helpers/fakebackend_helper";

function* fetchPlaylists({ payload: playlists }) {
  try {
    const response = yield call(getPlaylistList, playlists);

    yield put(getPlaylistsSuccess(response));
  } catch (error) {
    yield put(getPlaylistsFail(error));
  }
}

function* onAddNewPlaylist({ payload: playlists }) {
  try {
    const response = yield call(addNewPlaylist, playlists);
    if (response.status === 200) {
      yield put(addNewPlaylistSuccess(response));
    } else {
      yield put(addNewPlaylistFail(response));
    }
  } catch (error) {
    yield put(addNewPlaylistFail(error));
  }
}

function* onPlaylistDetails({ payload: playlists }) {
  try {
    const response = yield call(getPlaylistDetails, playlists);
    if (response.status === 200) {
      yield put(getPlaylistDetailsSuccess(response));
    } else {
      yield put(getPlaylistDetailsFail(response));
    }
  } catch (error) {
    yield put(getPlaylistDetailsFail(error));
  }
}

function* onPlaylistUpdate({ payload: playlists }) {
  try {
    const response = yield call(updatePlaylist, playlists);
    if (response.status === 200) {
      yield put(updatePlaylistSuccess(response));
    } else {
      yield put(updatePlaylistFail(response));
    }
  } catch (error) {
    yield put(updatePlaylistFail(error));
  }
}

function* onDeletePlaylist({ payload: data }) {
  try {
    const response = yield call(deleteFaq, data);
    yield put(deletePlaylistSuccess(response));
  } catch (error) {
    yield put(deletePlaylistFail(error));
  }
}

//Status Change
function* onStatusChangePlaylist({ payload: playlists }) {
  try {
    const response = yield call(statusChange, playlists);
    yield put(statusChangePlaylistSuccess({ playlists, ...response }));
  } catch (error) {
    yield put(statusChangePlaylistFail(error));
  }
}

// Featured Change
function* onFeaturedChangePlaylist({ payload: playlists }) {
  try {
    const response = yield call(statusChange, playlists);
    yield put(featuredChangePlaylistSuccess({ playlists, ...response }));
  } catch (error) {
    yield put(featuredChangePlaylistFail(error));
  }
}

//Save Ordering
function* onSaveOrderingPlaylist({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingPlaylistSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingPlaylistFail(error));
  }
}

function* PlaylistSaga() {
  yield takeEvery(GET_PLAYLIST, fetchPlaylists);
  yield takeEvery(ADD_NEW_PLAYLIST, onAddNewPlaylist);
  yield takeEvery(GET_PLAYLIST_DETAILS, onPlaylistDetails);
  yield takeEvery(UPDATE_PLAYLIST, onPlaylistUpdate);
  yield takeEvery(DELETE_PLAYLIST, onDeletePlaylist);
  yield takeEvery(STATUS_CHANGE_PLAYLIST, onStatusChangePlaylist);
  yield takeEvery(FEATURED_CHANGE_PLAYLIST, onFeaturedChangePlaylist);
  yield takeEvery(SAVE_ORDERING_PLAYLIST, onSaveOrderingPlaylist);
}

export default PlaylistSaga;
