import { APIClient } from "./api_helper";
import * as url from "./url_helper";
// import { APIClientNew } from "./new_api_helper";
// import {profileAxios} from "./api_helper";

const api = new APIClient();

// const apiNew = new profileAxios();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);
export const getProfileData = data => api.create(url.GET_PROFILE_DATA, data);
export const getProfileDetailsData = data => api.create(url.GET_PROFILE_DETAILS_DATA, data);
// export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);
export const postFakeProfile = (data) => api.create(url.POST_EDIT_PROFILE, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// Calendar


//Category
export const getCategoryList = data => api.create(url.GET_CATEGORIES_LIST,data);
export const addCategory = data => api.create(url.ADD_CATEGORY,data);
export const getCategory = data => api.create(url.GET_CATEGORY,data);
export const updCategory = data => api.create(url.UPDATE_CATEGORY,data);
export const delCategory = data => api.create(url.DELETE_CATEGORY,data);
// status change  Category
// export const statusChangeCat = cat => api.create(url.STATUS_CHANGE_CAT ,cat);

// Channels
export const getChannelList = channels => api.create(url.GET_CHANNELS_LIST,channels);

// Playlists
export const getPlaylistList = playlists => api.create(url.GET_PLAYLISTS_LIST, playlists);
export const addNewPlaylist = data => api.create(url.ADD_PLAYLIST,data);
export const getPlaylistDetails = playlists => api.create(url.GET_PLAYLIST, playlists);
export const updatePlaylist = playlists => api.create(url.UPDATE_PLAYLIST, playlists);

// Steps
export const getStepList = Steps => api.create(url.GET_STEPS_LIST, Steps);
export const addNewStep = Steps => api.create(url.ADD_STEP,Steps);
export const getStepDetails = Steps => api.create(url.GET_STEPS, Steps);
export const updateStep = Steps => api.create(url.UPDATE_STEPS, Steps);


// Features
export const getFeatureList = Features => api.create(url.GET_FEATURE_LIST, Features);
export const addNewFeature = Features => api.create(url.ADD_FEATURE,Features);
export const getFeatureDetails = Features => api.create(url.GET_FEATURE, Features);
export const updateFeature = Features => api.create(url.UPDATE_FEATURE, Features);

// Members
export const getMemberList = members => api.create(url.GET_MEMBER_LIST, members);
export const addNewMember = members => api.create(url.ADD_MEMBER,members);
export const getMemberDetails = members => api.create(url.GET_MEMBER, members);
export const updateMember = members => api.create(url.UPDATE_MEMBER, members);
export const statusChangeMember = members => api.create(url.STATUS_CHANGE_MEMBER ,members);
export const deleteMember = members => api.create(url.DELETE_MEMBER ,members);


// Role
export const getRoleList = roles => api.create(url.GET_ROLE_LIST, roles);
export const addNewRole = roles => api.create(url.ADD_NEW_ROLE, roles);
export const getRoleDetails = roles => api.create(url.GET_ROLE_DETAILS, roles);


// User Create
export const addNewUser = user => api.create(url.ADD_NEW_USER, user);

//User List
export const getUserList = data => api.create(url.GET_USER_LIST, data);

// User Update
export const updateUser = user => api.create(url.UPDATE_USER, user);

// User Details
export const getUserDetails = user => api.create(url.GET_USER_DETAILS, user);
// Delete User
export const delUser = data => api.create(url.DELETE_USER,data);

// status change of user
export const statusChangeUser = user => api.create(url.STATUS_CHANGE_USER ,user);
//Admin List
export const getAdminList = data => api.create(url.GET_ADMIN_LIST, data);

// FAQS
export const getFaqsList = data => api.create(url.GET_FAQ_LIST, data);
export const addNewFaq = faq => api.create(url.ADD_NEW_FAQ, faq);
export const updateFaq = faq => api.create(url.UPDATE_FAQ, faq);
export const getFaqDetails = faq => api.create(url.FAQ_DETAILS, faq);

// Tags
export const getTagsList = data => api.create(url.GET_TAG_LIST, data);
export const addNewTag = tags => api.create(url.ADD_NEW_TAG, tags);
export const updateTag = tags => api.create(url.UPDATE_TAG, tags);
export const getTagDetails = tags => api.create(url.TAG_DETAILS, tags);

// Events
export const getEventsList = data => api.create(url.GET_EVENT_LIST, data);
export const addNewEvent = events => api.create(url.ADD_NEW_EVENT, events);
export const updateEvent = events => api.create(url.UPDATE_EVENT, events);
export const getEventDetails = events => api.create(url.EVENT_DETAILS, events);

// Connection
export const getConnectionsList = data => api.create(url.GET_CONNECTION_LIST, data);
export const addNewConnection = events => api.create(url.ADD_NEW_CONNECTION, events);


// export const deleteFaqup = faq => api.create(url.DELETE_FAQ, faq);
// status change  faq
export const statusChangeFaq = faqs => api.create(url.STATUS_CHANGE_FAQ ,faqs);

// Banner list
export const getBannerList = banner => api.create(url.GET_BANNER_LIST, banner);

// Banner Create
export const addNewBanner = banner => api.create(url.ADD_NEW_BANNER, banner);

// Banner Update
export const updateBanner = banner => api.create(url.UPDATE_BANNER, banner);

// Banner Details
export const getBannerDetails = banner => api.create(url.GET_BANNER_DETAILS, banner);

// Banner Remove
export const delBanner = banner => api.create(url.DELETE_BANNER, banner);
export const updateSite = job => api.create(url.UPDATE_SITE, job);

// Site Details
export const getSiteDetails = site => api.create(url.GET_SITE_DETAILS, site);


// Page list
export const getPageList = page => api.create(url.GET_PAGE_LIST, page);

// Page Create
export const addNewPage = page => api.create(url.ADD_NEW_PAGE, page);

// Page Update
export const updatePage = page => api.create(url.UPDATE_PAGE, page);

// Page Details
export const getPageDetails = page => api.create(url.GET_PAGE_DETAILS, page);


// Seo list
export const getSeoList = seo => api.create(url.GET_SEO_LIST, seo);

// Seo Create
export const addNewSeo = seo => api.create(url.ADD_NEW_SEO, seo);

// Seo Update
export const updateSeo = seo => api.create(url.UPDATE_SEO, seo);

// Seo Details
export const getSeoDetails = seo => api.create(url.GET_SEO_DETAILS, seo);

// Seo Remove
export const delSeo = seo => api.create(url.DELETE_SEO, seo);

// Contact list
export const getContactList = contact => api.create(url.GET_CONTACT_LIST, contact);

// Contact Create
export const addNewContact = contact => api.create(url.ADD_NEW_CONTACT, contact);

// Contact Update
// export const updateContact = contact => api.create(url.UPDATE_CONTACT, contact);

// Contact Details
// export const getContactDetails = contact => api.create(url.GET_CONTACT_DETAILS, contact);

// Contact Remove
export const delContact = contact => api.create(url.DELETE_CONTACT, contact);

// status change contact
export const statusChangeContact = contact => api.create(url.STATUS_CHANGE_CONTACT ,contact);

// SAVE ORDERING
export const saveOrdering = product => api.create(url.SAVE_ORDERING ,product);

// Common Delete
export const deleteFaq = product => api.create(url.DELETE_DATA, product);

// COMMON STATUS CHANGE
export const statusChange = product => api.create(url.STATUS_CHANGE, product);


