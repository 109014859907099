import React, { useEffect, useState, Fragment } from "react";
import { APIClient } from "../../../helpers/api_helper";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getPlaylists,
  statusChangePlaylist,
  delPlaylist,
  featuredChangePlaylist,
  saveOrderingPlaylist,
  saveOrderingPlaylistResetFlag,
  getSiteDetails,
  modifyPlaylistFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Loader from "../../../Components/Common/Loader";
// import Moment from "react-moment";
import "moment-timezone";
import CommonModal from "../../CommonModal";
import frontUrl from "../../../config/frontUrl";
import spotifyUrl from "../../../config/spotifyUrl";

function List() {
  const dispatch = useDispatch();
  const api = new APIClient();
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [category_id, setCatId] = useState("");
  const [status, setStatus] = useState("");
  const [sortKey, setsortKey] = useState("ordering");
  const [sortValue, setSortByValue] = useState(1);
  const [featured, setFeatured] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [settings, setSettings] = useState([]);
  const [date, setDate] = useState("");
  const [isChecked, setisChecked] = useState([]);
  const searchParams = new URLSearchParams(window.location.search).get("type");
  const [type, setType] = useState(searchParams);
  const [metatitle, setMetaTitle] = useState("CEO");
  const [modal, setModal] = useState(false);
  const [mulValue, setMulVal] = useState([]);
  const [orderingMessage, setOrderingMessage] = useState(false);

  useEffect(() => {
    setType(searchParams);
    setMulVal({ value: searchParams, label: searchParams });
  }, [searchParams]);

  useEffect(() => {
    dispatch(getSiteDetails({ id: "624c1d79ac364e5ef3796d76" }));
    dispatch(
      getPlaylists({
        keyword,
        limit,
        offset,
        featured,
        type,
        sortByField: sortKey,
        sortByValue: sortValue,
        category_id,
        status,
      })
    );
  }, [
    dispatch,
    keyword,
    limit,
    offset,
    sortKey,
    sortValue,
    category_id,
    status,
    featured,
    type,
  ]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getPlaylists({ limit, dateRange, offset, keyword }));
    }
  }, [dispatch, limit, offset, date, keyword]);

  const {
    playlists,
    totalDataCount,
    isSuccess,
    error,
    success,
    details,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
    loading,
  } = useSelector((state) => ({
    playlists: state.Playlists.playlists,
    isSuccess: state.Playlists.isSuccess,
    success: state.Playlists.success,
    totalDataCount: state.Playlists.totalDataCount,
    error: state.Playlists.error,
    details: state.Playlists.details,
    statusSuccess: state.Playlists.statusSuccess,
    statusFailed: state.Playlists.statusFailed,
    saveOrderingSuccess: state.Playlists.saveOrderingSuccess,
    saveOrderingFaild: state.Playlists.saveOrderingFaild,
    loading: state.Playlists.loading,
  }));


  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyPlaylistFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  function featuredChange(e) {
    dispatch(
      featuredChangePlaylist({
        table_name: "videoPlaylist",
        id: isChecked,
        column_name: "featured",
        column_value: e,
      })
    );
  }

  function onClickFeatureChange(id, feature) {
    let idFeatured = id.split(" ");
    dispatch(
      featuredChangePlaylist({
        id: idFeatured,
        table_name: "videoPlaylist",
        column_name: "featured",
        column_value: feature,
      })
    );
  }

  function onClickStatusChange(id, status) {
    let idChecked = id.split(" ");
    dispatch(
      statusChangePlaylist({
        id: idChecked,
        table_name: "videoPlaylist",
        column_name: "status",
        column_value: status,
      })
    );
  }

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setsortKey(fieldName);
  };

  // Ordering
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);

  useEffect(() => {
    setDataList(playlists);
  }, [playlists]);

  const handleDragEnd = (e) => {
    function check(array, key, value) {
      return array.some((object) => object[key] === value);
    }
    const check2 =
      (Array.isArray(mulValue) &&
        check(mulValue, "value", "youtube") &&
        check(mulValue, "value", "vimeo") &&
        !check(mulValue, "value", "spotify")) ||
      (check(mulValue, "value", "spotify") &&
        !check(mulValue, "value", "youtube") &&
        !check(mulValue, "value", "vimeo"));
    if (check2) {
      if (!e.destination) return;
      let tempData = Array.from(dataList);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      setDataList([...tempData]);
      let dataArray = [];
      for (let i = 0; i < tempData.length; i++) {
        dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
      }
      setOrderingData(dataArray);
      onClickSaveOrdering(dataArray);
      setOrderingMessage(false);
    } else {
      setOrderingMessage(
        "Please select Youtube and Vimeo both or Spotify itself "
      );
      setTimeout(() => {
        setOrderingMessage(false);
      }, 2000);
    }
  };

  //Ordering
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingPlaylist({
        ordering_data: orderingData,
        table_name: "videoPlaylist",
      })
    );
  }

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

  const statusChange = (e) => {
    if (isChecked.length > 0) {
      dispatch(
        statusChangePlaylist({
          table_name: "videoPlaylist",
          id: isChecked,
          column_name: "status",
          column_value: e,
        })
      );
    } else {
      toast.warning("You do not have any selected files to Change the Status", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const deletePlaylistData = (id) => {
    let idDeleted = id.split(" ");
    dispatch(
      delPlaylist({
        table_name: "videoPlaylist",
        id: idDeleted,
      })
    );
  };

  const deleteCheck = () => {
    if (isChecked.length > 0) {
      setModal(true);
    } else {
      toast.warning("You do not have any selected files to delete", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const onClickDeleteData = () => {
    dispatch(
      delPlaylist({
        table_name: "videoPlaylist",
        id: isChecked,
      })
    );
    setModal(false);
  };

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingPlaylistResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  

  const selects = (e) => {
    let checkval = e.target.checked;
    var ele = document.getElementsByName("chk_child");
    if (checkval === true) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
      }
    } else {
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = false;
      }
    }

    var array = [];
    var checkboxes = document.querySelectorAll("input[name=chk_child]:checked");

    for (let i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    setisChecked(array);
  };

  const options = [
    { value: "youtube", label: "Youtube" },
    { value: "vimeo", label: "Vimeo" },
    { value: "spotify", label: "Spotify" },
  ];

  const onChange = (e) => {
    const val = e.map((item) => item.value).join(",");
    setType(val);
    setMulVal(e);
  };


  document.title = "Playlist | " + metatitle;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <ToastContainer />
          <BreadCrumb title="Playlists" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Playlist</h5>
                    <div className="d-flex gap-2">
                      <Link
                        to={`/playlist/create?type=${type}`}
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Playlist
                      </Link>

                      <Button
                        className="btn btn-active"
                        onClick={() => statusChange(true)}
                      >
                        Active
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => statusChange(false)}
                      >
                        Deactivate
                      </Button>
                      <Button
                        className="btn btn-featured"
                        onClick={() => featuredChange(true)}
                      >
                        Featured
                      </Button>
                      <Button
                        className="btn btn-unfeatured"
                        onClick={() => featuredChange(false)}
                      >
                        Unfeatured
                      </Button>
                      <Button className="btn btn-delete" onClick={deleteCheck}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={2} className="col-lg-2">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-lg-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setStatus(value);
                            }}
                          >
                            <option value="">Select status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={2} className="col-lg-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={featured}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setFeatured(value);
                            }}
                          >
                            <option value="">Select Featured</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={4} className="col-lg-3">
                        <div>
                          <Select
                            options={options}
                            isMulti
                            onChange={onChange}
                            value={mulValue}
                            classNamePrefix="select2-selection form-select"
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(playlists) && playlists.length > 0 && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <>
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                        onClick={(e) => selects(e)}
                                      />
                                    </div>
                                  </th>
                                  <th className="cursor-pointer">
                                    Playlist ID
                                  </th>
                                  <th className="cursor-pointer">Name</th>
                                  <th className="cursor-pointer">Type</th>
                                  <th className="cursor-pointer">Featured</th>
                                  <th>Status</th>
                                  <th className="text-end">Action</th>
                                </tr>
                              </thead>
                              <Droppable droppableId="tbody">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list form-check-all"
                                  >
                                    {dataList?.map((item, i) => (
                                      <Draggable
                                        draggableId={item._id}
                                        key={item._id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <tr
                                            key={item._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <th scope="row">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="chk_child"
                                                  value={item._id}
                                                  checked={item.isChecked}
                                                  onChange={(e) =>
                                                    handleChange(e)
                                                  }
                                                />
                                              </div>
                                            </th>
                                            <td>{item.playlist_id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.type}</td>
                                            <td>
                                              <button
                                                className="btn-status"
                                                title="Click to change featured"
                                                onClick={() =>
                                                  onClickFeatureChange(
                                                    item._id,
                                                    item.featured ? false : true
                                                  )
                                                }
                                              >
                                                {item.featured ? (
                                                  <span className="badge badge-soft-success text-uppercase">
                                                    Yes
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-soft-danger text-uppercase">
                                                    No
                                                  </span>
                                                )}
                                              </button>
                                            </td>

                                            <td>
                                              <button
                                                className="btn-status"
                                                title="Click to change status"
                                                onClick={() =>
                                                  onClickStatusChange(
                                                    item._id,
                                                    item.status ? false : true
                                                  )
                                                }
                                              >
                                                {item?.status ? (
                                                  <span className="badge badge-soft-success text-uppercase">
                                                    Active
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-soft-danger text-uppercase">
                                                    Inactive
                                                  </span>
                                                )}
                                              </button>
                                            </td>

                                            <td>
                                              <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                                <li
                                                  className="list-inline-item edit"
                                                  title="Edit"
                                                >
                                                  <Link
                                                    className="text-primary d-inline-block edit-item-btn"
                                                    to={
                                                      "/playlist/edit/" +
                                                      item._id
                                                    }
                                                  >
                                                    <i className="ri-more-2-fill fs-16"></i>
                                                  </Link>
                                                </li>

                                                {type === "spotify" ? (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View Sites"
                                                  >
                                                    <a
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      href={
                                                        spotifyUrl +
                                                        "/playlist/" +
                                                        item.playlist_id
                                                      }
                                                      target="_blank"
                                                    >
                                                      <i className="ri-links-line fs-18"></i>
                                                    </a>
                                                  </li>
                                                ) : (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View Sites"
                                                  >
                                                    <a
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      href={
                                                        frontUrl +
                                                        "/playlist/" +
                                                        item._id
                                                      }
                                                      target="_blank"
                                                    >
                                                      <i className="ri-links-line fs-18"></i>
                                                    </a>
                                                  </li>
                                                )}

                                                {/* {type === "youtube" ? (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View"
                                                  >
                                                    <a
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      href={
                                                        frontUrl +
                                                        "/playlist/" +
                                                        item._id
                                                      }
                                                      target="_blank"
                                                    >
                                                      <i className="ri-eye-fill"></i>
                                                    </a>
                                                  </li>
                                                )
                                                : type === "vimeo" ? (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View"
                                                  >
                                                    <a
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      href={
                                                        frontUrl +
                                                        "/playlist/" +
                                                        item._id 
                                                      }
                                                      target="_blank"
                                                    >
                                                      <i className="ri-eye-fill"></i>
                                                    </a>
                                                  </li>
                                                ) : type === "spotify" ? (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View"
                                                  >
                                                    <a
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      href={
                                                        spotifyUrl +
                                                        "/playlist/" +
                                                        item.playlist_id 
                                                      }
                                                      target="_blank"
                                                    >
                                                      <i className="ri-eye-fill"></i>
                                                    </a>
                                                  </li>
                                                ) : ""
                                                } */}

                                                <li
                                                  className="list-inline-item"
                                                  title="Remove"
                                                  id={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <Button className="p-0 bg-transparent border-0 text-danger">
                                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                                  </Button>
                                                </li>
                                                <UncontrolledPopover
                                                  trigger="focus"
                                                  placement="left"
                                                  target={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    Delete Video List
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    Are you sure want to delete?
                                                    <div className="d-flex align-items-center justify-content-end mt-2">
                                                      <Button className="btn btn-sm btn-light">
                                                        No
                                                      </Button>
                                                      <Button
                                                        onClick={() =>
                                                          deletePlaylistData(
                                                            item._id
                                                          )
                                                        }
                                                        className="btn btn-sm btn-danger ms-2"
                                                      >
                                                        Yes
                                                      </Button>
                                                    </div>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </ul>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>
                            {noOfPage > 1 && (
                              <Pagination className="px-3">
                                {Array(noOfPage)
                                  .fill()
                                  .map((_, i) => {
                                    return (
                                      <Fragment key={i}>
                                        {offset > 0 && i === 0 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset - limit);
                                                setCurrentPage(currentPage - 1);
                                              }}
                                            >
                                              ← &nbsp; Prev
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage === i + 1 && (
                                          <PaginationItem active>
                                            <PaginationLink>
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== i + 1 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(limit * i);
                                                setCurrentPage(i + 1);
                                              }}
                                            >
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== noOfPage &&
                                          i + 1 === noOfPage && (
                                            <PaginationItem>
                                              <PaginationLink
                                                to="#"
                                                onClick={() => {
                                                  setOffset(offset + limit);
                                                  setCurrentPage(
                                                    currentPage + 1
                                                  );
                                                }}
                                              >
                                                Next &nbsp; →
                                              </PaginationLink>
                                            </PaginationItem>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                              </Pagination>
                            )}
                          </>
                        </DragDropContext>
                      )}
                    </div>
                  )}
                  {loading && <Loader />}
                  {isSuccess &&
                    !loading &&
                    Array.isArray(playlists) &&
                    playlists.length === 0 && (
                      <h5 className="mt-3 text-center">No Result Found</h5>
                    )}
                  <CommonModal
                    modal={modal}
                    setModal={setModal}
                    onClickDeleteData={onClickDeleteData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
        {orderingMessage && (
          <MsgToast
            msg={orderingMessage}
            color="danger"
            icon="ri-error-warning-line"
          />
        )}
        {saveOrderingSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {saveOrderingFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default List;
