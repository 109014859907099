import {
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAIL,
  ADD_NEW_CONTACT_SUCCESS,
  ADD_NEW_CONTACT_FAIL,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_FAIL,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAIL,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_FAIL,
  STATUS_CHANGE_CONTACT_SUCCESS,
  STATUS_CHANGE_CONTACT_FAIL,
  RESET_CONTACT_FLAG,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  MODIFY_CONTACT_FLAG,
} from "./actionTypes";

const INIT_STATE = {
  contacts: [],
};

const Contacts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_NEW_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: [...state.contacts, action.payload.data],
        success: action.payload.message,
        isContactAdd: true,
        isContactAddFail: false,
      };

    case ADD_NEW_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isContactAdd: false,
        isContactAddFail: true,
      };

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact._id.toString() === action.payload.data._id.toString()
            ? { ...contact, ...action.payload.data }
            : contact
        ),
        success: action.payload.message,
        isContactUpdate: true,
        isContactUpdateFail: false,
      };

    case UPDATE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isContactUpdate: false,
        isContactUpdateFail: true,
      };

    case GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data[0] ? action.payload.data[0] : [],
        isSuccess: true,
      };

    case GET_CONTACT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
      };

    case REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter((contacts) => contacts._id !== action.payload.contacts.id),
        success: action.payload.message,
        isContactRemove: true,
        isContactRemoveFail: true,
      };

    case REMOVE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isContactRemove: false,
        isContactRemoveFail: true,
      };
    //Status Change
    case STATUS_CHANGE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map((contact) =>
          contact._id.toString() === action.payload.data._id.toString()
            ? { ...contact, ...action.payload.data }
            : contact
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };
    case RESET_CONTACT_FLAG:
      return {
        ...state,
        isContactAdd: false,
        isContactAddFail: false,
        isSuccess: false,
        isContactDetails: false,
        isContactUpdate: false,
        isContactUpdateFail: false,
        error: false,
      };

      case DELETE_CONTACT_SUCCESS:
        return { 
          ...state,
          contacts: state.contacts.filter(getData =>
            {
               return getData._id !== action.payload.data._id
            }
            
          ),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isContactRemove: true,
          isContactRemoveFail: false,
        };
    
      case DELETE_CONTACT_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isContactRemove: false,
          isContactRemoveFail: true,
        };

    case MODIFY_CONTACT_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    default:
      return state;
  }
};

export default Contacts;
