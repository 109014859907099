import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const CommonModal = ({ setModal, modal, onClickDeleteData }) => {
  return (
    <>
      <Modal isOpen={modal} trigger="click">
        <ModalHeader>Delete</ModalHeader>
        <ModalBody>
          Are you sure want to delete?
          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              onClick={() => {
                setModal(!modal);
              }}
              className="btn btn-sm btn-light"
            >
              No
            </Button>

            <Button
              onClick={() => {
                onClickDeleteData();
                setModal(!modal);
              }}
              className="btn btn-sm btn-danger ms-2"
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CommonModal;
