module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    AMITY_API_URL: "https://api.us.amity.co/api/v2", 
    API_URL: "https://ceo.iosx.in/api/"
  },
  token: {
    ACCESS_TOKEN: "eyJhbGciOiJSUzI1NiIsImtpZCI6IkVyd1J6MWtiQ0tQcEQweFpSM0tBN055SEFCSGNFd1NnVEZfajFuY29ZNjQifQ.eyJ1c2VyIjp7InVzZXJJZCI6IjYzNGZhMDM1ODgwNzE1ODc1ZTRjODViYiIsInB1YmxpY1VzZXJJZCI6IjY1NDMyMSIsImRldmljZUluZm8iOnsia2luZCI6ImFuZHJvaWQiLCJtb2RlbCI6Im5vdGUgNSIsInNka1ZlcnNpb24iOiIxMS40In0sIm5ldHdvcmtJZCI6IjYzM2E0MDZhNzA1YThlMDBkOTU1MzExYiIsImRpc3BsYXlOYW1lIjoiU3VqYXkiLCJyZWZyZXNoVG9rZW4iOiIzNjllZTMzNGFjMjI5ZmY0MGViZGY5OGM5MzNiNjBmMjRlN2NjNjliNWE4M2U1YTlmMmViMTk1ZmViYTcxYTI1MWMxZDg4Y2JmYzQ4MjMyNyJ9LCJzdWIiOiI2MzRmYTAzNTg4MDcxNTg3NWU0Yzg1YmIiLCJpc3MiOiJodHRwczovL2FwaS51cy5hbWl0eS5jbyIsImlhdCI6MTY2NjE2Mjc0MSwiZXhwIjoxNjk3NzIwMzQxfQ.kwmh182PsF_2OXQZQEK5nopwf4YIC38KxZdWpVEuvqhCIScjb9jjYTcfh5osyUON5MXL6fmRZMY6yYCcKajvRns_951UyykpuNag67ocy5Ns2xicpRs6V1Fw40HSawedXOPFa3L85LhFihYPsF4oPIFFIG5jklmHigxNuODMrfVCEL0vRL3PK-8-CtZCrsvMeCXshPRHRWyipV_7UjFtHHq6G6Y_YqBiBGen0MzPwCe41GxggMOb2P-cZBMrz_q8REpVi-N0KRCHxnilK851XXnJQVRQ59TvtMktx30c35srmw7ARLdgS6vHbqpyHDmULPwfIGc6DFCJqLzlysMp-g"
  }

};