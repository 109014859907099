import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../store/actions";
// actions
import { getUser, resetProfileFlag } from "../../store/actions";
import { convertToBase64 } from "../../helpers/image";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import MsgToast from "../../Components/Common/MsgToast";

const UserProfile = () => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [email, setemail] = useState("admin@gmail.com");
  const [idx, setidx] = useState("1");
  const allowedExts = ["jpg", "jpeg", "png"];
  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    dispatch(getUser());
  }, []);

  const { user, success, error, isUserUpdate, isUserUpdateFail } = useSelector((state) => ({
    user: state.Profile.getuser,
    success: state.Users.success,
    error: state.Users.error,
    isUserUpdate: state.Users.isUserUpdate,
    isUserUpdateFail: state.Users.isUserUpdateFail
  }));

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));

      if (!isEmpty(user)) {
        obj.data.name = user.name;
        sessionStorage.removeItem("authUser");
        sessionStorage.setItem("authUser", JSON.stringify(obj));
      }

      setUserName(obj.data.name);
      setemail(obj.data.email);
      setidx(obj.data._id || "1");

      if (isUserUpdate || isUserUpdateFail) {
        setTimeout(() => {
          dispatch(resetProfileFlag());
        }, 1000);
      }
    }
  }, [dispatch, user, success, isUserUpdate, isUserUpdateFail,]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: (user && user?.name) || "Admin",
      email: user && user?.email,
      description: user && user?.abouts,
      id: user?._id,
      image: user && user.image ? user.image : "",
      idx: idx || "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      const data = {
        id: values.id,
        name: values.name,
        email: values.email,
        password: values.password,
        image: image,
      };
      dispatch(updateUser(data));
    },
  });

  const onImgChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src);
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };


  document.title = "Profile | CEO";
  return (
    <div className="page-content">
      <Container fluid={true}>
        <BreadCrumb
          title="Edit Profile"
          pageTitle="Profile"
          pageUrl="/profile"
        />
        <Row>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            {isUserUpdate ? (
              <MsgToast
                msg={error}
                color="danger"
                icon="ri-error-warning-line"
              />
            ) : (
              ""
            )}
            {isUserUpdate && (
              <MsgToast
                msg={success}
                color="success"
                icon="ri-checkbox-circle-line"
              />
            )}
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="mx-3">
                    <img
                      id="preview"
                      src={validation.values.image}
                      alt=""
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <h5>{validation.values.name || "Admin"}</h5>
                      <p className="mb-1">Email Id : {email}</p>
                      {/* <p className="mb-0">Id No : #{idx}</p> */}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Row>
              <Col lg={8}>
                <Card>
                  <PreviewCardHeader title={`Edit Profile`} />

                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={12}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Name *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Email
                            </Label>
                            <Input
                              name="email"
                              // value={name}
                              className="form-control"
                              placeholder="Enter Email"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={6} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Password
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Password"
                              name="password"
                              value={validation.values.password || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" className="btn-load" type="submit">
                      <span className="d-flex align-items-center">
                        <span className="flex-grow-1">Update</span>
                      </span>
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <PreviewCardHeader title="Image *" />
                  <CardBody>
                    <div className="mb-4">
                      <Input
                        type="file"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Place your image"
                        name="image"
                        onChange={onImgChange}
                      />
                    </div>
                    <div className="image-container">
                      <img
                        src={validation.values.image || ""}
                        alt=""
                        id="image1"
                      />
                    </div>
                    {validation.touched.image && validation.errors.image ? (
                      <FormFeedback type="invalid">
                        {validation.errors.image}
                      </FormFeedback>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Row>

        <ToastContainer
          limit={1}
          closeButton={false}
          theme="dark"
          autoClose={2000}
        />
      </Container>
    </div>
  );
};

export default UserProfile;
