import {
  GET_CONNECTIONS_FAIL,
  GET_CONNECTIONS_SUCCESS,
  ADD_CONNECTION_SUCCESS,
  ADD_CONNECTION_FAIL,
  DELETE_CONNECTION_SUCCESS,
  RESET_CONNECTION_FLAG,
  DELETE_CONNECTION_FAIL,
  STATUS_CHANGE_CONNECTION_SUCCESS,
  STATUS_CHANGE_CONNECTION_FAIL,
  MODIFY_CONNECTION_FLAG,
  SAVE_ORDERING_CONNECTION_SUCCESS,
  SAVE_ORDERING_CONNECTION_FAIL,
  SAVE_ORDERING_CONNECTION_RESET_FLAG,
} from "./actionType";

const INIT_STATE = {
  connections: [],
  error: {},
};

const Connections = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONNECTIONS_SUCCESS:
      return {
        ...state,
        connections: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
      };

    case GET_CONNECTIONS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
      };

    case ADD_CONNECTION_SUCCESS:
      return {
        ...state,
        connections: [...state.connections, action.payload.data],
        success: action.payload.message,
        isConnectionAdd: true,
        isConnectionAddFail: false,
      };

    case ADD_CONNECTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isConnectionAdd: false,
        isConnectionAddFail: true,
      };

    case MODIFY_CONNECTION_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    case RESET_CONNECTION_FLAG:
      return {
        ...state,
        isConnectionAdd: false,
        isConnectionAddFail: false,
        isSuccess: false,
        isConnectionDetails: false,
        isConnectionUpdate: false,
        isConnectionUpdateFail: false,
        error: false,
      };

    // Status Change
    case STATUS_CHANGE_CONNECTION_SUCCESS:
      return {
        ...state,
        connections: state.connections.map((connection) =>
          action.payload.connections.id.includes(connection._id)
            ? { ...connection, status: action.payload.connections.column_value }
            : connection
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_CONNECTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    //Save Ordering
    case SAVE_ORDERING_CONNECTION_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_CONNECTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_CONNECTION_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    //Delete

    case DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        connections: state.connections.filter((connection) => {
          return !action.payload.data.includes(connection._id);
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isConnectionRemove: true,
        isConnectionRemoveFail: false,
      };

    case DELETE_CONNECTION_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isConnectionRemove: false,
        isConnectionRemoveFail: true,
      };

    default:
      return state;
  }
};

export default Connections;
