import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updateSite,
  resetSiteFlag,
  getSiteDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";

const SiteEdit = () => {
  document.title = "Edit Site | CEO";
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [description, setData] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [logo, setLogo] = useState();
  const [footer_logo, setAltlogo] = useState();
  const { isSiteUpdate, isSiteUpdateFail, error, success, details } =
    useSelector((state) => ({
      isSiteUpdate: state.Sites.isSiteUpdate,
      isSiteUpdateFail: state.Sites.isSiteUpdateFail,
      error: state.Sites.error,
      success: state.Sites.success,
      details: state.Sites.details,
    }));

  useEffect(() => {
    dispatch(getSiteDetails({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isSiteUpdate || isSiteUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetSiteFlag());
      }, 3000);
    }
  }, [dispatch, isSiteUpdate, isSiteUpdateFail]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      logo: details && details.logo ? details.logo : "",
      footer_logo: details && details.footer_logo ? details.footer_logo : "",
      description: details && details.description ? details.description : "",
      email: details && details.email ? details.email : "",
      phone: details && details.phone ? details.phone : "",
      address: details && details.address ? details.address : "",
      copyright: details && details.copyright ? details.copyright : "",
      spotify_cllient_id:
        details && details.spotify_cllient_id ? details.spotify_cllient_id : "",
      spotify_secret_id:
        details && details.spotify_secret_id ? details.spotify_secret_id : "",
      ghost_api: details && details.ghost_api ? details.ghost_api : "",
      ghost_url: details && details.ghost_url ? details.ghost_url : "",
      facebook_link:
        details && details.facebook_link ? details.facebook_link : "",
      twitter_link: details && details.twitter_link ? details.twitter_link : "",
      Linkedin_link:
        details && details.Linkedin_link ? details.Linkedin_link : "",
      youtube_link: details && details.youtube_link ? details.youtube_link : "",
      youtube_api: details && details.youtube_api ? details.youtube_api : "",
      vimeo_api: details && details.vimeo_api ? details.vimeo_api : "",
      amity_api_key:
        details && details.amity_api_key ? details.amity_api_key : "",
      amity_server_key:
        details && details.amity_server_key ? details.amity_server_key : "",
      amity_admin_token:
        details && details.amity_admin_token ? details.amity_admin_token : "",
      community_id: details && details.community_id ? details.community_id : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Job Title"),
      logo: Yup.string().required("Please Enter Logo"),
      email: Yup.string().required("Please Enter Email"),
      description: Yup.string().required("Please Enter Description"),
      copyright: Yup.string().required("Please Enter Copyright"),
      youtube_api: Yup.string().required("Youtube API is required"),
      vimeo_api: Yup.string().required("Vimeo API is required"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        title: values.title,
        phone: values.phone,
        logo: logo,
        footer_logo: footer_logo,
        description: description,
        email: values.email,
        address: values.address,
        copyright: values.copyright,
        facebook_link: values.facebook_link,
        twitter_link: values.twitter_link,
        Linkedin_link: values.Linkedin_link,
        youtube_link: values.youtube_link,
        youtube_api: values.youtube_api,
        vimeo_api: values.vimeo_api,
        spotify_cllient_id: values.spotify_cllient_id,
        spotify_secret_id: values.spotify_secret_id,
        ghost_api: values.ghost_api,
        ghost_url: values.ghost_url,
        amity_api_key: values.amity_api_key,
        amity_server_key: values.amity_server_key,
        amity_admin_token: values.amity_admin_token,
        community_id: values.community_id,
      };
      dispatch(updateSite(data));
    },
  });

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];

  const onLogoChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setLogo);
      }
    }
  };

  const onaltLogoChange = (e) => {
    if (e.target.files[0]) {
      const exten = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(exten)) {
        toast("Please upload a valid alternative image.");
      } else {
        var output = document.getElementById("image2");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setAltlogo);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Settings" pageTitle="Sites" pageUrl="/sites" />

          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row>
              <Col lg={9}>
                <Card>
                  <PreviewCardHeader title="Informations" />
                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={9} md={8}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Job title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.title}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={3} md={4}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Copyright *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Copyright"
                              name="copyright"
                              value={validation.values.copyright || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.copyright &&
                                validation.errors.copyright
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.copyright &&
                            validation.errors.copyright ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.copyright}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Email *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={validation.values.email || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.email}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <PreviewCardHeader title="API & Integrations" />
                  <CardBody>
                    <div className="live-preview">
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {" "}
                              Youtube API Key*{" "}
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Youtube API"
                              name="youtube_api"
                              value={validation.values.youtube_api || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.youtube_api &&
                                validation.errors.youtube_api
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.youtube_api &&
                            validation.errors.youtube_api ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.youtube_api}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {" "}
                              Vimeo API Key*{" "}
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Vimeo API"
                              name="vimeo_api"
                              value={validation.values.vimeo_api || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.vimeo_api &&
                                validation.errors.vimeo_api
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.vimeo_api &&
                            validation.errors.vimeo_api ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.vimeo_api}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Spotify Client ID *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Spotify Client ID"
                              name="spotify_cllient_id"
                              value={validation.values.spotify_cllient_id || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.spotify_cllient_id &&
                                validation.errors.spotify_cllient_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.spotify_cllient_id &&
                            validation.errors.spotify_cllient_id ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.spotify_cllient_id}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Spotify Secret ID *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Spotify Secret ID"
                              name="spotify_secret_id"
                              value={validation.values.spotify_secret_id || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.spotify_secret_id &&
                                validation.errors.spotify_secret_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.spotify_secret_id &&
                            validation.errors.spotify_secret_id ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.spotify_secret_id}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {" "}
                              Amity API Key{" "}
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Amity API Key"
                              name="amity_api_key"
                              value={validation.values.amity_api_key || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.amity_api_key &&
                                validation.errors.amity_api_key
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.amity_api_key &&
                            validation.errors.amity_api_key ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.amity_api_key}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Amity Server Key
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Amity Server Key"
                              name="amity_server_key"
                              value={validation.values.amity_server_key || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.amity_server_key &&
                                validation.errors.amity_server_key
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.amity_server_key &&
                            validation.errors.amity_server_key ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.amity_server_key}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Amity Admin Token
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Amity Admin Token"
                              name="amity_admin_token"
                              value={validation.values.amity_admin_token || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.amity_admin_token &&
                                validation.errors.amity_admin_token
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.amity_admin_token &&
                            validation.errors.amity_admin_token ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.amity_admin_token}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {" "}
                              Ghost API URL *{" "}
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Ghost API URL"
                              name="ghost_url"
                              value={validation.values.ghost_url || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.ghost_url &&
                                validation.errors.ghost_url
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.ghost_url &&
                            validation.errors.ghost_url ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.ghost_url}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              {" "}
                              Ghost API Key *{" "}
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Ghost API Key"
                              name="ghost_api"
                              value={validation.values.ghost_api || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.ghost_api &&
                                validation.errors.ghost_api
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.ghost_api &&
                            validation.errors.ghost_api ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.ghost_api}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xxl={4} md={6}>
                          <div className="mb-4">
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Communit ID
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Community ID"
                              name="community_id"
                              value={validation.values.community_id || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{ required: { value: true } }}
                              invalid={
                                validation.touched.community_id &&
                                validation.errors.community_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.community_id &&
                            validation.errors.community_id ? (
                              <FormFeedback type="invalid">
                                {" "}
                                {validation.errors.community_id}{" "}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}>
                <Card>
                  <PreviewCardHeader title="Logo *" />
                  <CardBody>
                    <div className="mb-4">
                      <Input
                        type="file"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Place your logo"
                        name="logo"
                        onChange={onLogoChange}
                      />
                    </div>
                    <div className="image-container w-50">
                      <img
                        src={validation.values.logo || ""}
                        alt=""
                        id="image1"
                      />
                    </div>
                    {validation.touched.logo && validation.errors.logo ? (
                      <FormFeedback type="invalid">
                        {" "}
                        {validation.errors.logo}{" "}
                      </FormFeedback>
                    ) : null}
                  </CardBody>
                </Card>
                <Card>
                  <PreviewCardHeader title="Alternative Logo" />
                  <CardBody>
                    <div className="mb-4">
                      <Input
                        type="file"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Place your alternative logo"
                        name="footer_logo"
                        onChange={onaltLogoChange}
                      />
                    </div>
                    <div className="image-container w-50">
                      <img
                        src={validation.values.footer_logo || ""}
                        alt=""
                        id="image2"
                      />
                    </div>
                    {validation.touched.footer_logo &&
                    validation.errors.footer_logo ? (
                      <FormFeedback type="invalid">
                        {" "}
                        {validation.errors.footer_logo}{" "}
                      </FormFeedback>
                    ) : null}
                  </CardBody>
                </Card>
                <Card>
                  <PreviewCardHeader title="Social Links" />
                  <CardBody>
                    <div className="mb-4">
                      <Label htmlFor="placeholderInput" className="form-label">
                        {" "}
                        Facebook Link{" "}
                      </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Facebook Link"
                        name="facebook_link"
                        value={validation.values.facebook_link || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{ required: { value: true } }}
                        invalid={
                          validation.touched.facebook_link &&
                          validation.errors.facebook_link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.facebook_link &&
                      validation.errors.facebook_link ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.facebook_link}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="placeholderInput" className="form-label">
                        {" "}
                        Twitter Link{" "}
                      </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Twitter Link"
                        name="twitter_link"
                        value={validation.values.twitter_link || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{ required: { value: true } }}
                        invalid={
                          validation.touched.twitter_link &&
                          validation.errors.twitter_link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.twitter_link &&
                      validation.errors.twitter_link ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.twitter_link}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="placeholderInput" className="form-label">
                        LinkedIn Link
                      </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="LinkedIn Link"
                        name="Linkedin_link"
                        value={validation.values.Linkedin_link || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{ required: { value: true } }}
                        invalid={
                          validation.touched.Linkedin_link &&
                          validation.errors.Linkedin_link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.Linkedin_link &&
                      validation.errors.Linkedin_link ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.Linkedin_link}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-4">
                      <Label htmlFor="placeholderInput" className="form-label">
                        Youtube Link
                      </Label>
                      <Input
                        type="text"
                        id="placeholderInput"
                        className="form-control"
                        placeholder="Youtube Link"
                        name="youtube_link"
                        value={validation.values.youtube_link || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        validate={{ required: { value: true } }}
                        invalid={
                          validation.touched.youtube_link &&
                          validation.errors.youtube_link
                            ? true
                            : false
                        }
                      />
                      {validation.touched.youtube_link &&
                      validation.errors.youtube_link ? (
                        <FormFeedback type="invalid">
                          {" "}
                          {validation.errors.youtube_link}{" "}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                <div className="d-flex gap-2 mb-4">
                  <Link to="/jobs" className="btn btn-cancel w-sm me-1">
                    {" "}
                    Cancel{" "}
                  </Link>
                  <Button
                    color="primary"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          Update
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          {isSiteUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isSiteUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default SiteEdit;
