import {
  GET_PLAYLIST,
  GET_PLAYLIST_SUCCESS,
  GET_PLAYLIST_FAIL,
  ADD_NEW_PLAYLIST,
  ADD_NEW_PLAYLIST_SUCCESS,
  ADD_NEW_PLAYLIST_FAIL,
  UPDATE_PLAYLIST,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAIL,
  GET_PLAYLIST_DETAILS,
  GET_PLAYLIST_DETAILS_SUCCESS,
  GET_PLAYLIST_DETAILS_FAIL,
  DELETE_PLAYLIST,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAIL,
  MODIFY_PLAYLIST_FLAG,
  RESET_PLAYLIST_FLAG,
  STATUS_CHANGE_PLAYLIST,
  STATUS_CHANGE_PLAYLIST_SUCCESS,
  STATUS_CHANGE_PLAYLIST_FAIL,
  FEATURED_CHANGE_PLAYLIST,
  FEATURED_CHANGE_PLAYLIST_SUCCESS,
  FEATURED_CHANGE_PLAYLIST_FAIL,
  SAVE_ORDERING_PLAYLIST,
  SAVE_ORDERING_PLAYLIST_SUCCESS,
  SAVE_ORDERING_PLAYLIST_FAIL,
  SAVE_ORDERING_PLAYLIST_RESET_FLAG
} from "./actionType";

export const getPlaylists = data => ({
  type: GET_PLAYLIST,
  payload: data,
});

export const getPlaylistsSuccess = playlists => ({
  type: GET_PLAYLIST_SUCCESS,
  payload: playlists,
});

export const getPlaylistsFail = error => ({
  type: GET_PLAYLIST_FAIL,
  payload: error,
});

export const addNewPlaylist = playlists => ({
  type: ADD_NEW_PLAYLIST,
  payload: playlists,
});

export const addNewPlaylistSuccess = playlists => ({
  type: ADD_NEW_PLAYLIST_SUCCESS,
  payload: playlists,
});

export const addNewPlaylistFail = error => ({
  type: ADD_NEW_PLAYLIST_FAIL,
  payload: error,
});

export const updatePlaylist = playlists => ({
  type: UPDATE_PLAYLIST,
  payload: playlists,
});

export const updatePlaylistSuccess = playlists => ({
  type: UPDATE_PLAYLIST_SUCCESS,
  payload: playlists,
});

export const updatePlaylistFail = error => ({
  type: UPDATE_PLAYLIST_FAIL,
  payload: error,
});

export const getPlaylistDetails = playlists => ({
  type: GET_PLAYLIST_DETAILS,
  payload: playlists,
});

export const getPlaylistDetailsSuccess = playlists => ({
  type: GET_PLAYLIST_DETAILS_SUCCESS,
  payload: playlists,
});

export const getPlaylistDetailsFail = error => ({
  type: GET_PLAYLIST_DETAILS_FAIL,
  payload: error,
});

export const delPlaylist = data => ({
  type: DELETE_PLAYLIST,
  payload: data,
});

export const deletePlaylistSuccess = data => ({
  type: DELETE_PLAYLIST_SUCCESS,
  payload: data,
});

export const deletePlaylistFail = data => ({
  type: DELETE_PLAYLIST_FAIL,
  payload: data,
});

export const statusChangePlaylist = playlists => ({
  type: STATUS_CHANGE_PLAYLIST,
  payload: playlists,
});

export const statusChangePlaylistSuccess = playlists => ({
  type: STATUS_CHANGE_PLAYLIST_SUCCESS,
  payload: playlists,
});

export const statusChangePlaylistFail = error => ({
  type: STATUS_CHANGE_PLAYLIST_FAIL,
  payload: error,
});

// Featured Change
export const featuredChangePlaylist = playlists => ({
  type: FEATURED_CHANGE_PLAYLIST,
  payload: playlists,
});

export const featuredChangePlaylistSuccess = playlists => ({
  type: FEATURED_CHANGE_PLAYLIST_SUCCESS,
  payload: playlists,
});

export const featuredChangePlaylistFail = error => ({
  type: FEATURED_CHANGE_PLAYLIST_FAIL,
  payload: error,
});

export const modifyPlaylistFlag = () => {
  return {
    type: MODIFY_PLAYLIST_FLAG,
  }
}

export const resetPlaylistFlag = () => {
  return {
    type: RESET_PLAYLIST_FLAG,
  }
}


//Save Ordaring
export const saveOrderingPlaylist = data => ({
  type: SAVE_ORDERING_PLAYLIST,
  payload: data,
});

export const saveOrderingPlaylistSuccess = data => ({
  type: SAVE_ORDERING_PLAYLIST_SUCCESS,
  payload: data,
});

export const saveOrderingPlaylistFail = error => ({
  type: SAVE_ORDERING_PLAYLIST_FAIL,
  payload: error,
});


//Save ordering reset Flag for toaster
export const saveOrderingPlaylistResetFlag = () => {
  return {
    type: SAVE_ORDERING_PLAYLIST_RESET_FLAG,
  }
}



