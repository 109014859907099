import { useEffect, useState, useRef, Fragment } from "react";
import { APIClient } from "../../../helpers/api_helper";
import axios from "axios";
import hostUrl from "../../../config/host";
import amityToken from "../../../config/token";
import { statusChangeUser } from "../../../store/actions";

import {
  Container,
  Row,
  Col,
  CardHeader,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import MsgToast from "../../../Components/Common/MsgToast";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  getUsers,
  resetUserFlag,
  modifyUserFlag,
} from "../../../store/actions";
import Moment from "react-moment";
import "moment-timezone";

const UserList = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "User list | " + metatitle;
  const fp = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState("");
  const [sortKey, setSortByField] = useState("created_at");
  const [sortValue, setSortByValue] = useState("DESC");
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("user");
  const [email_verified, setVerified] = useState("");
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const {
    users,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    loading,
  } = useSelector((state) => ({
    users: state.Users.users,
    totalDataCount: state.Users.totalDataCount,
    isSuccess: state.Users.isSuccess,
    error: state.Users.error,
    success: state.Users.success,
    statusSuccess: state.Users.statusSuccess,
    statusFailed: state.Users.statusFailed,
    loading: state.Users.loading,
  }));

  console.log(loading);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    dispatch(
      getUsers({
        keyword,
        sortKey,
        sortValue,
        limit,
        offset,
        type,
        email_verified,
      })
    );
  }, [
    dispatch,
    keyword,
    sortKey,
    sortValue,
    limit,
    offset,
    type,
    email_verified,
  ]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getUsers({ limit, dateRange, offset, keyword, type }));
    }
  }, [dispatch, date, keyword, limit, offset, type]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const resetFilter = () => {
    dispatch(resetUserFlag());
    setKeyword("");
    fp.current.flatpickr.clear();
    setDate(null);
    setLimit(10);
    setVerified("");
    dispatch(getUsers({ keyword, sortKey, sortValue, limit, offset, type }));
  };

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === "ASC") {
        setSortByValue("DESC");
      } else {
        setSortByValue("ASC");
      }
    } else {
      setSortByValue("DESC");
    }
    setSortByField(fieldName);
  };
  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyUserFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  const BanUser = (userID) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${amityToken}`,
    };

    axios
      .post(`${hostUrl}/v2/users/ban`, { userId: userID }, { headers: headers })
      .then(function (response) {})

      .catch(function (error) {});
  };

  function onClickStatusChange(id, status) {
    let idChecked = id.split(" ");
    dispatch(
      statusChangeUser({
        id: idChecked,
        email_verified: status,
      })
    );
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="User" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0 d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">User List</h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-4">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={email_verified}
                          onChange={(e) => {
                            const value =
                              e.target.value === "true"
                                ? Boolean(e.target.value)
                                : e.target.value === "false"
                                ? false
                                : "";
                            setVerified(value);
                          }}
                        >
                          <option value="">Email Verified</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </Col>
                      <Col sm={3} className="col-xxl-3">
                        <div>
                          <Flatpickr
                            ref={fp}
                            className="form-control"
                            id="datepicker-publish-input"
                            placeholder="Select a date"
                            onChange={(e) => {
                              setDate({ e });
                            }}
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              mode: "range",
                              dateFormat: "d.m.y",
                            }}
                          />
                        </div>
                      </Col>

                      <Col sm={1} className="col-xxl-1">
                        <div className="d-flex">
                          <Button
                            className="btn-reset"
                            color="grey"
                            onClick={resetFilter}
                            id="resetTooltip"
                          >
                            <i className="ri-restart-line align-bottom"></i>
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="resetTooltip"
                          >
                            Reset
                          </UncontrolledTooltip>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(users) && users.length > 0 && (
                        <>
                          <table
                            className="table align-middle table-nowrap"
                            id="customerTable"
                          >
                            <thead className="table-light">
                              <tr>
                                <th className="cursor-pointer">Image</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="name"
                                  onClick={() => sortBy("name")}
                                >
                                  Name
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="email"
                                  onClick={() => sortBy("email")}
                                >
                                  Email
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="company_name"
                                  onClick={() => sortBy("company_name")}
                                >
                                  Company Name
                                </th>
                                <th className="cursor-pointer">Headline</th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="phone"
                                  onClick={() => sortBy("phone")}
                                >
                                  Phone
                                </th>
                                <th
                                  className="sort cursor-pointer"
                                  data-sort="created_at"
                                  onClick={() => sortBy("created_at")}
                                >
                                  Member Since
                                </th>
                                {/* <th
                                  className="sort cursor-pointer"
                                  data-sort="email_verified"
                                  onClick={() => sortBy("email_verified")}
                                >
                                  Email Verified
                                </th> */}
                                <th className="text-end">Action</th>
                              </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {users.map((item) => (
                                <tr key={item._id}>
                                  <td>
                                    {" "}
                                    <img
                                      className="image avatar-xs rounded-circle"
                                      src={item.image}
                                      alt=""
                                      width="50px"
                                    ></img>
                                  </td>
                                  <td>{item.name}</td>
                                  <td>
                                    {item.email}
                                    <br></br>
                                    <button
                                      className="btn-status"
                                      title="Click to change email status"
                                      onClick={() =>
                                        onClickStatusChange(
                                          item?._id,
                                          item?.email_verified ? false : true
                                        )
                                      }
                                    >
                                      {item.email_verified ? (
                                        <span className="badge badge-soft-success text-uppercase">
                                          Verified
                                        </span>
                                      ) : (
                                        <span className="badge badge-soft-danger text-uppercase">
                                          Not Verified
                                        </span>
                                      )}
                                    </button>
                                  </td>
                                  <td>{item?.company_name}</td>
                                  <td>{item?.headline}</td>
                                  <td>{item?.phone}</td>
                                  <td>
                                    <Moment format="D MMM YYYY">
                                      {item.created_at}
                                    </Moment>
                                  </td>
                                  {/* <td>
                                    {item.email_verified ? (
                                      <span className="badge badge-soft-success text-uppercase">
                                        Yes
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger text-uppercase">
                                        No
                                      </span>
                                    )}
                                  </td> */}

                                  <td>
                                    <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                      <li
                                        className="list-flex-item"
                                        title="Edit"
                                      >
                                        <Link
                                          className="text-primary d-inline-block edit-item-btn h4 m-0"
                                          to={"/user/edit/" + item._id}
                                        >
                                          <i className="ri-pencil-line me-2"></i>
                                        </Link>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {noOfPage > 1 && (
                            <Pagination className="px-3">
                              {Array(noOfPage)
                                .fill()
                                .map((_, i) => {
                                  return (
                                    <Fragment key={i}>
                                      {offset > 0 && i === 0 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(offset - limit);
                                              setCurrentPage(currentPage - 1);
                                            }}
                                          >
                                            {" "}
                                            ← &nbsp; Prev{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage === i + 1 && (
                                        <PaginationItem active>
                                          {" "}
                                          <PaginationLink>
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== i + 1 && (
                                        <PaginationItem>
                                          {" "}
                                          <PaginationLink
                                            to="#"
                                            onClick={() => {
                                              setOffset(limit * i);
                                              setCurrentPage(i + 1);
                                            }}
                                          >
                                            {" "}
                                            {i + 1}{" "}
                                          </PaginationLink>{" "}
                                        </PaginationItem>
                                      )}
                                      {currentPage !== noOfPage &&
                                        i + 1 === noOfPage && (
                                          <PaginationItem>
                                            {" "}
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset + limit);
                                                setCurrentPage(currentPage + 1);
                                              }}
                                            >
                                              {" "}
                                              Next &nbsp; →{" "}
                                            </PaginationLink>{" "}
                                          </PaginationItem>
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Pagination>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {loading && <Loader />}
                  {isSuccess &&
                    !loading &&
                    Array.isArray(users) &&
                    users.length === 0 && (
                      <h5 className="mt-3 text-center">No Result Found</h5>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </>
  );
};

export default UserList;
