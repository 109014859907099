import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useHistory } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { addNewRole, resetUserFlag } from "../../../store/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
const RoleCreate = () => {
  const api = new APIClient();
  const history = useHistory();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit User | " + metatitle;
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const { isRolesAdd, isRolesAddFail, error, success } = useSelector(
    (state) => ({
      isRolesAdd: state.Roles.isRolesAdd,
      isRolesAddFail: state.Roles.isRolesAddFail,
      error: state.Roles.error,
      success: state.Roles.success,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      code: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      let data = {};
      data = {
        name: values.name,
        code: values.code,
      };

      dispatch(addNewRole(data));
    },
  });

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {
        id: "624c1d79ac364e5ef3796d76",
      });
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    if (isRolesAdd || isRolesAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetUserFlag());
        history.push("/roles");
      }, 3000);
    }
  }, [dispatch, isRolesAdd, isRolesAddFail, validation]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Add Roles & Permissions"
            pageTitle="Roles"
            pageUrl="/roles"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Add Roles & Permissions" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Role ID *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Role ID"
                              name="name"
                              value={validation.values.name || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Code
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Code"
                              name="code"
                              value={validation.values.code || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.code &&
                                validation.errors.code
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.code &&
                            validation.errors.code ? (
                              <FormFeedback type="invalid">
                                {validation.errors.code}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/roles" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isRolesAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isRolesAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default RoleCreate;
