import React, { useEffect, useState } from "react";
import { APIClient } from "../../../helpers/api_helper";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../Components/Common/Loader";
import { getSiteDetails } from "../../../store/actions";
import PlaylistTabs from "../../../Components/TabNavigation/PlaylistTabs";
function ViewPage() {
  const dispatch = useDispatch();
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const { id } = useParams();
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [video, setVideo] = useState([]);
  const [loader, setLoader] = useState(false);
  const searchParams = new URLSearchParams(window.location.search).get("type");

  let url = "";
  const { details, playlistDetails } = useSelector((state) => ({
    details: state.Sites.details,
  }));

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    dispatch(getSiteDetails({ id: "624c1d79ac364e5ef3796d76" }));
  }, [dispatch]);

  useEffect(() => {
    setLoader(true);
    if (details !== undefined && searchParams === "youtube") {
      fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${details.youtube_api}&part=snippet&playlistId=${id}&maxResults=${limit}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setVideo(result.items);
            setLoader(false);
          },
          (error) => {}
        );
    }
    setLoader(true);
    if (details !== undefined && searchParams === "vimeo") {
      if (keyword) {
        url = `https://api.vimeo.com/channels/${id}/videos?per_page=${limit}&query=${keyword}`;
      } else {
        url = `https://api.vimeo.com/channels/${id}/videos?per_page=${limit}`;
      }
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + details.vimeo_api,
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setVideo(result.data);
            setLoader(false);
          },
          (error) => {}
        );
    }
    setLoader(true);
    if (details !== undefined && searchParams === "spotify") {
      fetch(`https://ceofront.iosx.in/spotify_token.php`)
        .then((res) => res.text())
        .then((result) => {
          fetch(
            `https://api.spotify.com/v1/playlists/${id}/tracks?limit=${limit}&offset=${offset}`,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + result,
                "Content-Type": "application/json",
              },
            }
          )
            .then((resp) => resp.json())
            .then(
              (response) => {
                setVideo(response.items);
                setLoader(false);
              },
              (error) => {}
            );
        });
    }
  }, [id, limit, details, offset, keyword]);

  document.title = "Video Details| " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="VideoDetails" />
          <PlaylistTabs id={id} type={details.type} />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      {playlistDetails && searchParams === "youtube"
                        ? "Youtube"
                        : searchParams === "vimeo"
                        ? "Vimeo"
                        : searchParams === "spotify"
                        ? "Spotify"
                        : ""}{" "}
                      Video Details
                    </h5>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(+e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          {/* <option value="1">1</option> */}
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      {searchParams === "vimeo" && (
                        <Col sm={2} className="col-xxl-2">
                          <div className="search-box">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search"
                              value={keyword}
                              onChange={(e) => setKeyword(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </form>
                </CardBody>

                {loader && <Loader></Loader>}

                {!loader && (
                  <CardBody className="pt-0">
                    <div className="table-responsive table-card mt-3 mb-1">
                      {video?.length > 0 ? (
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Image</th>
                              <th>title</th>
                              <th>Link</th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {video &&
                              video.length > 0 &&
                              video.map((item, index) => {
                                let link = "";
                                if (searchParams === "youtube") {
                                  let text = item.snippet?.resourceId?.videoId;

                                  link =
                                    "https://www.youtube.com/watch?v=" + text;
                                } else if (searchParams === "vimeo") {
                                  let text = item.uri;
                                  const myArray = text.split("/")[2];
                                  link = "https://vimeo.com/" + myArray;
                                } else if (searchParams === "spotify") {
                                  link = item.track?.external_urls?.spotify;
                                }

                                return (
                                  <tr key={index}>
                                    {item && searchParams === "youtube" ? (
                                      <>
                                        <td>
                                          {item.snippet?.thumbnails?.standard
                                            ?.url && (
                                            <img
                                              className="image avatar-xs rounded-circle"
                                              src={
                                                item.snippet?.thumbnails
                                                  ?.standard?.url
                                              }
                                              alt=""
                                              width="50px"
                                            ></img>
                                          )}
                                        </td>
                                        <td>{item?.snippet?.title}</td>
                                        <td>
                                          <a href={link} target="_blank">
                                            {link}
                                          </a>
                                        </td>
                                      </>
                                    ) : item && searchParams === "vimeo" ? (
                                      <>
                                        <td>
                                          <img
                                            className="image avatar-xs rounded-circle"
                                            src={item.pictures?.base_link}
                                            alt=""
                                            width="50px"
                                          ></img>
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>
                                          <a href={link} target="_blank">
                                            {link}
                                          </a>
                                        </td>
                                      </>
                                    ) : item && searchParams === "spotify" ? (
                                      <>
                                        <td>
                                          <img
                                            className="image avatar-xs rounded-circle"
                                            src={
                                              item.track?.album?.images[0].url
                                            }
                                            alt=""
                                            width="50px"
                                          ></img>
                                        </td>
                                        <td>{item?.track?.name}</td>
                                        <td>
                                          <a href={link} target="_blank">
                                            {link}
                                          </a>
                                        </td>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <div className="noresult">
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: "75px", height: "75px" }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ViewPage;
