import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { APIClient } from "../../../helpers/api_helper";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import UserNav from "../../../Components/UserNavigation/UserNav";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
const ViewUsers = () => {
  const api = new APIClient();
  const { id } = useParams();
  const [viewUsers, setViewusers] = useState([]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("profile-details", {
        user_id: id,
      });
      setViewusers(response.data);
    };
    request();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title={`User Details of ${viewUsers.first_name}`} />
          <Row>
            <Col lg={12}>
              <UserNav user_id={id} />
            </Col>
            <Col lg={8}>
              <div id="orderList">
                {viewUsers && (
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                          About {viewUsers.first_name}
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="card-descrption">
                        {viewUsers?.abouts?.title}
                      </div>
                    </CardBody>
                  </Card>
                )}
                {viewUsers.headline && (
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                          Headline
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="card-descrption">
                        {viewUsers?.headline}
                      </div>
                    </CardBody>
                  </Card>
                )}
                {viewUsers.experiences && (
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                          Experience
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {viewUsers.experiences &&
                        viewUsers.experiences.length > 0 &&
                        viewUsers.experiences.map((item, index) => {
                          return (
                            <>
                              <div className="card-block" key={item._id}>
                                <h5 className="card-subtitle">{item?.title}</h5>
                                <div className="subtext">
                                  {item?.company_name}
                                </div>
                                {item?.is_currently_working !== true ? (
                                  <div className="meta">
                                    <Moment format="D MMM YYYY">
                                      {item?.start_date}
                                    </Moment>
                                    -
                                    <Moment format="D MMM YYYY">
                                      {item?.end_date}
                                    </Moment>
                                  </div>
                                ) : (
                                  <div className="meta">
                                    <Moment format="D MMM YYYY">
                                      {item?.start_date}
                                    </Moment>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </CardBody>
                  </Card>
                )}
                {viewUsers.educations && (
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                          Education
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      {viewUsers.educations &&
                        viewUsers.educations.length > 0 &&
                        viewUsers.educations.map((item, index) => {
                          return (
                            <>
                              <div className="card-block" key={item._id}>
                                <h5 className="card-subtitle">
                                  {item?.institution}
                                </h5>

                                {item.graduation &&
                                  item.graduation.length > 0 &&
                                  item.graduation.map((item, index) => {
                                    return (
                                      <>
                                        <div className="subtext">
                                          {item?.name}
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        })}
                    </CardBody>
                  </Card>
                )}
              </div>
            </Col>

            <Col lg={4}>
              <div id="orderList">
                {viewUsers?.abouts?.subject.length > 0 && (
                  <Card>
                    <CardHeader>
                      <div className="d-flex align-items-center">
                        <h5 className="card-title mb-0 flex-grow-1">
                          Interest
                        </h5>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="interest-list">
                        {viewUsers.abouts.subject &&
                          viewUsers.abouts.subject.length > 0 &&
                          viewUsers.abouts.subject.map((item, index) => {
                            return (
                              <>
                                <div className="interest-item" key={item._id}>
                                  <div className="interest-title">
                                    {item?.name}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </CardBody>
                  </Card>
                )}
              </div>
            </Col>

            <Col lg={4}>
              <Card>
                <PreviewCardHeader title="Image" />
                <CardBody>
                  <div className="image-container">
                    <img src={viewUsers.image || ""} alt="" id="image1" />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewUsers;
