import React, { useEffect, useState, Fragment } from "react";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getTags,
  deleteTag,
  statusChangeTag,
  modifyTagFlag,
  saveOrderingTag,
  resetTagFlag,
  saveOrderingTagResetFlag,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Loader from "../../../Components/Common/Loader";

function TagList() {
  const dispatch = useDispatch();
  const api = new APIClient();
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [sortKey, setsortKey] = useState("ordering");
  const [sortValue, setSortByValue] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState("");
  const [isChecked, setisChecked] = useState([]);
  const [metatitle, setMetaTitle] = useState("CEO");
  const searchParams = new URLSearchParams(window.location.search).get(
    "tag_type"
  );
  const [tag_type, settag_type] = useState(searchParams);

  useEffect(() => {
    dispatch(
      getTags({
        keyword,
        limit,
        offset,
        sortByField: sortKey,
        sortByValue: sortValue,
        tag_type,
        status,
      })
    );
  }, [dispatch, keyword, limit, offset, sortKey, sortValue, tag_type, status]);

  useEffect(() => {
    settag_type(searchParams);
  }, [searchParams]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getTags({ limit, dateRange, offset, keyword, tag_type }));
    }
  }, [dispatch, limit, offset, date, keyword, tag_type]);

  const {
    tags,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
    loading,
  } = useSelector((state) => ({
    tags: state.Tag.tags,
    isSuccess: state.Tag.isSuccess,
    success: state.Tag.success,
    totalDataCount: state.Tag.totalDataCount,
    error: state.Tag.error,
    statusSuccess: state.Tag.statusSuccess,
    statusFailed: state.Tag.statusFailed,
    saveOrderingSuccess: state.Tag.saveOrderingSuccess,
    saveOrderingFaild: state.Tag.saveOrderingFaild,
    loading: state.Tag.loading,
  }));

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyTagFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setsortKey(fieldName);
  };

  // Ordering
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);
  useEffect(() => {
    setDataList(tags);
  }, [tags]);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  //Ordering
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingTag({
        ordering_data: orderingData,
        table_name: "tag",
      })
    );
  }

  function onClickStatusChange(id, status) {
    let idChecked = id.split(" ");
    dispatch(
      statusChangeTag({
        id: idChecked,
        table_name: "tag",
        column_name: "status",
        column_value: status,
      })
    );
  }

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

  const statusChange = (e) => {
    if (isChecked.length > 0) {
      dispatch(
        statusChangeTag({
          table_name: "tag",
          id: isChecked,
          column_name: "status",
          column_value: e,
        })
      );
    } else {
      toast.warning("You do not have any selected files to Change the Status", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const deleteTagData = (id) => {
    let idDeleted = id.split(" ");
    dispatch(
      deleteTag({
        table_name: "tag",
        id: idDeleted,
      })
    );
  };

  const deleteCheck = () => {
    if (isChecked.length > 0) {
      dispatch(
        deleteTag({
          table_name: "tag",
          id: isChecked,
        })
      );
    } else {
      toast.warning("You do not have any selected files to delete", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  useEffect(() => {
    if (statusSuccess) {
      setTimeout(() => {
        dispatch(resetTagFlag());
      }, 2000);
    }
  }, [dispatch, statusSuccess]);

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingTagResetFlag());
      }, 2000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {
        id: "624c1d79ac364e5ef3796d76",
      });

      setMetaTitle(response?.data?.title);
    };
    request();
  }, []);

  const selects = (e) => {
    let checkval = e.target.checked;
    var ele = document.getElementsByName("chk_child");
    if (checkval === true) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = true;
      }
    } else {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = false;
      }
    }

    var array = [];
    var checkboxes = document.querySelectorAll("input[name=chk_child]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    setisChecked(array);
  };

  document.title =
    tag_type === "1"
      ? "Tag List | " + metatitle
      : tag_type === "2"
      ? "Feedtopic List | " + metatitle
      : "";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <ToastContainer theme="dark" autoClose={2000} />
          <BreadCrumb title="Tags" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    {tag_type === "1" && (
                      <h5 className="card-title mb-0 flex-grow-1">Tag List</h5>
                    )}
                    {tag_type === "2" && (
                      <h5 className="card-title mb-0 flex-grow-1">Feed Tags</h5>
                    )}
                    <div className="d-flex gap-2">
                      <Link
                        to={`/tag/create?tag_type=${tag_type}`}
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Tag
                      </Link>
                      <Button
                        className="btn btn-success"
                        onClick={() => statusChange(true)}
                      >
                        Active
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => statusChange(false)}
                      >
                        Deactivate
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={2} className="col-lg-2">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-lg-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              const value =
                                e.target.value === "true"
                                  ? Boolean(e.target.value)
                                  : e.target.value === "false"
                                  ? false
                                  : "";
                              setStatus(value);
                            }}
                          >
                            <option value="">Select status</option>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading &&  (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(tags) && tags.length > 0 && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <>
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                        onClick={(e) => selects(e)}
                                      />
                                    </div>
                                  </th>
                                  <th className="cursor-pointer">Title</th>
                                  <th>Status</th>
                                  <th className="text-end">Action</th>
                                </tr>
                              </thead>
                              <Droppable droppableId="tbody">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list form-check-all"
                                  >
                                    {dataList?.length > 0 &&
                                      dataList?.map((item, i) => {
                                        return (
                                          <Draggable
                                            draggableId={item?._id}
                                            key={item?._id}
                                            index={i}
                                          >
                                            {(provided) => (
                                              <tr
                                                key={item?._id}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <th scope="row">
                                                  <div className="form-check">
                                                    <input
                                                      className="form-check-input"
                                                      type="checkbox"
                                                      name="chk_child"
                                                      value={item?._id}
                                                      checked={item?.isChecked}
                                                      onChange={(e) =>
                                                        handleChange(e)
                                                      }
                                                    />
                                                  </div>
                                                </th>
                                                <td>{item?.title}</td>
                                                <td>
                                                  <button
                                                    className="btn-status"
                                                    title="Click to change status"
                                                    onClick={() =>
                                                      onClickStatusChange(
                                                        item?._id,
                                                        item?.status
                                                          ? false
                                                          : true
                                                      )
                                                    }
                                                  >
                                                    {item?.status ? (
                                                      <span className="badge badge-soft-success text-uppercase">
                                                        Active
                                                      </span>
                                                    ) : (
                                                      <span className="badge badge-soft-danger text-uppercase">
                                                        Inactive
                                                      </span>
                                                    )}
                                                  </button>
                                                </td>

                                                <td>
                                                  <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                                    <li
                                                      className="list-inline-item edit"
                                                      title="Edit"
                                                    >
                                                      <Link
                                                        className="text-primary d-inline-block edit-item-btn"
                                                        to={
                                                          "/tag/edit/" +
                                                          item?._id
                                                        }
                                                      >
                                                        <i className="ri-pencil-fill fs-16"></i>
                                                      </Link>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>
                            {noOfPage > 1 && (
                              <Pagination className="px-3">
                                {Array(noOfPage)
                                  .fill()
                                  .map((_, i) => {
                                    return (
                                      <Fragment key={i}>
                                        {offset > 0 && i === 0 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset - limit);
                                                setCurrentPage(currentPage - 1);
                                              }}
                                            >
                                              ← &nbsp; Prev
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage === i + 1 && (
                                          <PaginationItem active>
                                            <PaginationLink>
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== i + 1 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(limit * i);
                                                setCurrentPage(i + 1);
                                              }}
                                            >
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== noOfPage &&
                                          i + 1 === noOfPage && (
                                            <PaginationItem>
                                              <PaginationLink
                                                to="#"
                                                onClick={() => {
                                                  setOffset(offset + limit);
                                                  setCurrentPage(
                                                    currentPage + 1
                                                  );
                                                }}
                                              >
                                                Next &nbsp; →
                                              </PaginationLink>
                                            </PaginationItem>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                              </Pagination>
                            )}
                          </>
                        </DragDropContext>
                      )}
                    </div>
                  )}
                  {loading && <Loader />}
                  { isSuccess && !loading &&
                  Array.isArray(tags) && tags.length === 0 && (
                    <h5 className="mt-3 text-center" >No Result Found</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}

        {saveOrderingSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {saveOrderingFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default TagList;
