// FEATURES

export const GET_FEATURES = "GET_FEATURES";
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS";
export const GET_FEATURES_FAIL = "GET_FEATURES_FAIL";

export const ADD_NEW_FEATURES = "ADD_NEW_FEATURES";
export const ADD_NEW_FEATURES_SUCCESS = "ADD_NEW_FEATURES_SUCCESS";
export const ADD_NEW_FEATURES_FAIL = "ADD_NEW_FEATURES_FAIL";

export const UPDATE_FEATURES = "UPDATE_FEATURES";
export const UPDATE_FEATURES_SUCCESS = "UPDATE_FEATURES_SUCCESS";
export const UPDATE_FEATURES_FAIL = "UPDATE_FEATURES_FAIL";

export const GET_FEATURES_DETAILS = "GET_FEATURES_DETAILS";
export const GET_FEATURES_DETAILS_SUCCESS = "GET_FEATURES_DETAILS_SUCCESS";
export const GET_FEATURES_DETAILS_FAIL = "GET_FEATURES_DETAILS_FAIL";

export const DELETE_FEATURES = "DELETE_FEATURES";
export const DELETE_FEATURES_SUCCESS = "DELETE_FEATURES_SUCCESS";
export const DELETE_FEATURES_FAIL = "DELETE_FEATURES_FAIL";
export const MODIFY_FEATURES_FLAG = "MODIFY_FEATURES_FLAG";

export const RESET_FEATURES_FLAG = "RESET_FEATURES_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_FEATURES = "STATUS_CHANGE_FEATURES";
export const STATUS_CHANGE_FEATURES_SUCCESS = "STATUS_CHANGE_FEATURES_SUCCESS";
export const STATUS_CHANGE_FEATURES_FAIL = "STATUS_CHANGE_FEATURES_FAIL";


export const SAVE_ORDERING_FEATURES = "SAVE_ORDERING_FEATURES";
export const SAVE_ORDERING_FEATURES_SUCCESS = "SAVE_ORDERING_FEATURES_SUCCESS";
export const SAVE_ORDERING_FEATURES_FAIL = "SAVE_ORDERING_FEATURES_FAIL";
export const SAVE_ORDERING_FEATURES_RESET_FLAG = "SAVE_ORDERING_FEATURES_RESET_FLAG";
