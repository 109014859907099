import { Link, useLocation } from "react-router-dom";

const PlaylistTabs = ({ id, type, playlist_id }) => {
  const location = useLocation();
  return (
    <>
      <ul className="tab-navigation-menu">
        <li className="item" title="Edit">
          <Link to={"/playlist/edit/" + id} className={location.pathname.split("/")[2]==="edit" ?"item-link active" : "item-link"}>
            <i className="ri-pencil-line me-2"></i> Edit
          </Link>
        </li>

        <li className="item" title="Edit">
          <Link
            to={"/playlist/view/" + playlist_id + "?type=" + type}
            className={location.pathname.split("/")[2]==="view" ?"item-link active" : "item-link"}
          >
            <i className="ri-eye-line me-2"></i> View
          </Link>
        </li>
        {/* {(type === "spotify") ? (
        <li className="item" title="View">
          <a href={spotifyUrl + "/playlist/" +  id} className={"item-link"} target={"_blank"}>
            <i className="ri-eye-line me-2"></i> View
          </a>
        </li>
        ):(
          <li className="item" title="View">
          <a href={frontUrl + "/playlist/" +  id} className={"item-link"} target={"_blank"}>
            <i className="ri-eye-line me-2"></i> View
          </a>
          </li>
        )} */}
      </ul>
    </>
  );
};

export default PlaylistTabs;
