import React, { useEffect, useState, Fragment } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIClient } from "../../helpers/api_helper";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getMembers,
  statusChangeMember,
  deleteMember,
  resetMemberFlag,
  saveOrderingMember,
  saveOrderingMemberResetFlag,
} from "../../store/actions";
import MsgToast from "../../Components/Common/MsgToast";
import "moment-timezone";
import Moment from "react-moment";
import Select from "react-select";
import CommonModal from "../CommonModal";
import Loader from "../../Components/Common/Loader";
import BreadCrumb from "../../Components/Common/BreadCrumb";
function MemberList() {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [sortKey, setsortKey] = useState("ordering");
  const [sortValue, setSortByValue] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState("");
  const [isChecked, setisChecked] = useState([]);
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("");
  const [mulValue, setMulVal] = useState([]);
  const searchParams = new URLSearchParams(window.location.search).get("type");
  useEffect(() => {
    dispatch(
      getMembers({
        keyword,
        limit: parseInt(limit),
        offset,
        type,
        sortByField: sortKey,
        sortByValue: sortValue,
        status,
      })
    );
  }, [dispatch, keyword, limit, offset, sortKey, sortValue, status, type]);

  const {
    members,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
  } = useSelector((state) => ({
    members: state.Members.members,
    isSuccess: state.Members.isSuccess,
    success: state.Members.success,
    totalDataCount: state.Members.totalDataCount,
    error: state.Members.error,
    statusSuccess: state.Members.statusSuccess,
    statusFailed: state.Members.statusFailed,
    saveOrderingSuccess: state.Members.saveOrderingSuccess,
    saveOrderingFaild: state.Members.saveOrderingFaild,
  }));

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details", {});
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  // Ordering
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);
  useEffect(() => {
    setDataList(members);
  }, [members]);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  //Ordering
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingMember({
        ordering_data: orderingData,
        table_name: "becomemembers",
      })
    );
  }

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  function onClickStatusChange(id, status) {
    let idChecked = id.split(" ");
    dispatch(
      statusChangeMember({
        id: idChecked,
        table_name: "becomemembers",
        column_name: "status",
        column_value: status,
      })
    );
  }

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingMemberResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setsortKey(fieldName);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setisChecked([...isChecked, value]);
    } else {
      setisChecked(isChecked.filter((e) => e !== value));
    }
  };

  const statusChange = (e) => {
    if (isChecked.length > 0) {
      dispatch(
        statusChangeMember({
          table_name: "becomemembers",
          id: isChecked,
          column_name: "status",
          column_value: e,
        })
      );
    } else {
      toast.warning("You do not have any selected files to Change the Status", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const deleteMemberData = (id) => {
    let idDeleted = id.split(" ");
    dispatch(
      deleteMember({
        table_name: "becomemembers",
        id: idDeleted,
      })
    );
  };

  const deleteCheck = () => {
    if (isChecked.length > 0) {
      setModal(true);
    } else {
      toast.warning("You do not have any selected files to delete", {
        position: "top-right",
        autoClose: 200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    }
  };

  const onClickDeleteData = () => {
    dispatch(
      deleteMember({
        table_name: "becomemembers",
        id: isChecked,
      })
    );
    setModal(false);
  };

  useEffect(() => {
    if (statusSuccess) {
      setTimeout(() => {
        dispatch(resetMemberFlag());
      }, 2000);
    }
  }, [dispatch, statusSuccess]);

  const selects = (e) => {
    let checkval = e.target.checked;
    let ele = document.getElementsByName("chk_child");
    if (checkval === true) {
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = true;
      }
    } else {
      for (let i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = false;
      }
    }

    let array = [];
    let checkboxes = document.querySelectorAll("input[name=chk_child]:checked");

    for (let i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    setisChecked(array);
  };

  const options = [
    { value: "home", label: "Home" },
    { value: "membership", label: "Membership" },
    { value: "receive", label: "Receive" },
  ];

  const onChange = (e) => {
    const val = e.map((item) => item.value).join(",");
    setType(val);
    setMulVal(e);
  };

  useEffect(() => {
    if (searchParams) {
      setType(searchParams);
      setMulVal([{ value: searchParams, label: searchParams }]);
    }
  }, [searchParams]);

  document.title = "Become Members | " + metatitle;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <ToastContainer /> */}
          <BreadCrumb title="Members" />
          <Row>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Member List</h5>
                    <div className="d-flex gap-2">
                      <Link
                        to="/member/create"
                        className="btn btn-primary add-btn"
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        Member
                      </Link>

                      <Button
                        className="btn btn-success"
                        onClick={() => statusChange(true)}
                      >
                        Active
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => statusChange(false)}
                      >
                        Deactivate
                      </Button>
                      <Button className="btn btn-delete" onClick={deleteCheck}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-lg-1 me-auto">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>

                      <Col sm={4} className="col-lg-3">
                        <Select
                          options={options}
                          isMulti
                          onChange={onChange}
                          value={mulValue}
                          classNamePrefix="select2-selection form-select"
                        />
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess ? (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {members?.length ? (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <>
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th scope="col" style={{ width: "50px" }}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="checkAll"
                                        value="option"
                                        onClick={(e) => selects(e)}
                                      />
                                    </div>
                                  </th>
                                  <th
                                    className="cursor-pointer"
                                    // data-sort="image"
                                    // onClick={() => sortBy("image")}
                                  >
                                    Image
                                  </th>

                                  <th
                                    className="sort cursor-pointer"
                                    data-sort="created_at"
                                    onClick={() => sortBy("created_at")}
                                  >
                                    Created Date
                                  </th>
                                  <th>status</th>

                                  <th className="text-end">Action</th>
                                </tr>
                              </thead>
                              <Droppable droppableId="tbody">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list form-check-all"
                                  >
                                    {dataList?.map((item, i) => (
                                      <Draggable
                                        draggableId={item._id}
                                        key={item._id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <tr
                                            key={item._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <th scope="row">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  name="chk_child"
                                                  value={item._id}
                                                  checked={item.isChecked}
                                                  onChange={(e) =>
                                                    handleChange(e)
                                                  }
                                                />
                                              </div>
                                            </th>

                                            <td>
                                              {" "}
                                              <img
                                                className="image avatar-xs rounded-circle"
                                                src={item.image}
                                                alt=""
                                                width="50px"
                                              ></img>
                                            </td>
                                            <td>
                                              <Moment format="D MMM YYYY">
                                                {item.created_at}
                                              </Moment>
                                            </td>

                                            <td>
                                              <button
                                                className="btn-status"
                                                title="Click to change status"
                                                onClick={() =>
                                                  onClickStatusChange(
                                                    item._id,
                                                    item.status ? false : true
                                                  )
                                                }
                                              >
                                                {item?.status ? (
                                                  <span className="badge badge-soft-success text-uppercase">
                                                    Active
                                                  </span>
                                                ) : (
                                                  <span className="badge badge-soft-danger text-uppercase">
                                                    Inactive
                                                  </span>
                                                )}
                                              </button>
                                            </td>

                                            <td>
                                              <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                                <li
                                                  className="list-inline-item edit"
                                                  title="Edit"
                                                >
                                                  <Link
                                                    className="text-primary d-inline-block edit-item-btn"
                                                    to={
                                                      "/member/edit/" + item._id
                                                    }
                                                  >
                                                    <i className="ri-pencil-line fs-16"></i>
                                                  </Link>
                                                </li>
                                                <li
                                                  className="list-inline-item"
                                                  title="Remove"
                                                  id={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <Button className="p-0 bg-transparent border-0 text-danger">
                                                    <i className="ri-delete-bin-5-line fs-16"></i>
                                                  </Button>
                                                </li>
                                                <UncontrolledPopover
                                                  trigger="focus"
                                                  placement="left"
                                                  target={
                                                    "deletePopover" + item._id
                                                  }
                                                >
                                                  <PopoverHeader>
                                                    Delete Member List
                                                  </PopoverHeader>
                                                  <PopoverBody>
                                                    Are you sure want to delete?
                                                    <div className="d-flex align-items-center justify-content-end mt-2">
                                                      <Button className="btn btn-sm btn-light">
                                                        No
                                                      </Button>
                                                      <Button
                                                        onClick={() =>
                                                          deleteMemberData(
                                                            item._id
                                                          )
                                                        }
                                                        className="btn btn-sm btn-danger ms-2"
                                                      >
                                                        Yes
                                                      </Button>
                                                    </div>
                                                  </PopoverBody>
                                                </UncontrolledPopover>
                                              </ul>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>
                            {noOfPage > 1 && (
                              <Pagination className="px-3">
                                {Array(noOfPage)
                                  .fill()
                                  .map((_, i) => {
                                    return (
                                      <Fragment key={i}>
                                        {offset > 0 && i === 0 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset - limit);
                                                setCurrentPage(currentPage - 1);
                                              }}
                                            >
                                              ← &nbsp; Prev
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage === i + 1 && (
                                          <PaginationItem active>
                                            <PaginationLink>
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== i + 1 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(limit * i);
                                                setCurrentPage(i + 1);
                                              }}
                                            >
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== noOfPage &&
                                          i + 1 === noOfPage && (
                                            <PaginationItem>
                                              <PaginationLink
                                                to="#"
                                                onClick={() => {
                                                  setOffset(offset + limit);
                                                  setCurrentPage(
                                                    currentPage + 1
                                                  );
                                                }}
                                              >
                                                Next &nbsp; →
                                              </PaginationLink>
                                            </PaginationItem>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                              </Pagination>
                            )}
                          </>
                        </DragDropContext>
                      ) : (
                        <div className="noresult text-center py-5">
                          <div className="loader">
                            <svg
                              width="188"
                              height="104"
                              viewBox="0 -2 24 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.35577 25.5022C1.11853 25.5022 0 24.3305 0 21.9872V3.51512C0 1.17212 1.11853 -8.39233e-05 3.35577 -8.39233e-05H3.73916C5.9549 -8.39233e-05 7.06258 1.17212 7.06258 3.51512V9.10814H4.44231V3.8667C4.44231 3.37702 4.35131 3.00376 4.17054 2.74817C3.98907 2.49257 3.78146 2.36478 3.54756 2.36478C3.24877 2.36478 3.02517 2.50857 2.8764 2.79616C2.72692 3.08374 2.65262 3.41932 2.65262 3.80271V21.6356C2.65262 22.1256 2.73758 22.4985 2.90839 22.7539C3.07832 23.0097 3.29178 23.1375 3.54756 23.1375C3.82412 23.1375 4.04275 22.9937 4.20254 22.7061C4.36233 22.4184 4.44231 22.083 4.44231 21.6994V16.3942H7.06258V21.9872C7.06258 24.3305 5.9549 25.5022 3.73916 25.5022H3.35577Z"
                                fill="white"
                              />
                              <path
                                d="M12.0161 2.39659C11.7175 2.39659 11.4939 2.54038 11.3452 2.82797C11.1957 3.11555 11.1214 3.45113 11.1214 3.83452V11.7924L11.1854 11.7284C11.9306 10.9401 12.4047 10.3011 12.6073 9.81111C12.8094 9.32107 12.9109 8.67142 12.9109 7.86181V3.89851C12.9109 3.40883 12.8254 3.03575 12.6553 2.78016C12.4845 2.52438 12.2717 2.39659 12.0161 2.39659ZM11.8243 25.5022C9.58711 25.5022 8.46875 24.3305 8.46875 21.987V3.54711C8.46875 1.20394 9.58711 0.0319042 11.8243 0.0319042H12.2079C14.4235 0.0319042 15.5312 1.20394 15.5312 3.54711V7.41443C15.5312 8.20272 15.4993 8.84721 15.4354 9.34773C15.3716 9.84843 15.2544 10.3118 15.084 10.7379C14.9132 11.1852 14.6686 11.6326 14.349 12.08C14.0294 12.5274 13.582 13.0498 13.0067 13.6459C12.2182 14.4984 11.7069 15.1636 11.473 15.643C11.2382 16.1224 11.1214 16.7455 11.1214 17.5129V21.6994C11.1214 22.0828 11.1957 22.4184 11.3452 22.706C11.4939 22.9935 11.7175 23.1375 12.0161 23.1375C12.2717 23.1375 12.4845 23.0095 12.6553 22.7539C12.8254 22.4984 12.9109 22.1256 12.9109 21.6356V16.426H15.5312V21.987C15.5312 24.3305 14.4235 25.5022 12.2079 25.5022H11.8243Z"
                                fill="white"
                              />
                              <path
                                d="M20.4849 2.36478C20.2076 2.36478 19.9897 2.50857 19.8297 2.79616C19.6701 3.08375 19.5901 3.41932 19.5901 3.80271V21.6356C19.5901 22.1256 19.6749 22.4985 19.8457 22.7539C20.0158 23.0097 20.2293 23.1377 20.4849 23.1377C20.7616 23.1377 20.9803 22.9937 21.14 22.7061C21.2998 22.4185 21.3796 22.083 21.3796 21.6996V3.8667C21.3796 3.37702 21.2941 3.00394 21.124 2.74835C20.9532 2.49257 20.7405 2.36478 20.4849 2.36478ZM20.2933 25.5022C18.0559 25.5022 16.9375 24.3307 16.9375 21.9872V3.5153C16.9375 1.17213 18.0559 9.34601e-05 20.2933 9.34601e-05H20.6767C22.8922 9.34601e-05 23.9999 1.17213 23.9999 3.5153V21.9872C23.9999 24.3307 22.8922 25.5022 20.6767 25.5022H20.2933Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <h5 className="mt-3">No Result Found</h5>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Loader error={error} />
                  )}
                  <CommonModal
                    modal={modal}
                    setModal={setModal}
                    onClickDeleteData={onClickDeleteData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}

        {saveOrderingSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {saveOrderingFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default MemberList;
