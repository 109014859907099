import { useState, useEffect } from "react";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useHistory, useParams } from "react-router-dom";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import { APIClient } from "../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../Components/Common/MsgToast";
import { convertToBase64 } from "../../helpers/image";
import {
  getMemberDetails,
  resetMemberFlag,
  updateMember,
} from "../../store/actions";
import Select from "react-select";

const MemberEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Members | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [image, setImage] = useState();
  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [type, setType] = useState([]);
  const [mulValue, setMulVal] = useState([]);

  const { isMemberUpdate, isMemberUpdateFail, error, success, details } =
    useSelector((state) => ({
      isMemberUpdate: state.Members.isMemberUpdate,
      isMemberUpdateFail: state.Members.isMemberUpdateFail,
      error: state.Members.error,
      success: state.Members.success,
      details: state.Members.details,
    }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: details && details.image ? details.image : "",
      type: details && details.type ? details.type : "",
      description: details && details?.description ? details.description : "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setIsCreating(true);
      let data = {};
      if (type.length === 0) {
        toast("Please select atleast one type.");
        setIsCreating(false);
        return false;
      }
      data = {
        id: id,
        image: image,
        type: type,
        description: values.description,
      };

      dispatch(updateMember(data));
    },
  });

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    if (isMemberUpdate || isMemberUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetMemberFlag());
        history.push("/members");
      }, 3000);
    }
  }, [dispatch, isMemberUpdate, isMemberUpdateFail, validation]);

  useEffect(() => {
    dispatch(getMemberDetails({ id: id }));
  }, [dispatch, id]);

  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  const options = [
    { value: "home", label: "Home" },
    { value: "membership", label: "Membership" },
    { value: "receive", label: "Receive" },
  ];
  useEffect(() => {
    if (details) {
      let TypeArr = [];
      details?.type.map((item) => {
        TypeArr.push({ label: item, value: item });
      }, []);
      setMulVal(TypeArr);
    }
  }, [details]);

  const onChange = (e) => {
    let arr = [];
    e.map((item) => {
      arr.push(item.value);
    });
    setType(arr);
    setMulVal(e);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb
            title="Edit Members"
            pageTitle="Members"
            pageUrl="/members"
          />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={8}>
                  <Card>
                    <PreviewCardHeader title="Edit Member" />

                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col xxl={6} md={6}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Image *
                              </Label>
                              <Input
                                type="file"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Place your image"
                                name="image"
                                onChange={onImageChange}
                              />
                              <img
                                src={validation.values.image || ""}
                                alt=""
                                id="image1"
                                width="100px"
                              ></img>
                              {validation.touched.image &&
                              validation.errors.image ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.image}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Select
                            options={options}
                            isMulti
                            onChange={onChange}
                            value={mulValue}
                            classNamePrefix="select2-selection form-select"
                          />

                          <Col xxl={12} md={12}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Description (Optional)
                              </Label>
                              <Input
                                type="textarea"
                                id="placeholderInput"
                                rows="3"
                                className="form-control"
                                placeholder="Enter description"
                                name="description"
                                value={validation.values.description || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.description &&
                                  validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                              validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Link to="/members" className="btn btn-reset w-sm me-1">
                        Cancel
                      </Link>
                      <Button
                        color="primary"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Update{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Update</span>
                        </span>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Row>

          {isMemberUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isMemberUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default MemberEdit;
