/* CONTACTS */
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL";

export const ADD_NEW_CONTACT = "ADD_NEW_CONTACT";
export const ADD_NEW_CONTACT_SUCCESS = "ADD_NEW_CONTACT_SUCCESS";
export const ADD_NEW_CONTACT_FAIL = "ADD_NEW_CONTACT_FAIL";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";

export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const REMOVE_CONTACT_SUCCESS = "REMOVE_CONTACT_SUCCESS";
export const REMOVE_CONTACT_FAIL = "REMOVE_CONTACT_FAIL";

export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const GET_CONTACT_DETAILS_SUCCESS = "GET_CONTACT_DETAILS_SUCCESS";
export const GET_CONTACT_DETAILS_FAIL = "GET_CONTACT_DETAILS_FAIL";

// STATUS CHANGE
export const STATUS_CHANGE_CONTACT = "STATUS_CHANGE_CONTACT";
export const STATUS_CHANGE_CONTACT_SUCCESS = "STATUS_CHANGE_CONTACT_SUCCESS";
export const STATUS_CHANGE_CONTACT_FAIL = "STATUS_CHANGE_CONTACT_FAIL";

export const DELETE_CONTACT = "DELETE_CONTACT";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";

export const MODIFY_CONTACT_FLAG = "MODIFY_CONTACT_FLAG";

export const RESET_CONTACT_FLAG = "RESET_CONTACT_FLAG";
