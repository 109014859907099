/* ROLES */
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const ADD_NEW_ROLES = "ADD_NEW_ROLES";
export const ADD_NEW_ROLES_SUCCESS = "ADD_NEW_ROLES_SUCCESS";
export const ADD_NEW_ROLES_FAIL = "ADD_NEW_ROLES_FAIL";

export const GET_ROLES_DETAILS = "GET_ROLES_DETAILS";
export const GET_ROLES_DETAILS_SUCCESS = "GET_ROLES_DETAILS_SUCCESS";
export const GET_ROLES_DETAILS_FAIL = "GET_ROLES_DETAILS_FAIL";

export const MODIFY_ROLE_FLAG = "MODIFY_ROLE_FLAG";
export const RESET_ROLE_FLAG = "RESET_ROLE_FLAG";
