import {
    GET_STEPS,
    GET_STEPS_SUCCESS,
    GET_STEPS_FAIL,
    ADD_NEW_STEPS,
    ADD_NEW_STEPS_SUCCESS,
    ADD_NEW_STEPS_FAIL,
    UPDATE_STEPS,
    UPDATE_STEPS_SUCCESS,
    UPDATE_STEPS_FAIL,
    GET_STEPS_DETAILS,
    GET_STEPS_DETAILS_SUCCESS,
    GET_STEPS_DETAILS_FAIL,
    DELETE_STEPS,
    DELETE_STEPS_SUCCESS,
    DELETE_STEPS_FAIL,
    MODIFY_STEPS_FLAG,
    RESET_STEPS_FLAG,
    STATUS_CHANGE_STEPS,
    STATUS_CHANGE_STEPS_SUCCESS,
    STATUS_CHANGE_STEPS_FAIL,
    FEATURED_CHANGE_STEPS,
    FEATURED_CHANGE_STEPS_SUCCESS,
    FEATURED_CHANGE_STEPS_FAIL,
    SAVE_ORDERING_STEPS,
    SAVE_ORDERING_STEPS_SUCCESS,
    SAVE_ORDERING_STEPS_FAIL,
    SAVE_ORDERING_STEPS_RESET_FLAG
  } from "./actionTypes";
  
  export const getSteps = data => ({
    type: GET_STEPS,
    payload: data,
  });
  
  export const getStepsSuccess = Steps => ({
    type: GET_STEPS_SUCCESS,
    payload: Steps,
  });
  
  export const getStepsFail = error => ({
    type: GET_STEPS_FAIL,
    payload: error,
  });
  
  export const addNewSteps = Steps => ({
    type: ADD_NEW_STEPS,
    payload: Steps,
  });
  
  export const addNewStepsuccess = Steps => ({
    type: ADD_NEW_STEPS_SUCCESS,
    payload: Steps,
  });
  
  export const addNewStepsFail = error => ({
    type: ADD_NEW_STEPS_FAIL,
    payload: error,
  });
  
  export const updateSteps = Steps => ({
    type: UPDATE_STEPS,
    payload: Steps,
  });
  
  export const updateStepsuccess = Steps => ({
    type: UPDATE_STEPS_SUCCESS,
    payload: Steps,
  });
  
  export const updateStepsFail = error => ({
    type: UPDATE_STEPS_FAIL,
    payload: error,
  });
  
  export const getStepsDetails = Steps => ({
    type: GET_STEPS_DETAILS,
    payload: Steps,
  });
  
  export const getStepsDetailsSuccess = Steps => ({
    type: GET_STEPS_DETAILS_SUCCESS,
    payload: Steps,
  });
  
  export const getStepsDetailsFail = error => ({
    type: GET_STEPS_DETAILS_FAIL,
    payload: error,
  });
  
  export const delSteps = data => ({
    type: DELETE_STEPS,
    payload: data,
  });
  
  export const deleteStepsuccess = data => ({
    type: DELETE_STEPS_SUCCESS,
    payload: data,
  });
  
  export const deleteStepsFail = data => ({
    type: DELETE_STEPS_FAIL,
    payload: data,
  });
  
  export const statusChangeSteps = Steps => ({
    type: STATUS_CHANGE_STEPS,
    payload: Steps,
  });
  
  export const statusChangeStepsuccess = Steps => ({
    type: STATUS_CHANGE_STEPS_SUCCESS,
    payload: Steps,
  });
  
  export const statusChangeStepsFail = error => ({
    type: STATUS_CHANGE_STEPS_FAIL,
    payload: error,
  });
  
  // Featured Change
  export const featuredChangeSteps = Steps => ({
    type: FEATURED_CHANGE_STEPS,
    payload: Steps,
  });
  
  export const featuredChangeStepsuccess = Steps => ({
    type: FEATURED_CHANGE_STEPS_SUCCESS,
    payload: Steps,
  });
  
  export const featuredChangeStepsFail = error => ({
    type: FEATURED_CHANGE_STEPS_FAIL,
    payload: error,
  });
  
  export const modifyStepsFlag = () => {
    return {
      type: MODIFY_STEPS_FLAG,
    }
  }
  
  export const resetStepsFlag = () => {
    return {
      type: RESET_STEPS_FLAG,
    }
  }
  
  
  //Save Ordaring
  export const saveOrderingSteps = data => ({
    type: SAVE_ORDERING_STEPS,
    payload: data,
  });
  
  export const saveOrderingStepsuccess = data => ({
    type: SAVE_ORDERING_STEPS_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingStepsFail = error => ({
    type: SAVE_ORDERING_STEPS_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingStepsResetFlag = () => {
    return {
      type: SAVE_ORDERING_STEPS_RESET_FLAG,
    }
  }
  
  
  
  