import { Link } from "react-router-dom";

const PageNav = ({ page_id, id }) => {
  return (
    <>
      <ul className="tab-navigation-menu">
        <>
          {/* <li className="item">
            <Link to={`/page/edit/home`}>Main Page</Link>
          </li> */}
          <li className="item">
            <Link to={`/page/edit/home-banner`}>WELCOME TO CEO.COM</Link>
          </li>

          {/* <li className="item">
            <Link to={`/page/edit/premium-access`}>PREMIUM ACCESS</Link>
          </li> */}
          <li className="item">
            <Link to={`/page/edit/exclusive-community`}>
              Exclusive Community Title
            </Link>
          </li>
          <li className="item">
            <Link to={`/exclusive-communities`}>Exclusive Community</Link>
          </li>
          <li className="item">
            <Link to={`/members?type=home`}>Become a Member</Link>
          </li>
          <li className="item">
            <Link to={`/members?type=receive`}>ONLY MEMBERS RECIEVE</Link>
          </li>
          <li className="item">
            <Link to={`/page/edit/home-video`}>WATCH REAL CONTENT.</Link>
          </li>
          {/* <li className="item">
            <Link to={`/seo/page/${id}`}>SEO</Link>
          </li> */}
        </>
      </ul>
    </>
  );
};

export default PageNav;
