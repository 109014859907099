import './assets/scss/themes.scss';
import './assets/css/taginput.css';
import './assets/css/style.css'
import Route from './Routes';
import fakeBackend from "./helpers/AuthType/fakeBackend";

fakeBackend();

function App() {
  return (
    <>
      <Route />
    </>
  );
}

export default App;
