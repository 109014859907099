import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();

  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isStories, setIsStories] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPodcast, setIsPodcast] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isTag, setIsTags] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
  }, [history, iscurrentState, isDashboard]);

  const menuItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },

    {
      label: "User Management",
      isHeader: true,
    },
    {
      id: "admins",
      label: "Admins",
      icon: "ri-shield-user-line",
      link: "/admins",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Admins");
      },
    },
    {
      id: "users",
      label: "Users",
      icon: "ri-user-3-line",
      link: "/users",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Users");
      },
    },

    {
      label: "Social management",
      isHeader: true,
    },
    {
      id: "posts",
      label: "Posts/Feeds",
      icon: "ri-pushpin-line",
      link: "/posts?communityId=634f94c835c20130243b63aa&type=community",
      stateVariables: isPost,
      click: function (e) {
        e.preventDefault();
        setIsPost(!isPost);
        setIscurrentState("Posts");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "posts",
          label: "List",
          link: "/posts?communityId=634f94c835c20130243b63aa&type=community",
          parentId: "posts",
        },
        {
          id: "posttaglist",
          label: "Tags",
          link: "/tags?tag_type=2",
          parentId: "posttags",
        },
      ],
    },
    {
      label: "Video Management",
      isHeader: true,
    },
    {
      id: "videos",
      label: "Videos",
      icon: "ri-vidicon-line",
      link: "/#",
      stateVariables: isVideo,
      click: function (e) {
        e.preventDefault();
        setIsVideo(!isVideo);
        setIscurrentState("Videos");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "youtube",
          label: "Youtube",
          link: "/playlist?type=youtube",
          parentId: "Videos",
        },
        {
          id: "vimeo",
          label: "Vimeo",
          link: "/playlist?type=vimeo",
          parentId: "Videos",
        },
      ],
    },

    {
      label: "Podcast Management",
      isHeader: true,
    },
    {
      id: "podcasts",
      label: "Podcasts",
      icon: "ri-disc-line",
      link: "/#",
      stateVariables: isPodcast,
      click: function (e) {
        e.preventDefault();
        setIsPodcast(!isPodcast);
        setIscurrentState("Podcast");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "spotify",
          label: "Spotify",
          link: "/playlist?type=spotify",
          parentId: "spotify",
        },
      ],
    },

    {
      label: "Stories management",
      isHeader: true,
    },
    {
      id: "stories",
      label: "Stories",
      icon: "ri-article-line",
      link: "/#",
      stateVariables: isStories,
      click: function (e) {
        e.preventDefault();
        setIsStories(!isStories);
        setIscurrentState("");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "storieslist",
          label: "List",
          link: "/stories",
          parentId: "stories",
        },
      ],
    },

    {
      label: "Schedule management",
      isHeader: true,
    },

    {
      id: "events",
      label: "Event/Schedule",
      icon: "ri-calendar-event-line",
      link: "/events",
      stateVariables: isTag,
      click: function (e) {
        e.preventDefault();
        setIsTags(!isTag);
        setIscurrentState("Events");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "events",
          label: "List",
          link: "/events",
          parentId: "events",
        },
        {
          id: "taglist",
          label: "Tags",
          link: "/tags?tag_type=1",
          parentId: "tags",
        },
      ],
    },

    {
      label: "Content management",
      isHeader: true,
    },
    {
      id: "pages",
      label: "Pages",
      icon: "ri-pages-line",
      link: "/pages",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Pages");
      },
    },
    {
      id: "settings",
      label: "Site Settings",
      icon: "ri-settings-4-line",
      link: "/site/edit/624c1d79ac364e5ef3796d76",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Settings");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
