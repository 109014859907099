import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import {
  GET_CONTACTS,
  ADD_NEW_CONTACT,
  DELETE_CONTACT,
  STATUS_CHANGE_CONTACT,
} from "./actionTypes";

import {
  getContactsSuccess,
  getContactsFail,
  addNewContactSuccess,
  addNewContactFail,
  removeContactSuccess,
  removeContactFail,
  statusChangeContactSuccess,
  statusChangeContactFail,
  deleteContactSuccess,
  deleteContactFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getContactList,
  addNewContact,
  removeContact,
  statusChangeContact,
  delContact
} from "../../helpers/fakebackend_helper";


function* fetchContacts({ payload: contact }) {
  try {
    const response = yield call(getContactList, contact);
    yield put(getContactsSuccess(response));
  } catch (error) {
    yield put(getContactsFail(error));
  }
}

export function* watchFetchContacts() {
  yield takeEvery(GET_CONTACTS, fetchContacts);
}

function* onAddNewContact({ payload: contact }) {
  try {
    const response = yield call(addNewContact, contact);
    if (response.status === 200) {
      yield put(addNewContactSuccess(response));
    } else {
      yield put(addNewContactFail(response));
    }
  } catch (error) {
    yield put(addNewContactFail(error));
  }
}

export function* watchAddNewContact() {
  yield takeEvery(ADD_NEW_CONTACT, onAddNewContact);
}

// function* onContactDetails({ payload: contact }) {
//   try {
//     const response = yield call(getContactDetails, contact);
//     if (response.status === 200) {
//       yield put(getContactDetailsSuccess(response));
//     } else {
//       yield put(getContactDetailsFail(response));
//     }
//   } catch (error) {
//     yield put(getContactDetailsFail(error));
//   }
// }

// export function* watchContactDetails() {
//   yield takeEvery(GET_CONTACT_DETAILS, onContactDetails);
// }

// function* onContactUpdate({ payload: contact }) {
//   try {
//     const response = yield call(updateContact, contact);
//     if (response.status === 200) {
//       yield put(updateContactSuccess(response));
//     } else {
//       yield put(updateContactFail(response));
//     }
//   } catch (error) {
//     yield put(updateContactFail(error));
//   }
// }

// export function* watchUpdateContact() {
//   yield takeEvery(UPDATE_CONTACT, onContactUpdate);
// }



//Status Change
function* onStatusChangeContact({ payload: contact }) {
  try {
    const response = yield call(statusChangeContact, contact);
    yield put(statusChangeContactSuccess({ contact, ...response }));
  } catch (error) {
    yield put(statusChangeContactFail(error));
  }
}

export function* watchStatusChangeContact() {
  yield takeEvery(STATUS_CHANGE_CONTACT, onStatusChangeContact);
}

function* onDeleteContact({payload : data}) {
  try {
    const response = yield call(delContact,data)
    yield put(deleteContactSuccess(response))
  } catch (error) {
    yield put(deleteContactFail(error))
  }
}

export function* watchDeleteContact() {
  yield takeEvery(DELETE_CONTACT, onDeleteContact);
}


function* contactSaga() {
  yield all([
    fork(watchFetchContacts),
    fork(watchAddNewContact),
    // fork(watchContactDetails),
    // fork(watchUpdateContact),

    fork(watchStatusChangeContact),
    fork(watchDeleteContact),
  ]);
}

export default contactSaga;
