import React, { useEffect, useState } from "react";
import { getUsers } from "../../store/actions";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import "moment-timezone";
import { useParams, Link } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import UserNav from "../../Components/UserNavigation/UserNav";
import axios from "axios";
import Moment from "react-moment";
import frontUrl, { BASE_URL } from "../../config/frontUrl";
import Loader from "../../Components/Common/Loader";

function SavedContentList() {
  const { usrId } = useParams();
  const [posts, setPosts] = useState();

  const [sortBy, setSortBy] = useState("lastCreated");
  const searchParams = new URLSearchParams(window.location.search).get("type");

  const [limit, setLimit] = useState(10);
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  const [loader, setLoader] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [favList, setFavorites] = useState([]);
  const [postIds, setPostids] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers({ type: "user" }));
  }, []);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    const getsavecontentList = async () => {
      setLoader(true);
      const response = await api.create("post/savecontent-list", {
        limit: limit,
        offset: offset,
        user_id: usrId,
      });
      setHasNext(response.hasNext);
      setFavorites(response.data === [] ? [] : response.data);
      response.data.length > 0 &&
        response.data.map((item) => {
          postIds.push(item.post_id);
        });
      setPostids(postIds);

      const saveContent = await axios.post(
        BASE_URL + "amity/fevorite-feed",

        {
          postIds: postIds,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("accesstoken"),
          },
        }
      );


      if (saveContent.status === 200) {
        setPosts(saveContent?.data.posts);
      }
      setLoader(false);
    };
    getsavecontentList();
  }, [limit, offset]);

  document.title = "Saved Content List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <BreadCrumb title="Posts" /> */}
          <Row>
            {usrId && <UserNav user_id={usrId} />}

            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">
                      Saved Content List
                    </h5>
                  </div>
                </CardHeader>

                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="100">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                    </Row>
                  </form>
                </CardBody>

                <CardBody className="pt-0">
                  {!loader && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(posts) && posts.length > 0 && (
                        <table
                          className="table align-middle table-nowrap"
                          id="customerTable"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Content</th>

                              <th>Type</th>

                              <th>Sub Post</th>
                              <th>Comments</th>
                              <th>Reactions</th>
                              <th className="sort" data-sort="created_at">
                                Created at
                              </th>
                              <th className="text-end">Action</th>
                            </tr>
                          </thead>

                          <tbody className="list form-check-all">
                            {posts.map((item) => (
                              <tr key={item._id}>
                                <td
                                  id={"textcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item.data?.text.length > 40
                                    ? item.data?.text.substring(0, 30) + "..."
                                    : item.data?.text}
                                </td>

                                <td
                                  id={"typecount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <span className="badge badge-soft-success text-uppercase">
                                    {item?.dataType}
                                  </span>
                                </td>

                                <td
                                  id={"subpostcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <Link
                                    to={"/post/" + item._id}
                                    style={{ color: "var(--color05)" }}
                                  >
                                    {item.children?.length}
                                  </Link>
                                </td>
                                <td
                                  id={"commentcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item?.commentsCount === 0 ? (
                                    "0"
                                  ) : (
                                    <Link
                                      to={"/comments?referenceId=" + item._id}
                                      style={{ color: "var(--color05)" }}
                                    >
                                      {item.commentsCount}
                                    </Link>
                                  )}
                                </td>
                                <td
                                  id={"reactioncount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  {item.reactionsCount}
                                </td>
                                <td
                                  id={"createcount" + item.postId}
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <Moment format="D MMM YYYY">
                                    {item.createdAt}
                                  </Moment>
                                </td>
                                <td
                                  className={
                                    item.isDeleted === true
                                      ? "opacity-25 text-decoration-line-through"
                                      : ""
                                  }
                                >
                                  <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                    <li
                                      className="list-inline-item"
                                      title="View Sites"
                                    >
                                      <a
                                        className="text-primary d-inline-block edit-item-btn"
                                        href={
                                          frontUrl + "/post/" + item?.postId
                                        }
                                        target="_blank"
                                      >
                                        <i className="ri-links-line fs-18"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {loader && <Loader />}
                  {!loader && Array.isArray(posts) && posts.length === 0 && (
                    <h5 className="mt-3 text-center">No Result Found</h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default SavedContentList;
