import {
    GET_CHANNELS,
    GET_CHANNELS_SUCCESS,
    GET_CHANNELS_FAIL,
  } from "./actionType";

  export const getChannels = results => ({
    type: GET_CHANNELS,
    payload: results,
  });
  
  
  
  export const getChannelsSuccess = channels => ({
    type: GET_CHANNELS_SUCCESS,
    payload: channels,
  });
  
  export const getChannelsFail = error => ({
    type: GET_CHANNELS_FAIL,
    payload: error,
  });

  
