import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  updateUser,
  getRoles,
  getUserDetails,
  resetUserFlag,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";
import useAxios from "../../../helpers/useAxios";
import { userUpdate } from "../../../helpers/axios_helper";
import UserNav from "../../../Components/UserNavigation/UserNav";
const UserEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit User | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [image, setImage] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const [roleValue, setRoleValue] = useState([]);

  const { isUserUpdate, isUserUpdateFail, error, success, details } =
    useSelector((state) => ({
      isUserUpdate: state.Users.isUserUpdate,
      isUserUpdateFail: state.Users.isUserUpdateFail,
      error: state.Users.error,
      success: state.Users.success,
      details: state.Users.details,
    }));
  const [RoleVal, setRoleval] = useState({
    label: details?.role?.code !== undefined ? details?.role?.code : "",
    value: details?.role?.code !== undefined ? details?.role?.code : "",
  });

  const { response: rolesAmity } = useAxios({
    method: "get",
    url: "/v3/roles?sortBy=lastCreated&options%5Blimit%5D=10",
  });

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  useEffect(() => {
    let tmp = [];
    if (rolesAmity) {
      rolesAmity?.roles?.forEach((element) => {
        tmp.push({ label: element.displayName, value: element.roleId });
      });
    }
    setRoleValue(tmp);
  }, [dispatch, rolesAmity]);

  const optionsRole = {
    value: details && details.role && details.role._id,
    label: details && details.role && details.role.name,
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details.name ? details.name : "",
      email: details && details.email ? details.email : "",
      // image: details && details.image ? details.image : "",
      phone: details && details.phone ? details.phone : "",
      headline: details && details.headline ? details.headline : "",
      company_name: details && details.company_name ? details.company_name : "",
    },

    onSubmit: (values) => {
      setIsCreating(true);
      let data = {};
      if (values.password) {
        data = {
          id: id,
          name: values.name,
          email: values.email,
          password: values.password,
          // image: values.image,
          company_name: values.company_name,
          phone: values.phone,
          headline: values.headline,
          
        };
      } else {
        data = {
          id: id,
          name: values.name,
          email: values.email,
          // image: values.image,
          company_name: values.company_name,
          phone: values.phone,
          headline: values.headline,
          
        };
      }

      dispatch(updateUser(data));
      userUpdate({
        roles: RoleVal?.value ? [RoleVal.value] : [],
        userId: id,
      });
    },
  });

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");

      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  useEffect(() => {
    if (isUserUpdate || isUserUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetUserFlag());
        window.location.href = "/users";
      }, 3000);
    }
  }, [dispatch, isUserUpdate, isUserUpdateFail, validation]);

  useEffect(() => {
    dispatch(getUserDetails({ id: id, type: "admin" }));
  }, [dispatch, id]);
  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src); // free memory
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit User" pageTitle="Users" pageUrl="/users" />
          <Row>
            <Col lg={12}>{id && <UserNav user_id={id} />}</Col>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={8}>
                  <Card>
                    <PreviewCardHeader title={`Edit User - ${validation.values.name}`} />

                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col xxl={12}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Name *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                value={validation.values.name || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Headline*
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Headline"
                                name="headline"
                                value={validation.values.headline || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.headline &&
                                  validation.errors.headline
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.headline &&
                              validation.errors.headline ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.headline}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Company*
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Company"
                                name="company_name"
                                value={validation.values.company_name || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.company_name &&
                                  validation.errors.company_name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.company_name &&
                              validation.errors.company_name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.company_name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Email *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={validation.values.email || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Phone No.*
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Phone"
                                name="phone"
                                value={validation.values.phone || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.phone &&
                                  validation.errors.phone
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phone &&
                              validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phone}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Password *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Password"
                                name="password"
                                value={validation.values.password || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Link to="/users" className="btn btn-reset w-sm me-1">
                        Cancel
                      </Link>
                      <Button
                        color="primary"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Update{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Update</span>
                        </span>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                {/* <Col lg={4}>
                  <Card>
                    <PreviewCardHeader title="Image *" />
                    <CardBody>
                      <div className="mb-4">
                        <Input
                          type="file"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Place your image"
                          name="image"
                          onChange={onImageChange}
                        />
                      </div>
                      <div className="image-container">
                        <img
                          src={validation.values.image || ""}
                          alt=""
                          id="image1"
                        />
                      </div>
                      {validation.touched.image && validation.errors.image ? (
                        <FormFeedback type="invalid">
                          {validation.errors.image}
                        </FormFeedback>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>
            </Form>
          </Row>

          {isUserUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isUserUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer
            limit={1}
            closeButton={false}
            theme="dark"
            autoClose={2000}
          />
        </Container>
      </div>
    </>
  );
};

export default UserEdit;
