import {
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAIL,
  ADD_NEW_FEATURES_SUCCESS,
  ADD_NEW_FEATURES_FAIL,
  GET_FEATURES_DETAILS_SUCCESS,
  GET_FEATURES_DETAILS_FAIL,
  UPDATE_FEATURES_SUCCESS,
  UPDATE_FEATURES_FAIL,
  DELETE_FEATURES_SUCCESS,
  DELETE_FEATURES_FAIL,
  MODIFY_FEATURES_FLAG,
  RESET_FEATURES_FLAG,
  STATUS_CHANGE_FEATURES_SUCCESS,
  STATUS_CHANGE_FEATURES_FAIL,
  SAVE_ORDERING_FEATURES_SUCCESS,
  SAVE_ORDERING_FEATURES_FAIL,
  SAVE_ORDERING_FEATURES_RESET_FLAG,
} from "./actionTypes";
  
  const INIT_STATE = {
    features: [],
    loading: true
  };
  
  const Features = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_FEATURES_SUCCESS:
        
        return {
          ...state,
          features: action.payload.data,
          totalDataCount: action.payload.count,
          isSuccess: true,
          loading: false
        };
  
      case GET_FEATURES_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          totalDataCount: 0,
          loading: false
        };
  
        case ADD_NEW_FEATURES_SUCCESS:
         
        return {
          ...state,
          features: [...state.features, action.payload.data],
          success: action.payload.message,
          isFeaturesAdd: true,
          isFeaturesAddFail: false,
        };
  
      case ADD_NEW_FEATURES_FAIL:
        
        return {
          ...state,
          error: action.payload.message,
          isFeaturesAdd: false,
          isFeaturesAddFail: true,
        };
  
      case UPDATE_FEATURES_SUCCESS:
        return {
          ...state,
          features: state.features.map((step) =>
            step._id.toString() === action.payload.data._id.toString()
              ? { ...step, ...action.payload.data }
              : step
          ),
          success: action.payload.message,
          isFeaturesUpdate: true,
          isFeaturesUpdateFail: false,
        };
  
      case UPDATE_FEATURES_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isFeaturesUpdate: false,
          isFeaturesUpdateFail: true,
        };
  
      case GET_FEATURES_DETAILS_SUCCESS:
        return {
          ...state,
          details: action.payload.data,
          isSuccess: true,
        };
      case GET_FEATURES_DETAILS_FAIL:
        return {
          ...state,
          error: action.payload.message,
        };
  
      case DELETE_FEATURES_SUCCESS:
        return {
          ...state,
          features: state.features.filter((getData) => {
            return !action.payload.data.includes(getData._id);
          }),
          success: action.payload.message,
          isSuccess: true,
          statusSuccess: true,
          statusFailed: false,
          isFeaturesRemove: true,
          isFeaturesRemoveFail: false,
        };
  
      case DELETE_FEATURES_FAIL:
        return {
          ...state,
          error: action.payload.message,
          isSuccess: false,
          statusSuccess: false,
          statusFailed: true,
          isFeaturesRemove: false,
          isFeaturesRemoveFail: true,
        };
        
      //Status Change
      case STATUS_CHANGE_FEATURES_SUCCESS:
        return {
          ...state,
          features: state.features.map((step) =>
          action.payload.Features.id.includes(step._id)
              ? { ...step, status: action.payload.Features.column_value}
              : step
          ),
          success: action.payload.message,
          statusSuccess: true,
          isSuccess: true,
          statusFailed: false,
        };
  
      case STATUS_CHANGE_FEATURES_FAIL:
        return {
          ...state,
          isSuccess: true,
          error: action.payload.message,
          statusSuccess: false,
          statusFailed: true,
        };
  
      //Save Ordering
      case SAVE_ORDERING_FEATURES_SUCCESS:
        return {
          ...state,
          success: action.payload.message,
          saveOrderingSuccess: true,
          saveOrderingFaild: false,
        };
          
      case SAVE_ORDERING_FEATURES_FAIL:
        return {
          ...state,
          error: action.payload.message,
          saveOrderingSuccess: false,
          saveOrderingFaild: true,
        };
  
  
      //Save Ordering reset flag 
      case SAVE_ORDERING_FEATURES_RESET_FLAG:
        return {
          ...state,
          saveOrderingSuccess: false,
          saveOrderingFaild: false,
          error: false
        };
  
  
  
  
      case RESET_FEATURES_FLAG:
        return {
          ...state,
          isFeaturesAdd: false, 
          isFeaturesAddFail: false,
          isSuccess: true,
          isFeaturesDetails: false,
          isFeaturesUpdate: false,
          isFeaturesUpdateFail: false,
          error: false,
          statusSuccess:false,
        };
      case MODIFY_FEATURES_FLAG:
        return {
          ...state,
          statusSuccess: false,
        };
  
  
  
      default:
        return state;
    }
  };
  
  export default Features;
  