import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { addNewSeo, resetSeoFlag, getPages } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import Select from "react-select";

const SeoCreate = () => {
  document.title = "Create SEO | CEO - Admin Panel";
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [pagetype, setPagetype] = useState("");
  const [type, setPageType] = useState("");
  const [description, setData] = useState();
  const { isSeoAdd, isSeoAddFail, error, success, pages } = useSelector(
    (state) => ({
      isSeoAdd: state.Seos.isSeoAdd,
      isSeoAddFail: state.Seos.isSeoAddFail,
      error: state.Seos.error,
      success: state.Seos.success,
      pages: state.Pages.pages,
    })
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      keywords: "",
      author: "",
      description: "",
      type: "",
      type_id: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      keywords: Yup.string().required("Please Enter Keyword"),
      author: Yup.string().required("Please Enter Author"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        keywords: values.keywords,
        author: values.author,
        type: values.type.label,
        type_id: values.type_id.value,
        description: description,
      };
      dispatch(addNewSeo(data));
    },
  });

  useEffect(() => {
    if (isSeoAdd || isSeoAddFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetSeoFlag());
      }, 3000);
    }
  }, [dispatch, isSeoAdd, isSeoAddFail, validation]);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [pages]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Create SEO" pageTitle="Seos" pageUrl="/seos" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Create SEO" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Keyword *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Keyword"
                              name="keywords"
                              value={validation.values.keywords || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.keywords &&
                                validation.errors.keywords
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.keywords &&
                            validation.errors.keywords ? (
                              <FormFeedback type="invalid">
                                {validation.errors.keywords}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">Type *</Label>
                            <Select
                              type="text"
                              name="type"
                              placeholder="Enter Seo Type"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type",
                                    value: selectedOption,
                                  },
                                };
                                setPageType(selectedOption.value);

                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type || ""}
                              invalid={
                                validation.touched.type &&
                                validation.errors.type
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type &&
                            validation.errors.type ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label htmlFor="product-title-input">
                              Type ID *
                            </Label>
                            <Select
                              type="text"
                              name="type_id"
                              placeholder="Enter Type ID"
                              onChange={(selectedOption) => {
                                let event = {
                                  target: {
                                    name: "type_id",
                                    value: selectedOption,
                                  },
                                };
                                setPageType(selectedOption.value);

                                validation.handleChange(event);
                              }}
                              options={pagetype}
                              onBlur={validation.handleBlur}
                              value={validation.values.type_id || ""}
                              invalid={
                                validation.touched.type_id &&
                                validation.errors.type_id
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.type_id &&
                            validation.errors.type_id ? (
                              <FormFeedback type="invalid">
                                {validation.errors.type_id}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={6}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Author *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Author"
                              name="author"
                              value={validation.values.author || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.author &&
                                validation.errors.author
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.author &&
                            validation.errors.author ? (
                              <FormFeedback type="invalid">
                                {validation.errors.author}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={3} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/seos" className="btn btn-danger w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Create{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Create</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isSeoAdd ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isSeoAddFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default SeoCreate;
