import {
  GET_PLAYLIST_SUCCESS,
  GET_PLAYLIST_FAIL,
  ADD_NEW_PLAYLIST_SUCCESS,
  ADD_NEW_PLAYLIST_FAIL,
  GET_PLAYLIST_DETAILS_SUCCESS,
  GET_PLAYLIST_DETAILS_FAIL,
  UPDATE_PLAYLIST_SUCCESS,
  UPDATE_PLAYLIST_FAIL,
  DELETE_PLAYLIST_SUCCESS,
  DELETE_PLAYLIST_FAIL,
  MODIFY_PLAYLIST_FLAG,
  RESET_PLAYLIST_FLAG,
  STATUS_CHANGE_PLAYLIST_SUCCESS,
  STATUS_CHANGE_PLAYLIST_FAIL,
  FEATURED_CHANGE_PLAYLIST_SUCCESS,
  FEATURED_CHANGE_PLAYLIST_FAIL,
  SAVE_ORDERING_PLAYLIST_SUCCESS,
  SAVE_ORDERING_PLAYLIST_FAIL,
  SAVE_ORDERING_PLAYLIST_RESET_FLAG,
} from "./actionType";

const INIT_STATE = {
  playlists: [],
  details: [],
  loading: true
};

const Playlists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
        loading: false
      };

    case GET_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        totalDataCount: 0,
        loading: false
      };

    case ADD_NEW_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: [...state.playlists, action.payload.data],
        success: action.payload.message,
        isPlaylistAdd: true,
        isPlaylistAddFail: false,
      };

    case ADD_NEW_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPlaylistAdd: false,
        isPlaylistAddFail: true,
      };

    case UPDATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: state.playlists.map((playlist) =>
          playlist._id.toString() === action.payload.data._id.toString()
            ? { ...playlist, ...action.payload.data }
            : playlist
        ),
        success: action.payload.message,
        isPlaylistUpdate: true,
        isPlaylistUpdateFail: false,
      };

    case UPDATE_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isPlaylistUpdate: false,
        isPlaylistUpdateFail: true,
      };

    case GET_PLAYLIST_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_PLAYLIST_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    case DELETE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: state.playlists.filter((getData) => {
          return !action.payload.data.includes(getData._id);
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isPlaylistRemove: true,
        isPlaylistRemoveFail: false,
      };

    case DELETE_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isPlaylistRemove: false,
        isPlaylistRemoveFail: true,
      };

    //Status Change
    case STATUS_CHANGE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: state.playlists.map((playlist) =>
          action.payload.playlists.id.includes(playlist._id)
            ? { ...playlist, status: action.payload.playlists.column_value }
            : playlist
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    // Feature Change
    case FEATURED_CHANGE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: state.playlists.map((playlist) =>
          action.payload.playlists.id.includes(playlist._id)
            ? { ...playlist, featured: action.payload.playlists.column_value }
            : playlist
        ),
        success: action.payload.message,
        featuredSuccess: true,
        featuredFailed: false,
      };

    case FEATURED_CHANGE_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        featuredSuccess: false,
        featuredFailed: true,
      };
    //Save Ordering
    case SAVE_ORDERING_PLAYLIST_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_PLAYLIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_PLAYLIST_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    case RESET_PLAYLIST_FLAG:
      return {
        ...state,
        isPlaylistAdd: false,
        isPlaylistAddFail: false,
        isSuccess: false,
        isPlaylistDetails: false,
        isPlaylistUpdate: false,
        isPlaylistUpdateFail: false,
        error: false,
      };
    case MODIFY_PLAYLIST_FLAG:
      return {
        ...state,
        statusSuccess: false,
        statusFaild: false,
        error: false,
      };

    default:
      return state;
  }
};

export default Playlists;
