import { useState, useEffect } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Spinner,
  Row,
  Input,
  Label,
  FormFeedback,
  Form,
  CardFooter,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateUser,
  resetUserFlag,
  getUserDetails,
} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { convertToBase64 } from "../../../helpers/image";
import { APIClient } from "../../../helpers/api_helper";
const AdminEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Admin | " + metatitle;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [image, setImage] = useState();
  const [isCreating, setIsCreating] = useState(false);
  const { isUserUpdate, isUserUpdateFail, error, success, details } =
    useSelector((state) => ({
      isUserUpdate: state.Users.isUserUpdate,
      isUserUpdateFail: state.Users.isUserUpdateFail,
      error: state.Users.error,
      success: state.Users.success,
      details: state.Users.details,
    }));

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details && details.name ? details.name : "",
      email: details && details.email ? details.email : "",
      image: details && details.image ? details.image : "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      email: Yup.string().required("Please Enter Email"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: id,
        name: values.name,
        email: values.email,
        password: values.password,
        image: image,
      };
      dispatch(updateUser(data));
    },
  });

  useEffect(() => {
    if (isUserUpdate || isUserUpdateFail) {
      setIsCreating(false);
      validation.resetForm();
      setTimeout(() => {
        dispatch(resetUserFlag());
        window.location.href = "/admins";
      }, 3000);
    }
  }, [dispatch, isUserUpdate, isUserUpdateFail, validation]);

  useEffect(() => {
    dispatch(getUserDetails({ id: id, type: "admin" }));
  }, [dispatch, id]);
  const allowedExts = ["jpg", "jpeg", "png", "svg", "svg+xml"];
  const onImageChange = (e) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (![...allowedExts].includes(ext)) {
        toast("Please upload a valid image.");
      } else {
        var output = document.getElementById("image1");
        output.src = URL.createObjectURL(e.target.files[0]);
        output.onload = function () {
          URL.revokeObjectURL(output.src);
        };
        convertToBase64(e.target.files[0], setImage);
      }
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Admin" pageTitle="Admins" pageUrl="/admins" />
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col lg={8}>
                  <Card>
                    <PreviewCardHeader title="Edit Admin" />

                    <CardBody>
                      <div className="live-preview">
                        <Row>
                          <Col xxl={12}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Name *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                value={validation.values.name || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.name &&
                                  validation.errors.name
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.name &&
                              validation.errors.name ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.name}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={6} md={6}>
                            <div className="mb-4">
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Email *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={validation.values.email || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xxl={6} md={6}>
                            <div>
                              <Label
                                htmlFor="placeholderInput"
                                className="form-label"
                              >
                                Password *
                              </Label>
                              <Input
                                type="text"
                                id="placeholderInput"
                                className="form-control"
                                placeholder="Password"
                                name="password"
                                value={validation.values.password || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                validate={{
                                  required: { value: true },
                                }}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <Link to="/admins" className="btn btn-reset w-sm me-1">
                        Cancel
                      </Link>
                      <Button
                        color="primary"
                        className="btn-load"
                        disabled={isCreating}
                        type="submit"
                      >
                        <span className="d-flex align-items-center">
                          {isCreating && (
                            <Spinner size="sm" className="flex-shrink-0 me-2">
                              {" "}
                              Update{" "}
                            </Spinner>
                          )}
                          <span className="flex-grow-1">Update</span>
                        </span>
                      </Button>
                    </CardFooter>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card>
                    <PreviewCardHeader title="Image *" />
                    <CardBody>
                      <div className="mb-4">
                        <Input
                          type="file"
                          id="placeholderInput"
                          className="form-control"
                          placeholder="Place your image"
                          name="image"
                          onChange={onImageChange}
                        />
                      </div>
                      <div className="image-container">
                        <img
                          src={validation.values.image || ""}
                          alt=""
                          id="image1"
                        />
                      </div>
                      {validation.touched.image && validation.errors.image ? (
                        <FormFeedback type="invalid">
                          {validation.errors.image}
                        </FormFeedback>
                      ) : null}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Row>
          {isUserUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isUserUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} theme="dark" />
        </Container>
      </div>
    </>
  );
};

export default AdminEdit;
