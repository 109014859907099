// Steps

export const GET_STEPS = "GET_STEPS";
export const GET_STEPS_SUCCESS = "GET_STEPS_SUCCESS";
export const GET_STEPS_FAIL = "GET_STEPS_FAIL";

export const ADD_NEW_STEPS = "ADD_NEW_STEPS";
export const ADD_NEW_STEPS_SUCCESS = "ADD_NEW_STEPS_SUCCESS";
export const ADD_NEW_STEPS_FAIL = "ADD_NEW_STEPS_FAIL";

export const UPDATE_STEPS = "UPDATE_STEPS";
export const UPDATE_STEPS_SUCCESS = "UPDATE_STEPS_SUCCESS";
export const UPDATE_STEPS_FAIL = "UPDATE_STEPS_FAIL";

export const GET_STEPS_DETAILS = "GET_STEPS_DETAILS";
export const GET_STEPS_DETAILS_SUCCESS = "GET_STEPS_DETAILS_SUCCESS";
export const GET_STEPS_DETAILS_FAIL = "GET_STEPS_DETAILS_FAIL";

export const DELETE_STEPS = "DELETE_STEPS";
export const DELETE_STEPS_SUCCESS = "DELETE_STEPS_SUCCESS";
export const DELETE_STEPS_FAIL = "DELETE_STEPS_FAIL";
export const MODIFY_STEPS_FLAG = "MODIFY_STEPS_FLAG";

export const RESET_STEPS_FLAG = "RESET_STEPS_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_STEPS = "STATUS_CHANGE_STEPS";
export const STATUS_CHANGE_STEPS_SUCCESS = "STATUS_CHANGE_STEPS_SUCCESS";
export const STATUS_CHANGE_STEPS_FAIL = "STATUS_CHANGE_STEPS_FAIL";


export const SAVE_ORDERING_STEPS = "SAVE_ORDERING_STEPS";
export const SAVE_ORDERING_STEPS_SUCCESS = "SAVE_ORDERING_STEPS_SUCCESS";
export const SAVE_ORDERING_STEPS_FAIL = "SAVE_ORDERING_STEPS_FAIL";
export const SAVE_ORDERING_STEPS_RESET_FLAG = "SAVE_ORDERING_STEPS_RESET_FLAG";

// FEATURED CHANGE
export const FEATURED_CHANGE_STEPS = "FEATURED_CHANGE_STEPS";
export const FEATURED_CHANGE_STEPS_SUCCESS = "FEATURED_CHANGE_STEPS_SUCCESS";
export const FEATURED_CHANGE_STEPS_FAIL = "FEATURED_CHANGE_STEPS_FAIL";