//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/profile-update";
export const GET_PROFILE_DATA = "/profile-details";
export const GET_PROFILE_DETAILS_DATA= "/profileData-details";

//CATEGORIES
export const GET_CATEGORIES_LIST = "/category/list";
export const ADD_CATEGORY = "/category/add";
export const GET_CATEGORY = "/category/details";
export const UPDATE_CATEGORY = "/category/edit";
export const DELETE_CATEGORY = "/category/delete";

//Status Change Categories

export const STATUS_CHANGE_CAT = "/category/status-change";
//Blogs
export const GET_BLOGS_LIST = "/blog/list";
export const ADD_BLOG = "/blog/add";
export const UPDATE_BLOG = "/blog/edit";
export const DELETE_BLOG = "/blog/remove";

// Channels
export const GET_CHANNELS_LIST = "/v2/channels";

// Playlists
export const GET_PLAYLISTS_LIST = "/video-playlist/list";
export const ADD_PLAYLIST = "/video-playlist/add";
export const GET_PLAYLIST = "/video-playlist/details";
export const UPDATE_PLAYLIST = "/video-playlist/edit";

// Steps
export const GET_STEPS_LIST = "/how-it-work/list";
export const ADD_STEP = "/how-it-work/create";
export const GET_STEPS = "/how-it-work/details";
export const UPDATE_STEPS = "/how-it-work/edit";


// Features
export const GET_FEATURE_LIST = "/feature/list";
export const ADD_FEATURE = "/become-member/add";
export const GET_FEATURE = "/feature/details";
export const UPDATE_FEATURE = "/feature/edit";

// Members
export const GET_MEMBER_LIST = "/become-member/list";
export const ADD_MEMBER = "/become-member/add";
export const GET_MEMBER = "/become-member/details";
export const UPDATE_MEMBER = "/become-member/edit";
export const STATUS_CHANGE_MEMBER = "/become-member/status-change"
export const DELETE_MEMBER = "/become-member/delete";





// Role
export const GET_ROLE_LIST = "/role/list";
export const ADD_NEW_ROLE = "/role/add";
export const GET_ROLE_DETAILS = "/role/details";

//USER CREATE
export const ADD_NEW_USER = "/user/add";
//USER LIST
export const GET_USER_LIST = "/user-search";
//USER DETAILS
export const GET_USER_DETAILS = "/user/details";
//USER UPDATE
export const UPDATE_USER = "/user/edit";
// DELETE USER
export const DELETE_USER = "/user/delete";
//ADMIN LIST
export const GET_ADMIN_LIST = "/user-search";

//Status Change User

export const STATUS_CHANGE_USER = "/user/email-status-change";




// faqs
export const GET_FAQ_LIST ="/faq/list";
export const ADD_NEW_FAQ = "/faq/add";
export const UPDATE_FAQ = "/faq/edit";
export const DELETE_FAQ = "/faq/delete";
export const STATUS_CHANGE_FAQ = "/faq/status-change";
export const FAQ_DETAILS = "/faq/details"

// Tags
export const GET_TAG_LIST ="/tags";
export const ADD_NEW_TAG = "/tag/create";
export const UPDATE_TAG = "/tag/edit";
export const TAG_DETAILS = "/tag/details";

// Events
export const GET_EVENT_LIST ="/event/list";
export const ADD_NEW_EVENT = "/event/create";
export const UPDATE_EVENT = "/event/edit";
export const EVENT_DETAILS = "/event/details";

// Connection
export const GET_CONNECTION_LIST = "/connection-list";
export const ADD_NEW_CONNECTION = "/send-connection";

// Banner
export const GET_BANNER_LIST = "/banner/list";
export const GET_BANNER_DETAILS = "/banner/details";
export const ADD_NEW_BANNER = "/banner/add";
export const UPDATE_BANNER = "/banner/edit";
export const DELETE_BANNER = "/banner/delete";

// Sites

export const GET_SITE_DETAILS = "/site-setting/details";
export const UPDATE_SITE = "/site-setting/update";

// Pages
export const GET_PAGE_LIST = "/page/list";
export const GET_PAGE_DETAILS = "/page/details";
export const ADD_NEW_PAGE = "/page/add";
export const UPDATE_PAGE = "/page/edit";

// SEO
export const GET_SEO_LIST = "/seo/list";
export const ADD_NEW_SEO = "/seo/add";
export const GET_SEO_DETAILS = "/seo/details";
export const UPDATE_SEO = "/seo/edit";
export const DELETE_SEO = "/seo/delete";

// Contacts
export const GET_CONTACT_LIST = "/list-contact";
// export const GET_CONTACT_DETAILS = "/contact/details"; //double check api in postman
export const ADD_NEW_CONTACT = "/add-contact";
// export const UPDATE_CONTACT= "/contact/edit"; //double check api in postman

export const DELETE_CONTACT = "/delete-contact";

//Status Change Contact

export const STATUS_CHANGE_CONTACT = "/status-change-contact";

// Save ordering
export const SAVE_ORDERING= "/common/ordering";

export const DELETE_DATA = "/common/delete";

// Status Change Common
export const STATUS_CHANGE = "/common/status-change";

