import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Form,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import {
  updatePage,
  resetPageFlag,
  getPageDetails,
} from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";
import { APIClient } from "../../../helpers/api_helper";
import PageNav from "../../../Components/PageNavigation/PageNav";
import MemberNav from "../../../Components/PageNavigation/MemberNav";

const PageEdit = () => {
  const api = new APIClient();
  const [metatitle, setMetaTitle] = useState("CEO");
  document.title = "Edit Page | " + metatitle;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [description, setData] = useState();

  const { isPageUpdate, isPageUpdateFail, error, success, details } =
    useSelector((state) => ({
      isPageUpdate: state.Pages.isPageUpdate,
      isPageUpdateFail: state.Pages.isPageUpdateFail,
      error: state.Pages.error,
      success: state.Pages.success,
      details: state.Pages.details,
    }));

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      subtitle: details && details.subtitle ? details.subtitle : "",
      description: details && details.description ? details.description : "",
      slug: details && details.slug ? details.slug : "",
      video_url: details && details.video_url ? details.video_url : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      slug: Yup.string().required("Please Enter Slug"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        id: details && details._id ? details._id : "",
        video_url: values.video_url,
        title: values.title,
        subtitle: values.subtitle,
        description: description,
        slug: values.slug,
      };
      dispatch(updatePage(data));
    },
  });

  useEffect(() => {
    if (isPageUpdate || isPageUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetPageFlag());
      }, 3000);
    }
  }, [dispatch, isPageUpdate, isPageUpdateFail]);

  useEffect(() => {
    dispatch(getPageDetails({ slug: id }));
  }, [dispatch, id]);
  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit Page" pageTitle="Pages" pageUrl="/pages" />
          <Row>
            <Col lg={12}>
              {details?.slug === "home" && (
                <PageNav page_id={details?.slug} id={details._id} />
              )}
              {details?.slug === "premium-access" && (
                <PageNav page_id={details?.slug} id={details._id} />
              )}
              {details?.slug === "home-banner" && (
                <PageNav page_id={details?.slug} id={details._id} />
              )}
              {details?.slug === "membership" && (
                <MemberNav page_id={details?.slug} id={details._id} />
              )}
              {details?.slug === "caught-it" && (
                <MemberNav page_id={details?.slug} id={details._id} />
              )}
              {details?.slug === "exclusive-community" && (
                <PageNav page_id={details?.slug} id={details._id} />
              )}
            </Col>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col lg={12}>
                <Card>
                  <PreviewCardHeader title="Edit Page" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <Row className="gy-4">
                        {details?.slug === "home-banner" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            {/* <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col> */}
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Description *
                                </Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  name="description"
                                  data={validation.values.description || ""}
                                  onChange={(event, editor) => {
                                    setData(editor.getData());
                                  }}
                                  invalid={false}
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "exclusive-community" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "membership" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "home-video" ? (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Video URL (optional)
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Video URL"
                                  name="video_url"
                                  value={validation.values.video_url || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.video_url &&
                                    validation.errors.video_url
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.video_url &&
                                validation.errors.video_url ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.video_url}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        ) : null} 

                        {details?.slug === "caught-it" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Description *
                                </Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  name="description"
                                  data={validation.values.description || ""}
                                  onChange={(event, editor) => {
                                    setData(editor.getData());
                                  }}
                                  invalid={false}
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "privacy-policy" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Subtitle *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page subtitle"
                                  name="subtitle"
                                  value={validation.values.subtitle || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.subtitle &&
                                    validation.errors.subtitle
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.subtitle &&
                                validation.errors.subtitle ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.subtitle}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Description *
                                </Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  name="description"
                                  data={validation.values.description || ""}
                                  onChange={(event, editor) => {
                                    setData(editor.getData());
                                  }}
                                  invalid={false}
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "terms-condition" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Subtitle *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page subtitle"
                                  name="subtitle"
                                  value={validation.values.subtitle || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.subtitle &&
                                    validation.errors.subtitle
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.subtitle &&
                                validation.errors.subtitle ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.subtitle}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Description *
                                </Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  name="description"
                                  data={validation.values.description || ""}
                                  onChange={(event, editor) => {
                                    setData(editor.getData());
                                  }}
                                  invalid={false}
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {details?.slug === "cookies-policy" && (
                          <>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Title *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page title"
                                  name="title"
                                  value={validation.values.title || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Subtitle *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Page subtitle"
                                  name="subtitle"
                                  value={validation.values.subtitle || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.subtitle &&
                                    validation.errors.subtitle
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.subtitle &&
                                validation.errors.subtitle ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.subtitle}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={4} md={4} style={{ display: "none" }}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Slug *
                                </Label>
                                <Input
                                  type="text"
                                  id="placeholderInput"
                                  className="form-control"
                                  placeholder="Slug name"
                                  name="slug"
                                  value={validation.values.slug || ""}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  invalid={
                                    validation.touched.slug &&
                                    validation.errors.slug
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.slug &&
                                validation.errors.slug ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.slug}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col xxl={12} md={12}>
                              <div>
                                <Label
                                  htmlFor="placeholderInput"
                                  className="form-label"
                                >
                                  Description *
                                </Label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  name="description"
                                  data={validation.values.description || ""}
                                  onChange={(event, editor) => {
                                    setData(editor.getData());
                                  }}
                                  invalid={false}
                                />
                                {validation.touched.description &&
                                validation.errors.description ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.description}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {/* <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Page title"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.title &&
                                validation.errors.title
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.title &&
                            validation.errors.title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.title}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Subtitle *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Page subtitle"
                              name="subtitle"
                              value={validation.values.subtitle || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.subtitle &&
                                validation.errors.subtitle
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.subtitle &&
                            validation.errors.subtitle ? (
                              <FormFeedback type="invalid">
                                {validation.errors.subtitle}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={4} md={4}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Slug *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Slug name"
                              name="slug"
                              value={validation.values.slug || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.slug &&
                                validation.errors.slug
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.slug &&
                            validation.errors.slug ? (
                              <FormFeedback type="invalid">
                                {validation.errors.slug}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Video URL *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Video URL"
                              name="video_url"
                              value={validation.values.video_url || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={
                                validation.touched.video_url &&
                                validation.errors.video_url
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.video_url &&
                            validation.errors.video_url ? (
                              <FormFeedback type="invalid">
                                {validation.errors.video_url}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label
                              htmlFor="placeholderInput"
                              className="form-label"
                            >
                              Description *
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description &&
                            validation.errors.description ? (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col> */}
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/pages" className="btn btn-reset w-sm me-1">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    className="btn-load"
                    disabled={isCreating}
                    type="submit"
                  >
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Col>
            </Form>
          </Row>
          {isPageUpdate ? (
            <MsgToast
              msg={success}
              color="success"
              icon="ri-checkbox-circle-line"
            />
          ) : null}
          {isPageUpdateFail ? (
            <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
          ) : null}
          <ToastContainer limit={1} closeButton={false} />
        </Container>
      </div>
    </>
  );
};

export default PageEdit;
