import { Redirect } from "react-router-dom";
//Site Setting Route
import SiteEdit from "../pages/Sites/SiteEdit";

//user
import UserCreate from "../pages/Users/UserCreate";
import UserEdit from "../pages/Users/UserEdit";
import UserList from "../pages/Users/UserList";
import ViewUsers from "../pages/Users/ViewUsers";
//admin
import AdminCreate from "../pages/Admins/AdminCreate";
import AdminList from "../pages/Admins/AdminList";
import AdminEdit from "../pages/Admins/AdminEdit";

//Categories
import Categories from "../pages/Categories/List";
import AddCategory from "../pages/Categories/Add";
import EditCategory from "../pages/Categories/Edit";

// Roles
import roleList from "../pages/Roles Management/List";
import RoleCreate from "../pages/Roles Management/Create";

// Dashboard
import Dashboard from "../pages/Dashboard";

// Stories
import StoriesList from "../pages/Stories/List";
import StoriesView from "../pages/Stories/ViewStories";

// Channels
import Channels from "../pages/Channels/List";
import ChannelMsg from "../pages/ChannelMsg";
//  Posts
import PostList from "../pages/Posts/PostList";
import SubPost from "../pages/Posts/SubPost";

// Comments
import CommentList from "../pages/Comments/List";
import SubCommentList from "../pages/Comments/Subcomments/List";

// Community Category
import CommentCategoryList from "../pages/Community/Category/List";

// Community
import CommunityList from "../pages/Community/communityList";
import MemberCreate from "../pages/BecomeMember/Add";

// Members
import MemberList from "../pages/BecomeMember/List";
import MemberEdit from "../pages/BecomeMember/Edit";

//Video
import PlayList from "../pages/Playlist/List";
import PlaylistCreate from "../pages/Playlist/Create";
import PlaylistEdit from "../pages/Playlist/Edit";
import ViewPage from "../pages/Playlist/ViewPage";

//Pages
import PageList from "../pages/MyPages/PageList";
import PageCreate from "../pages/MyPages/PageCreate";
import PageEdit from "../pages/MyPages/PageEdit";

// Faqs
import FaqList from "../pages/FAQs/FaqList";
import FaqCreate from "../pages/FAQs/FaqCreate";
import FaqEdit from "../pages/FAQs/FaqEdit";

// Events
import EventList from "../pages/Events/List";
import EventsDetails from "../pages/Events/EventDetails";

// Tags
import TagList from "../pages/Tags/TagList";
import TagCreate from "../pages/Tags/TagCreate";
import TagEdit from "../pages/Tags/TagEdit";

// Connection
import ConnectionList from "../pages/Connections/List";

// Steps
import StepList from "../pages/Steps/StepList";
import StepCreate from "../pages/Steps/StepCreate";
import StepEdit from "../pages/Steps/StepEdit";

// Features
import FeatureList from "../pages/Features/FeatureList";
import FeatureCreate from "../pages/Features/FeatureCreate";
import FeatureEdit from "../pages/Features/FeatureEdit";

//SEO
import SeoList from "../pages/Seos/SeoList";
import SeoCreate from "../pages/Seos/SeoCreate";
import SeoEdit from "../pages/Seos/SeoEdit";

//Contacts
import ContactList from "../pages/Contacts/ContactList";
import ContactCreate from "../pages/Contacts/ContactCreate";
import ContactEdit from "../pages/Contacts/ContactEdit";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import ChangePassword from "../pages/Authentication/ChangePassword";
import SavedContentList from "../pages/SavedContent";
import DraftList from "../pages/Drafts";

const authProtectedRoutes = [
  //Users
  { path: "/user/create", component: UserCreate },
  { path: "/users", component: UserList },
  { path: "/user/edit/:id", component: UserEdit },
  { path: "/user/view/:id", component: ViewUsers },

  //Admins
  { path: "/admin/create", component: AdminCreate },
  { path: "/admin/edit/:id", component: AdminEdit },
  { path: "/admins", component: AdminList },

  //export * from "./categories/actions";
  { path: "/video-categories", component: Categories },
  { path: "/blog-categories", component: Categories },
  { path: "/category/create", component: AddCategory },
  { path: "/category/edit/:id", component: EditCategory },
  { path: "/faq-categories", component: Categories },

  // Dashboard
  { path: "/dashboard", component: Dashboard },
  // Stories

  { path: "/stories", component: StoriesList },
  { path: "/stories/view/:id", component: StoriesView },

  // roles
  { path: "/roles", component: roleList },
  { path: "/role/create", component: RoleCreate },

  // Channels

  { path: "/channels", component: Channels },
  { path: "/channel/:id", component: ChannelMsg },

  // posts

  { path: "/posts", component: PostList },
  { path: "/posts/:usrId", component: PostList },
  { path: "/post/:id", component: SubPost },

  // saved content
  { path: "/saved-content/:usrId", component: SavedContentList },

  // draft
  { path: "/draft/:usrId", component: DraftList },

  // members
  { path: "/members", component: MemberList },
  { path: "/member/create", component: MemberCreate },
  { path: "/member/edit/:id", component: MemberEdit },

  // Comments
  { path: "/comments", component: CommentList },

  // CommentCategory
  { path: "/category", component: CommentCategoryList },
  { path: "/community", component: CommunityList },

  // Subcomments
  { path: "/subcomments", component: SubCommentList },

  //Videos

  { path: "/playlist", component: PlayList },
  { path: "/playlist/create", component: PlaylistCreate },
  { path: "/playlist/edit/:id", component: PlaylistEdit },
  { path: "/playlist/view/:id", component: ViewPage },

  // Connection
  { path: "/connections", component: ConnectionList },

  // Faqs

  { path: "/faqs", component: FaqList },
  { path: "/faq/create", component: FaqCreate },
  { path: "/faq/edit/:id", component: FaqEdit },

  // Tags
  { path: "/tags", component: TagList },
  { path: "/tag/create", component: TagCreate },
  { path: "/tag/edit/:id", component: TagEdit },

  // Steps
  { path: "/exclusive-communities", component: StepList },
  { path: "/step/create", component: StepCreate },
  { path: "/step/edit/:id", component: StepEdit },

  // Features
  { path: "/features", component: FeatureList },
  { path: "/feature/create", component: FeatureCreate },
  { path: "/feature/edit/:id", component: FeatureEdit },

  //  Events
  { path: "/events", component: EventList },
  { path: "/event/view/:event_id", component: EventsDetails },

  // Sites

  { path: "/site/edit/:id", component: SiteEdit },

  // Pages

  { path: "/pages", component: PageList },
  { path: "/page/create", component: PageCreate },
  { path: "/page/edit/:id", component: PageEdit },

  // SEO

  { path: "/seos", component: SeoList },
  { path: "/seo/create", component: SeoCreate },
  { path: "/seo/:type/:id", component: SeoEdit },

  // Contacts

  { path: "/contacts", component: ContactList },
  { path: "/contact/create", component: ContactCreate },
  { path: "/contact/edit/:id", component: ContactEdit },

  //User Profile
  { path: "/profile", component: UserProfile },

  // Change Password
  { path: "/change-password", component: ChangePassword },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
];

export { authProtectedRoutes, publicRoutes };
