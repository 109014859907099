import { useEffect, useState } from "react";
import { Container, Row, Col, Label, Card, CardBody, Input, Button, Spinner, FormFeedback, Form } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewCardHeader from "../../../Components/Common/PreviewCardHeader";
import { updateSeo, resetSeoFlag, getSeoDetails, getPages } from "../../../store/actions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MsgToast from "../../../Components/Common/MsgToast";

const SeoEdit = () => {
  document.title = "Edit SEO | CEO";
  const { id } = useParams();
  const { type } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCreating, setIsCreating] = useState(false);
  const [pagetype, setPagetype] = useState([]);
  const [description, setData] = useState();

  const { isSeoUpdate, isSeoUpdateFail, error, success, details, pages } = useSelector((state) => ({
    isSeoUpdate: state.Seos.isSeoUpdate,
    isSeoUpdateFail: state.Seos.isSeoUpdateFail,
    error: state.Seos.error,
    success: state.Seos.success,
    details: state.Seos.details,
    pages: state.Pages.pages,
  }));


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: details && details.title ? details.title : "",
      description: details && details.description ? details.description : "",
      keywords: details && details.keywords ? details.keywords : "",
      author: details && details.author ? details.author : "",
      type: details && details.type ? details.type : "",
      type_id: details && details.type_id ? details.type_id : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title Name"),
      keywords: Yup.string().required("Please Enter Keyword Name"),
      author: Yup.string().required("Please Enter Author Name"),
    }),
    onSubmit: (values) => {
      setIsCreating(true);
      const data = {
        title: values.title,
        keywords: values.keywords,
        author: values.author,
        description: description,
        type: type,
        type_id: id,
      };
      dispatch(updateSeo(data));
    },
  });

  useEffect(() => {
    if (isSeoUpdate || isSeoUpdateFail) {
      setIsCreating(false);
      setTimeout(() => {
        dispatch(resetSeoFlag());
      }, 3000);
    }
  }, [dispatch, isSeoUpdate, isSeoUpdateFail]);

  useEffect(() => {
    dispatch(getSeoDetails({ typeId: id, type: type }));
  }, [dispatch, id, type]);

  useEffect(() => {
    dispatch(getPages());
  }, [dispatch]);

  useEffect(() => {
    let pagetypes = [];
    if (pages) {
      for (let i = 0; i < pages?.length; i++) {
        pagetypes[i] = { label: pages[i].title, value: pages[i]._id };
      }
    }
    setPagetype(pagetypes);
  }, [pages]);

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <BreadCrumb title="Edit SEO" pageTitle="Seos" pageUrl="/seos" />
          <Row>
            <Col lg={12}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Card>
                  <PreviewCardHeader title="Edit SEO" />

                  <CardBody className="card-body">
                    <div className="live-preview">
                      <div className="row">
                        <Col xxl={4} md={4}>
                          <div>
                            <Label htmlFor="placeholderInput" className="form-label">
                              Title *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Title name"
                              name="title"
                              value={validation.values.title || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={validation.touched.title && validation.errors.title ? true : false}
                            />
                            {validation.touched.title && validation.errors.title ? <FormFeedback type="invalid">{validation.errors.title}</FormFeedback> : null}
                          </div>
                        </Col>
                        
                        <Col xxl={4} md={4}>
                          <div>
                            <Label htmlFor="placeholderInput" className="form-label">
                              Keyword *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Keyword name"
                              name="keywords"
                              value={validation.values.keywords || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={validation.touched.keywords && validation.errors.keywords ? true : false}
                            />
                            {validation.touched.keywords && validation.errors.keywords ? <FormFeedback type="invalid">{validation.errors.keywords}</FormFeedback> : null}
                          </div>
                        </Col>

                        

                        <Col xxl={4} md={4}>
                          <div>
                            <Label htmlFor="placeholderInput" className="form-label">
                              Author *
                            </Label>
                            <Input
                              type="text"
                              id="placeholderInput"
                              className="form-control"
                              placeholder="Author name"
                              name="author"
                              value={validation.values.author || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              validate={{
                                required: { value: true },
                              }}
                              invalid={validation.touched.author && validation.errors.author ? true : false}
                            />
                            {validation.touched.author && validation.errors.author ? <FormFeedback type="invalid">{validation.errors.author}</FormFeedback> : null}
                          </div>
                        </Col>

                        <Col xxl={12} md={12}>
                          <div>
                            <Label htmlFor="placeholderInput" className="form-label">
                              Description*
                            </Label>
                            <CKEditor
                              editor={ClassicEditor}
                              name="description"
                              data={validation.values.description || ""}
                              onChange={(event, editor) => {
                                setData(editor.getData());
                              }}
                              invalid={false}
                            />
                            {validation.touched.description && validation.errors.description ? <FormFeedback type="invalid">{validation.errors.description}</FormFeedback> : null}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <div className="text-end mb-4">
                  <Link to="/pages" className="btn btn-reset w-sm me-1">
                    Cancel
                  </Link>
                  <Button color="success" className="btn-load" disabled={isCreating} type="submit">
                    <span className="d-flex align-items-center">
                      {isCreating && (
                        <Spinner size="sm" className="flex-shrink-0 me-2">
                          {" "}
                          Update{" "}
                        </Spinner>
                      )}
                      <span className="flex-grow-1">Update</span>
                    </span>
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
          {isSeoUpdate ? <MsgToast msg={success} color="success" icon="ri-checkbox-circle-line" /> : null}
          {isSeoUpdateFail ? <MsgToast msg={error} color="danger" icon="ri-error-warning-line" /> : null}
          <ToastContainer limit={1} closeButton={false} theme="dark" autoClose={2000} />
        </Container>
      </div>
    </>
  );
};

export default SeoEdit;
