import {
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  RESET_EVENT_FLAG,
  DELETE_EVENT_FAIL,
  STATUS_CHANGE_EVENT_SUCCESS,
  STATUS_CHANGE_EVENT_FAIL,
  MODIFY_EVENT_FLAG,
  SAVE_ORDERING_EVENT_SUCCESS,
  SAVE_ORDERING_EVENT_FAIL,
  SAVE_ORDERING_EVENT_RESET_FLAG,
  GET_EVENT_DETAILS_SUCCESS,
  GET_EVENT_DETAILS_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  events: [],
  error: {},
  loading: true
};

const Events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.data,
        totalDataCount: action.payload.count,
        isSuccess: true,
        loading: false
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload.message,
        totalDataCount: 0,
        isSuccess: false,
        loading: false
      };

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload.data],
        success: action.payload.message,
        isEventAdd: true,
        isEventAddFail: false,
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isEventAdd: false,
        isEventAddFail: true,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map((event) =>
          event._id.toString() === action.payload.data._id.toString()
            ? { event, ...action.payload.data }
            : event
        ),
        success: action.payload.message,
        isEventUpdate: true,
        isEventUpdateFail: false,
      };

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isEventUpdate: false,
        isEventUpdateFail: true,
      };

    case MODIFY_EVENT_FLAG:
      return {
        ...state,
        statusSuccess: false,
      };

    case RESET_EVENT_FLAG:
      return {
        ...state,
        isEventAdd: false,
        isEventAddFail: false,
        isSuccess: false,
        isEventDetails: false,
        isEventUpdate: false,
        isEventUpdateFail: false,
        error: false,
      };

    case GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.payload.data,
        isSuccess: true,
      };
    case GET_EVENT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };

    // Status Change
    case STATUS_CHANGE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map((event) =>
          action.payload.events.id.includes(event._id)
            ? { ...event, status: action.payload.events.column_value }
            : event
        ),
        success: action.payload.message,
        statusSuccess: true,
        statusFailed: false,
      };

    case STATUS_CHANGE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        statusSuccess: false,
        statusFailed: true,
      };

    //Save Ordering
    case SAVE_ORDERING_EVENT_SUCCESS:
      return {
        ...state,
        success: action.payload.message,
        saveOrderingSuccess: true,
        saveOrderingFaild: false,
      };

    case SAVE_ORDERING_EVENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        saveOrderingSuccess: false,
        saveOrderingFaild: true,
      };

    //Save Ordering reset flag
    case SAVE_ORDERING_EVENT_RESET_FLAG:
      return {
        ...state,
        saveOrderingSuccess: false,
        saveOrderingFaild: false,
        error: false,
      };

    //Delete

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter((event) => {
          return !action.payload.data.includes(event._id);
        }),
        success: action.payload.message,
        isSuccess: true,
        statusSuccess: true,
        statusFailed: false,
        isEventRemove: true,
        isEventRemoveFail: false,
      };

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        isSuccess: false,
        statusSuccess: false,
        statusFailed: true,
        isEventRemove: false,
        isEventRemoveFail: true,
      };

    default:
      return state;
  }
};

export default Events;
