import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Categories
import Categories from "./categories/reducer";

// playlist
import Playlists from "./playlist/reducer";

// Steps
import Steps from "./steps/reducer";

// Features
import Features from "./features/reducer";

// Members
import Members from "./member/reducer";

// Tags
import Tag from "./tags/reducer";

// role
import Roles from "./role/reducer";

import Users from "./users/reducer";

// Faqs
import Faq from "./Faqs/reducer";

// Events
import Events from "./events/reducer";

//Banner
import Banners from "./banner/reducer";

// Connections
import Connections from "./connection/reducer";

//Site
import Sites from "./sites/reducer";

// Channels
import Channels from "./channels/reducer";

//Page
import Pages from "./page/reducer";

//SEO
import Seos from "./seo/reducer";

//Contact
import Contacts from "./contacts/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Categories,
  Users,
  Banners,
  Sites,
  Faq,
  Tag,
  Connections,
  Channels,
  Pages,
  Roles,
  Playlists,
  Events,
  Steps,
  Seos,
  Contacts,
  Features,
  Members,
});

export default rootReducer;
