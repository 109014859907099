// Playlist

export const GET_PLAYLIST = "GET_PLAYLIST";
export const GET_PLAYLIST_SUCCESS = "GET_PLAYLIST_SUCCESS";
export const GET_PLAYLIST_FAIL = "GET_PLAYLIST_FAIL";

export const ADD_NEW_PLAYLIST = "ADD_NEW_PLAYLIST";
export const ADD_NEW_PLAYLIST_SUCCESS = "ADD_NEW_PLAYLIST_SUCCESS";
export const ADD_NEW_PLAYLIST_FAIL = "ADD_NEW_PLAYLIST_FAIL";

export const UPDATE_PLAYLIST = "UPDATE_PLAYLIST";
export const UPDATE_PLAYLIST_SUCCESS = "UPDATE_PLAYLIST_SUCCESS";
export const UPDATE_PLAYLIST_FAIL = "UPDATE_PLAYLIST_FAIL";

export const GET_PLAYLIST_DETAILS = "GET_PLAYLIST_DETAILS";
export const GET_PLAYLIST_DETAILS_SUCCESS = "GET_PLAYLIST_DETAILS_SUCCESS";
export const GET_PLAYLIST_DETAILS_FAIL = "GET_PLAYLIST_DETAILS_FAIL";

export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const DELETE_PLAYLIST_SUCCESS = "DELETE_PLAYLIST_SUCCESS";
export const DELETE_PLAYLIST_FAIL = "DELETE_PLAYLIST_FAIL";
export const MODIFY_PLAYLIST_FLAG = "MODIFY_PLAYLIST_FLAG";

export const RESET_PLAYLIST_FLAG = "RESET_PLAYLIST_FLAG";

// STATUS CHANGE
export const STATUS_CHANGE_PLAYLIST = "STATUS_CHANGE_PLAYLIST";
export const STATUS_CHANGE_PLAYLIST_SUCCESS = "STATUS_CHANGE_PLAYLIST_SUCCESS";
export const STATUS_CHANGE_PLAYLIST_FAIL = "STATUS_CHANGE_PLAYLIST_FAIL";


export const SAVE_ORDERING_PLAYLIST = "SAVE_ORDERING_PLAYLIST";
export const SAVE_ORDERING_PLAYLIST_SUCCESS = "SAVE_ORDERING_PLAYLIST_SUCCESS";
export const SAVE_ORDERING_PLAYLIST_FAIL = "SAVE_ORDERING_PLAYLIST_FAIL";
export const SAVE_ORDERING_PLAYLIST_RESET_FLAG = "SAVE_ORDERING_PLAYLIST_RESET_FLAG";

// FEATURED CHANGE
export const FEATURED_CHANGE_PLAYLIST = "FEATURED_CHANGE_PLAYLIST";
export const FEATURED_CHANGE_PLAYLIST_SUCCESS = "FEATURED_CHANGE_PLAYLIST_SUCCESS";
export const FEATURED_CHANGE_PLAYLIST_FAIL = "FEATURED_CHANGE_PLAYLIST_FAIL";