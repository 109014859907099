import {
    GET_CONNECTIONS,
    GET_CONNECTIONS_FAIL,
    GET_CONNECTIONS_SUCCESS,
    ADD_NEW_CONNECTION,
    ADD_CONNECTION_SUCCESS,
    ADD_CONNECTION_FAIL,
    DELETE_CONNECTION,
    RESET_CONNECTION_FLAG,
    DELETE_CONNECTION_SUCCESS,
    DELETE_CONNECTION_FAIL,
    STATUS_CHANGE_CONNECTION,
    STATUS_CHANGE_CONNECTION_SUCCESS,
    STATUS_CHANGE_CONNECTION_FAIL,
    MODIFY_CONNECTION_FLAG,
    SAVE_ORDERING_CONNECTION,
    SAVE_ORDERING_CONNECTION_SUCCESS,
    SAVE_ORDERING_CONNECTION_FAIL,
    SAVE_ORDERING_CONNECTION_RESET_FLAG,
  } from "./actionType";
  
  export const getConnections = data => ({
    type: GET_CONNECTIONS,
    payload: data,
  });
  
  export const getConnectionsSuccess = data => ({
    type: GET_CONNECTIONS_SUCCESS,
    payload: data,
  });
  
  export const getConnectionsFail = error => ({
    type: GET_CONNECTIONS_FAIL,
    payload: error,
  });
  
  export const addNewConnection = Connection => ({
    type: ADD_NEW_CONNECTION,
    payload: Connection,
  });
  
  export const addConnectionSuccess = Connection => ({
    type: ADD_CONNECTION_SUCCESS,
    payload: Connection,
  });
  
  export const addConnectionFail = error => ({
    type: ADD_CONNECTION_FAIL,
    payload: error,
  });
  
  export const deleteConnection = Connection => ({
    type: DELETE_CONNECTION,
    payload: Connection,
  });
  
  export const deleteConnectionSuccess = Connection => ({
    type: DELETE_CONNECTION_SUCCESS,
    payload: Connection,
  });
  
  export const deleteConnectionFail = error => ({
    type: DELETE_CONNECTION_FAIL,
    payload: error,
  });
  
  export const modifyConnectionFlag = () => {
    return {
      type: MODIFY_CONNECTION_FLAG,
    }
  }
  
  export const resetConnectionFlag = () => {
    return {
      type: RESET_CONNECTION_FLAG,
    }
  }
  // Status Chaange
  export const statusChangeConnection = Connection => ({
    type: STATUS_CHANGE_CONNECTION,
    payload: Connection,
  });
  
  export const statusChangeConnectionSuccess = Connection => ({
    type: STATUS_CHANGE_CONNECTION_SUCCESS,
    payload: Connection,
  });
  
  export const statusChangeConnectionFail = error => ({
    type: STATUS_CHANGE_CONNECTION_FAIL,
    payload: error,
  });
  
  
  // Save ordering
  
  export const saveOrderingConnection = data => ({
    type: SAVE_ORDERING_CONNECTION,
    payload: data,
  });
  
  export const saveOrderingConnectionSuccess = data => ({
    type: SAVE_ORDERING_CONNECTION_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingConnectionFail = error => ({
    type: SAVE_ORDERING_CONNECTION_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingConnectionResetFlag = () => {
    return {
      type: SAVE_ORDERING_CONNECTION_RESET_FLAG,
    }
  }
  