import {
    GET_TAGS,
    GET_TAGS_FAIL,
    GET_TAGS_SUCCESS,
    ADD_NEW_TAG,
    ADD_TAG_SUCCESS,
    ADD_TAG_FAIL,
    GET_TAG_DETAILS,
    GET_TAG_DETAILS_SUCCESS,
    GET_TAG_DETAILS_FAIL,
    UPDATE_TAG,
    UPDATE_TAG_SUCCESS,
    UPDATE_TAG_FAIL,
    DELETE_TAG,
    RESET_TAG_FLAG,
    DELETE_TAG_SUCCESS,
    DELETE_TAG_FAIL,
    STATUS_CHANGE_TAG,
    STATUS_CHANGE_TAG_SUCCESS,
    STATUS_CHANGE_TAG_FAIL,
    FEATURED_CHANGE_TAG,
    FEATURED_CHANGE_TAG_SUCCESS,
    FEATURED_CHANGE_TAG_FAIL,
    MODIFY_TAG_FLAG,
    SAVE_ORDERING_TAG,
    SAVE_ORDERING_TAG_SUCCESS,
    SAVE_ORDERING_TAG_FAIL,
    SAVE_ORDERING_TAG_RESET_FLAG,
  } from "./actionTypes";
  
  export const getTags = data => ({
    type: GET_TAGS,
    payload: data,
  });
  
  export const getTagsSuccess = data => ({
    type: GET_TAGS_SUCCESS,
    payload: data,
  });
  
  export const getTagsFail = error => ({
    type: GET_TAGS_FAIL,
    payload: error,
  });
  
  export const getTagDetails = tags => ({
    type: GET_TAG_DETAILS,
    payload: tags,
  });
  
  export const getTagDetailsSuccess = tags => ({
    type: GET_TAG_DETAILS_SUCCESS,
    payload: tags,
  });
  
  export const getTagDetailsFail = error => ({
    type: GET_TAG_DETAILS_FAIL,
    payload: error,
  });
  
  export const addNewTag = tags => ({
    type: ADD_NEW_TAG,
    payload: tags,
  });
  
  export const addTagSuccess = tags => ({
    type: ADD_TAG_SUCCESS,
    payload: tags,
  });
  
  export const addTagFail = error => ({
    type: ADD_TAG_FAIL,
    payload: error,
  });
  
  export const updateTag = tags => ({
    type: UPDATE_TAG,
    payload: tags,
  });
  
  export const updateTagSuccess = tags => ({
    type: UPDATE_TAG_SUCCESS,
    payload: tags,
  });
  
  export const updateTagFail = error => ({
    type: UPDATE_TAG_FAIL,
    payload: error,
  });
  
  export const deleteTag = tags => ({
    type: DELETE_TAG,
    payload: tags,
  });
  
  export const deleteTagSuccess = tags => ({
    type: DELETE_TAG_SUCCESS,
    payload: tags,
  });
  
  export const deleteTagFail = error => ({
    type: DELETE_TAG_FAIL,
    payload: error,
  });
  
  export const modifyTagFlag = () => {
    return {
      type: MODIFY_TAG_FLAG,
    }
  }
  
  export const resetTagFlag = () => {
    return {
      type: RESET_TAG_FLAG,
    }
  }
  // Status Chaange
  export const statusChangeTag = tags => ({
    type: STATUS_CHANGE_TAG,
    payload: tags,
  });
  
  export const statusChangeTagSuccess = tags => ({
    type: STATUS_CHANGE_TAG_SUCCESS,
    payload: tags,
  });
  
  export const statusChangeTagFail = error => ({
    type: STATUS_CHANGE_TAG_FAIL,
    payload: error,
  });
  
  // Featured Change
  export const featuredChangeTag = tags => ({
    type: FEATURED_CHANGE_TAG,
    payload: tags,
  });
  
  export const featuredChangeTagSuccess = tags => ({
    type: FEATURED_CHANGE_TAG_SUCCESS,
    payload: tags,
  });
  
  export const featuredChangeTagFail = error => ({
    type: FEATURED_CHANGE_TAG_FAIL,
    payload: error,
  });
  
  // Save ordering
  
  export const saveOrderingTag = data => ({
    type: SAVE_ORDERING_TAG,
    payload: data,
  });
  
  export const saveOrderingTagSuccess = data => ({
    type: SAVE_ORDERING_TAG_SUCCESS,
    payload: data,
  });
  
  export const saveOrderingTagFail = error => ({
    type: SAVE_ORDERING_TAG_FAIL,
    payload: error,
  });
  
  
  //Save ordering reset Flag for toaster
  export const saveOrderingTagResetFlag = () => {
    return {
      type: SAVE_ORDERING_TAG_RESET_FLAG,
    }
  }
  