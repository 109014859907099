import React, { useEffect, useState, Fragment } from "react";
import { APIClient } from "../../../helpers/api_helper";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import {
  getEvents,
  deleteEvent,
  getUsers,
  modifyEventFlag,
  saveOrderingEvent,
  saveOrderingEventResetFlag,
  getTags,
} from "../../../store/actions";
import MsgToast from "../../../Components/Common/MsgToast";
import Loader from "../../../Components/Common/Loader";
import UserNav from "../../../Components/UserNavigation/UserNav";
import moment from "moment";
import Select from "react-select";
import frontUrl from "../../../config/frontUrl";

function EventList() {
  const dispatch = useDispatch();
  const api = new APIClient();
  const [keyword, setKeyword] = useState("");
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [tags, setTags] = useState([]);
  const [sortKey, setsortKey] = useState("ordering");
  const [sortValue, setSortByValue] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPage, setNoOfPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [date, setDate] = useState("");
  const [isChecked, setisChecked] = useState([]);
  const [metatitle, setMetaTitle] = useState("CEO");
  const [tag_type, settag_type] = useState("1");
  const [options, setOptions] = useState([]);
  const [TagOptions, setTagOptions] = useState([]);
  const [multiValue, setMultiVal] = useState([]);
  const [userVal, setUserVal] = useState([{}]);
  const [fromUsers, setFromUsers] = useState(false);
  const searchParams = new URLSearchParams(window.location.search).get(
    "user_id"
  );
  const [user_id, setuser_id] = useState(searchParams);

  useEffect(() => {
    dispatch(
      getEvents({
        keyword,
        limit,
        user_id: user_id ? user_id : "",
        offset,
        sortByField: sortKey,
        sortByValue: sortValue,
        status,
        tags,
      })
    );
  }, [
    dispatch,
    keyword,
    limit,
    offset,
    sortKey,
    sortValue,
    status,
    user_id,
    tags,
  ]);

  useEffect(() => {
    let dateRange = "";
    if (date && date.e && date.e[0] && date.e[1]) {
      dateRange = {
        start: date.e[0],
        end: date.e[1],
      };
      dispatch(getEvents({ limit, dateRange, offset, keyword }));
    }
  }, [dispatch, limit, offset, date, keyword]);

  const {
    events,
    users,
    totalDataCount,
    isSuccess,
    error,
    success,
    statusSuccess,
    statusFailed,
    saveOrderingSuccess,
    saveOrderingFaild,
    tagList,
    loading,
  } = useSelector((state) => ({
    events: state.Events.events,
    isSuccess: state.Events.isSuccess,
    users: state.Users.users,
    success: state.Events.success,
    totalDataCount: state.Events.totalDataCount,
    error: state.Events.error,
    statusSuccess: state.Events.statusSuccess,
    statusFailed: state.Events.statusFailed,
    saveOrderingSuccess: state.Events.saveOrderingSuccess,
    saveOrderingFaild: state.Events.saveOrderingFaild,
    tagList: state.Tag.tags,
    loading: state.Events.loading,
  }));

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    dispatch(
      getUsers({
        type: "user",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTags({ tag_type: "1", status: "" }));
  }, [dispatch, tag_type, status]);

  useEffect(() => {
    if (statusSuccess || statusFailed) {
      setTimeout(() => {
        dispatch(modifyEventFlag());
      }, 3000);
    }
  }, [dispatch, statusSuccess, statusFailed]);

  const sortBy = (fieldName) => {
    if (fieldName === sortKey) {
      if (sortValue === 1) {
        setSortByValue(-1);
      } else {
        setSortByValue(1);
      }
    } else {
      setSortByValue(-1);
    }
    setsortKey(fieldName);
  };

  // Ordering
  const [dataList, setDataList] = useState();
  const [orderingData, setOrderingData] = useState(null);
  useEffect(() => {
    setDataList(events);
  }, [events]);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(dataList);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setDataList([...tempData]);
    let dataArray = [];
    for (let i = 0; i < tempData.length; i++) {
      dataArray.push({ id: tempData[i]._id, ordering: i + 1 });
    }
    setOrderingData(dataArray);
    onClickSaveOrdering(dataArray);
  };

  //Ordering
  function onClickSaveOrdering(orderingData) {
    dispatch(
      saveOrderingEvent({
        ordering_data: orderingData,
        table_name: "event",
      })
    );
  }

  const deleteEventData = (id) => {
    dispatch(deleteEvent(id));
  };

  useEffect(() => {
    if (saveOrderingSuccess || saveOrderingFaild) {
      setTimeout(() => {
        dispatch(saveOrderingEventResetFlag());
      }, 3000);
    }
  }, [dispatch, saveOrderingSuccess, saveOrderingFaild]);

  useEffect(() => {
    const request = async () => {
      const response = await api.create("site-setting/details");
      setMetaTitle(response.data.title);
    };
    request();
  }, []);

  const selects = (e) => {
    let checkval = e.target.checked;
    var ele = document.getElementsByName("chk_child");
    if (checkval === true) {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = true;
      }
    } else {
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type == "checkbox") ele[i].checked = false;
      }
    }

    var array = [];
    var checkboxes = document.querySelectorAll("input[name=chk_child]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    setisChecked(array);
  };

  useEffect(() => {
    const request = async () => {
      const response = await api.create("profile-details", {
        user_id: user_id,
      });
      setUserVal({ value: user_id, label: response?.data?.email });
    };
    request();
  }, []);

  useEffect(() => {
    let userData = [{ label: "Select User", value: "" }];
    users.length > 0 &&
      users.map((item, index) => {
        userData.push({ label: item.email, value: item._id });
      });
    setOptions(userData);
  }, [users]);

  const selectUser = (e) => {
    setUserVal(e);
    setuser_id(e.value);
  };

  useEffect(() => {
    let tagData = [];
    tagList.length > 0 &&
      tagList.map((item, index) => {
        tagData.push({ label: item.title, value: item._id });
      });
    setTagOptions(tagData);
  }, [tagList]);

  const selectTag = (e) => {
    let tagDatas = [];
    e.map((item, index) => {
      tagDatas.push(item.value);
    });

    setTags(tagDatas);
    setMultiVal(e);
  };

  document.title = "Event List | " + metatitle;
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* <ToastContainer /> */}
          <BreadCrumb title="Events" />
          <Row>
            <Col lg={12}>{searchParams && <UserNav user_id={user_id} />}</Col>
            <Col lg={12}>
              <Card id="orderList">
                <CardHeader className="card-header border-0">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title mb-0 flex-grow-1">Event List</h5>
                  </div>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                  <form>
                    <Row className="g-3">
                      <Col sm={2} className="col-xxl-2">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => setLimit(Number(e.target.value))}
                        >
                          <option disabled>Select Page</option>
                          <option value="9999999999">All</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                          <option value="50">50</option>
                        </select>
                      </Col>
                      <Col sm={2} className="col-lg-3">
                        <div className="search-box">
                          <input
                            type="text"
                            className="form-control search"
                            placeholder="Search"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                      </Col>

                      <Col sm={2} className="col-lg-2">
                        <div>
                          <select
                            className="form-select"
                            data-choices
                            data-choices-search-false
                            id="choices-privacy-status-input"
                            value={status}
                            onChange={(e) => {
                              setStatus(Number(e.target.value));
                            }}
                          >
                            <option value="">Select status</option>
                            <option value="1">Published</option>
                            <option value="2">Draft</option>
                          </select>
                        </div>
                      </Col>

                      {/* users dropdown */}
                      {!searchParams && (
                        <Col sm={4} className="col-lg-2">
                          <div>
                            <Select
                              options={options}
                              onChange={selectUser}
                              // value={userVal}
                              classNamePrefix="select2-selection form-select"
                            />
                          </div>
                        </Col>
                      )}

                      {/* tags dropdown */}

                      <Col sm={4} className="col-lg-2">
                        <div>
                          <Select
                            options={TagOptions}
                            onChange={selectTag}
                            isMulti
                            value={multiValue}
                            classNamePrefix="select2-selection form-select"
                          />
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
                <CardBody className="pt-0">
                  {isSuccess && !loading && (
                    <div className="table-responsive table-card mt-3 mb-1">
                      {Array.isArray(events) && events.length > 0 && (
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <>
                            <table
                              className="table align-middle table-nowrap"
                              id="customerTable"
                            >
                              <thead className="table-light">
                                <tr>
                                  <th className="cursor-pointer">Image</th>
                                  <th className="cursor-pointer">Event Name</th>
                                  <th>Event Tag</th>
                                  <th
                                    className="sort cursor-pointer"
                                    data-sort="created_at"
                                    onClick={() => sortBy("created_at")}
                                  >
                                    Date
                                  </th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Status</th>
                                  <th className="text-end">Actions</th>
                                </tr>
                              </thead>
                              <Droppable droppableId="tbody">
                                {(provided) => (
                                  <tbody
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    className="list form-check-all"
                                  >
                                    {dataList?.map((item, i) => (
                                      <Draggable
                                        draggableId={item._id}
                                        key={item._id}
                                        index={i}
                                      >
                                        {(provided) => (
                                          <tr
                                            key={item._id}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <td>
                                              {" "}
                                              {item?.image && (
                                                <img
                                                  className="image avatar-xs rounded-circle"
                                                  src={item.image}
                                                  alt=""
                                                  width="50px"
                                                ></img>
                                              )}
                                            </td>
                                            <td>{item.title}</td>
                                            <td>
                                              {item.eventTags
                                                .map((item) => item.title)
                                                .join(", ")}
                                            </td>

                                            <td>
                                              <Moment format="D MMM YYYY">
                                                {item.start_date}
                                              </Moment>
                                            </td>
                                            <td>
                                              {moment(
                                                item.start_time,
                                                "HH:mm:ss"
                                              ).format("hh:mm A")}
                                            </td>
                                            <td>
                                              {item.end_time !== ""
                                                ? moment(
                                                    item.end_time,
                                                    "HH:mm:ss"
                                                  ).format("hh:mm A")
                                                : "NA"}
                                            </td>
                                            <td>
                                              {item?.status === 1 ? (
                                                <span className="badge badge-soft-success text-uppercase">
                                                  Published
                                                </span>
                                              ) : (
                                                <span className="badge badge-soft-danger text-uppercase">
                                                  Draft
                                                </span>
                                              )}
                                            </td>

                                            <td>
                                              <ul className="list-inline hstack justify-content-end gap-2 mb-0">
                                                {item?.event_id !==
                                                  undefined && (
                                                  <li
                                                    className="list-inline-item"
                                                    title="View"
                                                  >
                                                    <Link
                                                      className="text-primary d-inline-block edit-item-btn"
                                                      to={
                                                        "/event/view/" +
                                                        item?.event_id
                                                      }
                                                    >
                                                      <i className="ri-eye-line fs-18"></i>
                                                    </Link>
                                                  </li>
                                                )}

                                                <li
                                                  className="list-inline-item"
                                                  title="View Sites"
                                                >
                                                  <a
                                                    className="text-primary d-inline-block edit-item-btn"
                                                    href={
                                                      frontUrl +
                                                      "/event-details/" +
                                                      item?.slug +
                                                      "/" +
                                                      item?.event_id
                                                    }
                                                    target="_blank"
                                                  >
                                                    <i className="ri-links-line fs-18"></i>
                                                  </a>
                                                </li>
                                              </ul>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </tbody>
                                )}
                              </Droppable>
                            </table>
                            {noOfPage > 1 && (
                              <Pagination className="px-3">
                                {Array(noOfPage)
                                  .fill()
                                  .map((_, i) => {
                                    return (
                                      <Fragment key={i}>
                                        {offset > 0 && i === 0 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(offset - limit);
                                                setCurrentPage(currentPage - 1);
                                              }}
                                            >
                                              ← &nbsp; Prev
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage === i + 1 && (
                                          <PaginationItem active>
                                            <PaginationLink>
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== i + 1 && (
                                          <PaginationItem>
                                            <PaginationLink
                                              to="#"
                                              onClick={() => {
                                                setOffset(limit * i);
                                                setCurrentPage(i + 1);
                                              }}
                                            >
                                              {i + 1}
                                            </PaginationLink>
                                          </PaginationItem>
                                        )}
                                        {currentPage !== noOfPage &&
                                          i + 1 === noOfPage && (
                                            <PaginationItem>
                                              <PaginationLink
                                                to="#"
                                                onClick={() => {
                                                  setOffset(offset + limit);
                                                  setCurrentPage(
                                                    currentPage + 1
                                                  );
                                                }}
                                              >
                                                Next &nbsp; →
                                              </PaginationLink>
                                            </PaginationItem>
                                          )}
                                      </Fragment>
                                    );
                                  })}
                              </Pagination>
                            )}
                          </>
                        </DragDropContext>
                      )}
                    </div>
                  )}
                  {loading && <Loader />}
                  {isSuccess &&
                    !loading &&
                    Array.isArray(events) &&
                    events.length === 0 && (
                      <h5 className="mt-3 text-center">No Result Found</h5>
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {statusSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {statusFailed ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}

        {saveOrderingSuccess ? (
          <MsgToast
            msg={success}
            color="success"
            icon="ri-checkbox-circle-line"
          />
        ) : null}
        {saveOrderingFaild ? (
          <MsgToast msg={error} color="danger" icon="ri-error-warning-line" />
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default EventList;
