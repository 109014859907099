import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getUser } from "../../store/actions";
import { useHistory } from "react-router-dom";

const ProfileDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Profile.getuser,
  }));

  useEffect(() => {
    dispatch(getUser());
  }, []);

  const navigateItem = () => {
    history.push("/profile");
  };

  const navigateItems = () => {
    history.push("/change-password");
  };

  const [userName, setUserName] = useState("Admin");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      setUserName(user.name || obj.data.name || "Admin");
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={user?.image}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user?.name}
              </span>
              {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {user?.name}!</h6>
          <DropdownItem onClick={navigateItem}>
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem>
          <DropdownItem onClick={navigateItems}>
            <i className="ri-lock-line"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Change Password
            </span>
          </DropdownItem>
          <DropdownItem onClick={() => setModal(true)}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modal} centered={true}>
        <ModalHeader>Logout</ModalHeader>
        <ModalBody>Are you sure you want to logout?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setModal(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              window.location.href = "/logout";
            }}
          >
            Logout
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileDropdown;
