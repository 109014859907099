/* CONNECTIONS */
export const GET_CONNECTIONS = "GET_CONNECTIONS";
export const GET_CONNECTIONS_SUCCESS = "GET_CONNECTIONS_SUCCESS";
export const GET_CONNECTIONS_FAIL = "GET_CONNECTIONS_FAIL";

export const ADD_NEW_CONNECTION = "ADD_NEW_CONNECTION";
export const ADD_CONNECTION_SUCCESS = "ADD_CONNECTION_SUCCESS";
export const ADD_CONNECTION_FAIL = "ADD_CONNECTION_FAIL";


export const DELETE_CONNECTION = "DELETE_CONNECTION";
export const DELETE_CONNECTION_SUCCESS = "DELETE_CONNECTION_SUCCESS";
export const DELETE_CONNECTION_FAIL = "DELETE_CONNECTION_FAIL";

export const MODIFY_CONNECTION_FLAG = "MODIFY_CONNECTION_FLAG";

export const RESET_CONNECTION_FLAG = "RESET_CONNECTION_FLAG";


// STATUS CHANGE
export const STATUS_CHANGE_CONNECTION = "STATUS_CHANGE_CONNECTION";
export const STATUS_CHANGE_CONNECTION_SUCCESS = "STATUS_CHANGE_CONNECTION_SUCCESS";
export const STATUS_CHANGE_CONNECTION_FAIL = "STATUS_CHANGE_CONNECTION_FAIL";


// Save Ordering
export const SAVE_ORDERING_CONNECTION = "SAVE_ORDERING_CONNECTION";
export const SAVE_ORDERING_CONNECTION_SUCCESS = "SAVE_ORDERING_CONNECTION_SUCCESS";
export const SAVE_ORDERING_CONNECTION_FAIL = "SAVE_ORDERING_CONNECTION_FAIL";
export const SAVE_ORDERING_CONNECTION_RESET_FLAG = "SAVE_ORDERING_CONNECTION_RESET_FLAG";
