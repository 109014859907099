import "react-toastify/dist/ReactToastify.css";

const Loader = () => {
  return (
    <>
      <div className="noresult text-center py-5">
        <div className="loader">
          <svg
            width="188"
            height="104"
            viewBox="0 -2 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.35577 25.5022C1.11853 25.5022 0 24.3305 0 21.9872V3.51512C0 1.17212 1.11853 -8.39233e-05 3.35577 -8.39233e-05H3.73916C5.9549 -8.39233e-05 7.06258 1.17212 7.06258 3.51512V9.10814H4.44231V3.8667C4.44231 3.37702 4.35131 3.00376 4.17054 2.74817C3.98907 2.49257 3.78146 2.36478 3.54756 2.36478C3.24877 2.36478 3.02517 2.50857 2.8764 2.79616C2.72692 3.08374 2.65262 3.41932 2.65262 3.80271V21.6356C2.65262 22.1256 2.73758 22.4985 2.90839 22.7539C3.07832 23.0097 3.29178 23.1375 3.54756 23.1375C3.82412 23.1375 4.04275 22.9937 4.20254 22.7061C4.36233 22.4184 4.44231 22.083 4.44231 21.6994V16.3942H7.06258V21.9872C7.06258 24.3305 5.9549 25.5022 3.73916 25.5022H3.35577Z"
              fill="white"
            />
            <path
              d="M12.0161 2.39659C11.7175 2.39659 11.4939 2.54038 11.3452 2.82797C11.1957 3.11555 11.1214 3.45113 11.1214 3.83452V11.7924L11.1854 11.7284C11.9306 10.9401 12.4047 10.3011 12.6073 9.81111C12.8094 9.32107 12.9109 8.67142 12.9109 7.86181V3.89851C12.9109 3.40883 12.8254 3.03575 12.6553 2.78016C12.4845 2.52438 12.2717 2.39659 12.0161 2.39659ZM11.8243 25.5022C9.58711 25.5022 8.46875 24.3305 8.46875 21.987V3.54711C8.46875 1.20394 9.58711 0.0319042 11.8243 0.0319042H12.2079C14.4235 0.0319042 15.5312 1.20394 15.5312 3.54711V7.41443C15.5312 8.20272 15.4993 8.84721 15.4354 9.34773C15.3716 9.84843 15.2544 10.3118 15.084 10.7379C14.9132 11.1852 14.6686 11.6326 14.349 12.08C14.0294 12.5274 13.582 13.0498 13.0067 13.6459C12.2182 14.4984 11.7069 15.1636 11.473 15.643C11.2382 16.1224 11.1214 16.7455 11.1214 17.5129V21.6994C11.1214 22.0828 11.1957 22.4184 11.3452 22.706C11.4939 22.9935 11.7175 23.1375 12.0161 23.1375C12.2717 23.1375 12.4845 23.0095 12.6553 22.7539C12.8254 22.4984 12.9109 22.1256 12.9109 21.6356V16.426H15.5312V21.987C15.5312 24.3305 14.4235 25.5022 12.2079 25.5022H11.8243Z"
              fill="white"
            />
            <path
              d="M20.4849 2.36478C20.2076 2.36478 19.9897 2.50857 19.8297 2.79616C19.6701 3.08375 19.5901 3.41932 19.5901 3.80271V21.6356C19.5901 22.1256 19.6749 22.4985 19.8457 22.7539C20.0158 23.0097 20.2293 23.1377 20.4849 23.1377C20.7616 23.1377 20.9803 22.9937 21.14 22.7061C21.2998 22.4185 21.3796 22.083 21.3796 21.6996V3.8667C21.3796 3.37702 21.2941 3.00394 21.124 2.74835C20.9532 2.49257 20.7405 2.36478 20.4849 2.36478ZM20.2933 25.5022C18.0559 25.5022 16.9375 24.3307 16.9375 21.9872V3.5153C16.9375 1.17213 18.0559 9.34601e-05 20.2933 9.34601e-05H20.6767C22.8922 9.34601e-05 23.9999 1.17213 23.9999 3.5153V21.9872C23.9999 24.3307 22.8922 25.5022 20.6767 25.5022H20.2933Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Loader;
