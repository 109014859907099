import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  ADD_NEW_ROLES,
  ADD_NEW_ROLES_SUCCESS,
  ADD_NEW_ROLES_FAIL,
  GET_ROLES_DETAILS,
  GET_ROLES_DETAILS_SUCCESS,
  GET_ROLES_DETAILS_FAIL,
  MODIFY_ROLE_FLAG,
  RESET_ROLE_FLAG
} from "./actionTypes";

  export const getRoles = (data) => ({
    type: GET_ROLES,
    payload: data
  });
  
  export const getRolesSuccess = roles => ({
    type: GET_ROLES_SUCCESS,
    payload: roles,
  });
  
  export const getRolesFail = error => ({
    type: GET_ROLES_FAIL,
    payload: error,
  });

  export const addNewRole = roles => ({
    type: ADD_NEW_ROLES,
    payload: roles,
  });
  
  export const addNewRolesuccess = roles => ({
    type: ADD_NEW_ROLES_SUCCESS,
    payload: roles,
  });
  
  export const addNewRoleFail = error => ({
    type: ADD_NEW_ROLES_FAIL,
    payload: error,
  });

  export const getRolesDetails = roles => ({
    type: GET_ROLES_DETAILS,
    payload: roles,
  });
  
  export const getRolesDetailsSuccess = roles => ({
    type: GET_ROLES_DETAILS_SUCCESS,
    payload: roles,
  });
  
  export const getRolesDetailsFail = error => ({
    type: GET_ROLES_DETAILS_FAIL,
    payload: error,
  });

  export const modifyRoleFlag = () => {
    return {
      type: MODIFY_ROLE_FLAG,
    }
  }
  
  export const resetRoleFlag = () => {
    return {
      type: RESET_ROLE_FLAG,
    }
  }

