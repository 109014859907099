import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Section from "./Section";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import hostUrl from "../../config/host";
import { getSiteDetails } from "../../store/actions";
import { APIClient } from "../../helpers/api_helper";
import Loader from "../../Components/Common/Loader";

const Dashboard = () => {
  const dispatch = useDispatch();
  const api = new APIClient();
  const [postData, setPostData] = useState("");
  const [loader, setLoader] = useState(false);
  const [dashboardData, setDashboardData] = useState({});

  const { Settingsdetails } = useSelector((state) => ({
    Settingsdetails: state.Sites.details,
  }));

  useEffect(() => {
    dispatch(getSiteDetails());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const response = await api.create("dashboard");
      setDashboardData(response.data);
      setLoader(false);
    };
    fetchData();
  }, []);

  console.log(loader);

  useEffect(() => {
    if (Settingsdetails !== undefined) {
      const fetchPostData = async () => {
        setLoader(true);
        const response = await axios.get(
          `${hostUrl}/v3/posts?sortBy=lastCreated&targetId=${Settingsdetails?.community_id}&targetType=community`,
          {
            headers: {
              Authorization: `Bearer ${Settingsdetails?.amity_admin_token}`,
            },
          }
        );
        setPostData(response.feeds[0].postCount);
        setLoader(false);
      };
      fetchPostData();
    }
  }, [Settingsdetails]);

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Dashboard" pageTitle="Dashboards" />
            <Row>
              <Col>
                <div className="h-100">
                  <Section />
                  {!loader && Object.keys(dashboardData).length !== 0 && (
                    <Row>
                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Users
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalUser}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/users"}>
                                    <i
                                      className={`text-white ri-user-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Feeds
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {postData}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link
                                    to={`posts?communityId=${Settingsdetails?.community_id}&type=community`}
                                  >
                                    <i
                                      className={`text-white ri-discuss-fill`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  YouTube
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalYoutube}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/playlist?type=youtube"}>
                                    <i
                                      className={`text-white ri-youtube-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Viemo
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalVimeo}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/playlist?type=vimeo"}>
                                    <i
                                      className={`text-white ri-youtube-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Podcast
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalSpotify}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/playlist?type=spotify"}>
                                    <i
                                      className={`text-white ri-spotify-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Stories
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalStory}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/stories"}>
                                    <i
                                      className={`text-white ri-book-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col xl={3} md={6}>
                        <Card className="card-animate">
                          <CardBody>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                  Events
                                </p>
                              </div>
                              <div className="flex-shrink-0"></div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                              <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                  <span
                                    className="counter-value"
                                    data-target="559.25"
                                  >
                                    {dashboardData.totalEvent}
                                  </span>
                                </h4>
                              </div>
                              <div className="avatar-sm flex-shrink-0">
                                <span
                                  className={
                                    "avatar-title rounded fs-3 bg-soft-yellow"
                                  }
                                >
                                  <Link to={"/events"}>
                                    <i
                                      className={`text-white ri-book-line`}
                                    ></i>
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {loader && <Loader />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default Dashboard;
