import { takeEvery, put, call } from "redux-saga/effects"

// Calender Redux States
import {
    ADD_NEW_CONNECTION,
    DELETE_CONNECTION,
    GET_CONNECTIONS,
    STATUS_CHANGE_CONNECTION,
    SAVE_ORDERING_CONNECTION,
} from "./actionType"

import {
  getConnectionsSuccess,
  getConnectionsFail,
  addConnectionFail,
  addConnectionSuccess,
  deleteConnectionSuccess,
  deleteConnectionFail,
  statusChangeConnectionSuccess,
  statusChangeConnectionFail,
  saveOrderingConnectionSuccess,
  saveOrderingConnectionFail,
} from "./action"

//Include Both Helper File with needed methods
import {
  getConnectionsList,
  addNewConnection,
  deleteFaq,
  statusChange,
  saveOrdering,
  
} from "../../helpers/fakebackend_helper"

function* fetchConnections({ payload: data }) {
  try {
    const response = yield call(getConnectionsList, data)
    yield put(getConnectionsSuccess(response))
  } catch (error) {
    yield put(getConnectionsFail(error))
  }
}

function* onAddNewConnection({ payload: Connection }) {
  try {
    const response = yield call(addNewConnection, Connection)
    yield put(addConnectionSuccess(response))
  } catch (error) {
    yield put(addConnectionFail(error))
  }
}


function* onDeleteConnection({ payload: Connection }) {
  try {
    const response = yield call(deleteFaq, Connection)
    yield put(deleteConnectionSuccess(response))
  } catch (error) {
    yield put(deleteConnectionFail(error))
  }
}

//Status Change
function* onStatusChangeConnection({ payload: Connection }) {
  try {
    const response = yield call(statusChange, Connection);
    yield put(statusChangeConnectionSuccess({ Connection, ...response }));
  } catch (error) {
    yield put(statusChangeConnectionFail(error));
  }
}


//Save Ordering
function* onSaveOrderingConnection({ payload: data }) {
  try {
    const response = yield call(saveOrdering, data);
    yield put(saveOrderingConnectionSuccess({ data, ...response }));
  } catch (error) {
    yield put(saveOrderingConnectionFail(error));
  }
}
function* ConnectionSaga() {
  yield takeEvery(GET_CONNECTIONS, fetchConnections)
  yield takeEvery(ADD_NEW_CONNECTION, onAddNewConnection)
  yield takeEvery(DELETE_CONNECTION, onDeleteConnection)
  yield takeEvery(STATUS_CHANGE_CONNECTION, onStatusChangeConnection);
  yield takeEvery(SAVE_ORDERING_CONNECTION, onSaveOrderingConnection);


}

export default ConnectionSaga
