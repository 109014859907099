import { CardHeader } from "reactstrap";

const PreviewCardHeader = ({ title }) => {
  return (
    <>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">{title}</h4>
      </CardHeader>
    </>
  );
};

export default PreviewCardHeader;
